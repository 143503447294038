import { string, object } from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button } from '../../../components/Button/index';
import { useState } from 'react';

const invitationValidationSchema = object().shape({
    subject: string().required('Please specify the subject'),
    message: string().required('Please write some message'),
});

type Props = {
    subject?: string;
    message?: string;
    onSubmit: (values: any) => void;
};

export const InvitationReminderForm = ({ onSubmit, subject = '', message: initialMessage = '' }: Props) => {
    const [message, setMessage] = useState(initialMessage);

    return (
        <Formik initialValues={{ subject, message }} validationSchema={invitationValidationSchema} onSubmit={(values) => onSubmit({ ...values, message })}>
            {(props) => (
                <Form action="#" className="relative rounded shadow-sm flex flex-col focus-within:ring-2 ring-offset-1 focus-within:ring-indigo-500">
                    <Field
                        type="text"
                        name="subject"
                        aria-label="subject"
                        placeholder="Subject"
                        className="text-lg font-normal placeholder-gray-500 rounded-t outline:none border-gray-300 focus:border-gray-300 focus:ring-0 border-b-0"
                    />

                    <div
                        className="h-[25vh] border p-3 sm:text-sm flex-grow outline:none border-gray-300 focus:ring-0 border-t-0 focus:outline-none overflow-y-scroll"
                        contentEditable={true}
                        dangerouslySetInnerHTML={{ __html: props.values.message }}
                        onInput={(event) => setMessage(event.currentTarget.innerHTML)}
                    />

                    <div className="p-2 border-gray-300 border border-t-0 rounded-b bg-white">
                        <Button type="submit" onClick={props.handleSubmit}>
                            Send
                        </Button>

                        <ErrorMessage component="a" className="text-red-500 text-sm ml-5" name="message" />
                    </div>
                </Form>
            )}
        </Formik>
    );
};
