import { MouseEventHandler, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
    isOpen: boolean;
    children: ReactNode;
    size?: 'normal' | 'medium' | 'large';
    onClose: () => void;
}

const ModalSize = {
    normal: 'max-w-[35vw]',
    medium: 'max-w-[50vw]',
    large: 'max-w-full',
};

export const Modal = ({ isOpen = false, children, size = 'normal', onClose = () => {} }: Props) => {
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handler);

        return () => document.removeEventListener('keydown', handler);
    }, []);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : '';
    }, [isOpen]);

    const onClickStub: MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

    const Component = (
        <div className="fixed bg-black/50 left-0 top-0 w-full h-full z-50" onClick={onClose}>
            <div className="relative h-full overflow-auto p-6">
                <div
                    onClick={onClickStub}
                    className={`
                    p-6
                    relative
                    left-1/2
                    bg-white
                    shadow-md
                    rounded-md
                    border
                    w-screen
                    transform
                    -translate-x-1/2
                    ${ModalSize[size]}
                `}
                >
                    {children}
                </div>
            </div>
        </div>
    );

    return createPortal(isOpen ? Component : null, document.body);
};
