import { useMutation } from '@tanstack/react-query';
import { UserRole } from '../../types';
import { HttpMethod, sendRequest } from '../../services/api';

interface Params {
    userId: string;
    role: UserRole;
}

export const useAddUserRole = () => {
    return useMutation({
        mutationFn: async ({ userId, role }: Params) => {
            return sendRequest('manager/user/role', {
                body: { userId, role },
                method: HttpMethod.PUT,
            });
        },
    });
};

export const useRemoveUserRole = () => {
    return useMutation({
        mutationFn: async ({ userId, role }: Params) => {
            return sendRequest(`manager/user/role/${role}`, {
                body: { userId, role },
                method: HttpMethod.DELETE,
            });
        },
    });
};
