import { useQuery } from '@tanstack/react-query';
import { sendRequest } from '../../services/api';

export const path = 'user/me';

export const useMyProfile = () => {
    return useQuery({
        queryKey: [path],
        queryFn: () => sendRequest(path),
    });
};
