import { ProgressBar } from '../../../../components/ProgressBar';
import { pluralize } from '../../../../helpers';

export const Header = ({
    title,
    pageTitle,
    progress,
    areasProgress,
    questionsProgress,
}) => (
    <div className="max-w-full p-6 bg-white drop-shadow-sm rounded-lg border border-gray-200">
        <h1 className="mb-3 text-2xl font-normal tracking-tight text-gray-900">{title}</h1>
        <ProgressBar value={progress} />
        <div className="mt-3 text-base font-normal">
            {pageTitle}:
            <span className="ml-1 text-neutral-400 font-light">{questionsProgress} {pluralize(questionsProgress, ['question', 'questions'])} complete</span>
        </div>
        <h2 className="mt-1 font-light text-neutral-400">
            {areasProgress} {pluralize(areasProgress, ['section', 'sections'])} complete
        </h2>
    </div>
);
