import axios from 'axios';
import * as yup from 'yup';
import { captureException } from '@sentry/react';

const schema = yup.array().of(
  yup.object().shape({
    Page: yup.number().required(),
    Question: yup.string().required(),
    'Seq #': yup.number().required(),
    'Question Name': yup.string().required(),
    'Terrain Ten Bucket': yup.array().of(yup.string()).required(),
  })
);

// checks if array has duplicates
const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};

const scoreTypeExtract = (scoreType) => {
  if (!scoreType) {
    return 'Standard';
  }
  switch (scoreType) {
    case 'Standard':
    case 'Overall':
    case 'Negative':
    case 'Positive':
      return scoreType;
    default:
      throw new Error(`Score Type ${scoreType} is not supported`);
  }
};

const noDescription = (element) => {
  if (!element.Description) {
    return {};
  }
  return element.Description.includes('PANEL |') ||
    element.Description.toLowerCase().includes('sub - panel |')
    ? { description: '', Description: '' }
    : {};
};

const genericQuestion = (question, buckets) => {
  return {
    id: question.id,
    seq: question['Seq #'],
    name: question['Question Name'],
    title: question.Question,
    description: question.Description,
    section: buckets.find(
      (bucket) => bucket.id === question['Terrain Ten Bucket'][0]
    ).name,
    scoreType: scoreTypeExtract(question['Score Type']),
    score: question.Score,
    isRequired: !!question['Required'],
    ...noDescription(question),
  };
};

/**

    mapConditionLogic - Maps a given condition string to the corresponding visibleIf value.
    @param {string} condition - A string representing the condition to be parsed and mapped.
    @returns {string} - The mapped visibleIf value based on the input condition.
    This function takes a given condition string, splits it into two parts based on the '=' sign, trims
    the resulting values, and maps them to the correct visibleIf value. It also checks for supported
    formats and throws an error if an unsupported format is encountered.
    The function is used for processing conditional logic in the given code block that deals with
    sub-questions and their input types, as well as mapping the conditional logic for the main
    question and its elements.
  */
const mapConditionLogic = (condition, index, allQuestions) => {
  if (condition.startsWith('{')) {
    return condition;
  }

  const matches = condition.match(/\[(.*?)\]/g)?.map(match => match.slice(1, -1));
  if (matches?.length) {
    // Replace original substrings (including brackets) with extracted ones
    let updatedStr = condition;
    for (let match of matches) {
      updatedStr = updatedStr.replace(`[${match}]`, mapConditionLogic(match, undefined, allQuestions));
    }
    return updatedStr.replaceAll(']', '').replaceAll('[', '');
  }

  const regex = /\[(.*?)\]/g;

  const extractedValues = [];
  let match;
  while ((match = regex.exec(condition)) !== null) {
    extractedValues.push(match[1]);
  }

  if (extractedValues && extractedValues.length > 0 && index !== undefined) {
    return mapConditionLogic(extractedValues[index], undefined, allQuestions);
  }
  const values = condition.split('=').map((el) => el.trim());
  if (values.length !== 2) {
    throw new Error(`Conditional Logic ${condition} is not supported format`);
  }
  const mapping = {
    yes: 'true',
    no: 'false',
    true: 'true',
    false: 'false',
    True: 'true',
    False: 'false',
  };
  const addslashes = (str) => {
    return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
  };
  const mapRight = (right) => {
    const choicesValues = (allQuestions?.find((el) => el.name === values[0]))?.choices?.map((el) => el.value);
    if (right.toLowerCase() === '{any}' && choicesValues) {
      return `anyof [${choicesValues
        .map((el) => `'${addslashes(el)}'`)
        .join(', ')}]`;
    }
    return `= '${Object.keys(mapping).includes(values[1]) ? mapping[values[1]] : values[1]
      }'`;
  };
  return `{${values[0]}} ${mapRight(values[1])}`;
};

const extractDynamicMatrixColumns = (matrixField) => {
  if (!matrixField) {
    return null;
  }
  return matrixField
    .split('];')
    .map((e) => {
      const cleaned = e.replace('[', '').replace(']', '').trim();
      const parts = cleaned.split(';');
      if (!parts.length || parts.length < 2) {
        return null;
      }
      const inputType = parts[1].trim().toLowerCase();
      switch (inputType) {
        case 'text':
        case 'time':
        case 'datetime':
        case 'week':
        case 'number':
        case 'date':
        case 'email':
        case 'url':
        case 'color':
        case 'month and year':
          return {
            name: parts[0].trim(),
            inputType,
            minWidth: "250px",
            cellType: 'text',
          }
        case 'boolean':
          return {
            name: parts[0].trim(),
            minWidth: "250px",
            cellType: 'boolean',
          }
        case 'long text':
          return {
            name: parts[0].trim(),
            minWidth: "250px",
            cellType: 'comment',
          }
        case 'checkboxes':
        case 'dropdown':
        case 'multi-select dropdown':
          const cleanedChoices = parts.slice(-(parts.length - 2)).map(e => e.replace('{', '').replace('}', '').trim());
          if (!cleanedChoices || !cleanedChoices.length) {
            throw new Error(`Dynamic Matrix Input Type ${inputType} is not supported, wrong choices format ${parts[3]}`);
          }
          return {
            ...(inputType === 'multi-select dropdown' ? {
              showOtherItem: true,
              storeOthersAsComment: true
            } : {}),
            name: parts[0].trim(),
            choices: cleanedChoices,
            minWidth: "250px",
            cellType: inputType === 'dropdown' ? 'dropdown' : (inputType === 'checkboxes' ? 'checkbox' : 'tagbox'),
          }
        default:
          throw new Error(`Dynamic Matrix Input Type '${inputType}' is not supported`);
      }
    })
    .filter((e) => e);
};

const extractSubQuestion = (question, buckets) => {
  const supported = [
    'text',
    'time',
    'datetime',
    'week',
    'number',
    'date',
    'month',
    'email',
    'url',
    'color',
    'dynamic matrix',
    'month and year',
  ];
  if (!supported.includes(question['Sub-Question Input Type'])) {
    throw new Error(
      `Sub-Question Input Type ${question['Sub-Question Input Type']} is not supported`
    );
  }
  let subQuestion = {
    id: `${question.id}-sub`,
    indent: 3,
    name: question['Sub-Question Name'],
    title: question['Sub Question on Survey'],
    description: question['Sub-Question Description'],
    isRequired: !!question['Sub-Question Required'],
    section: buckets.find(
      (bucket) => bucket.id === question['Terrain Ten Bucket'][0]
    ).name,
  };
  if (question['Sub-Question Input Type'] === 'dynamic matrix') {
    subQuestion = {
      ...subQuestion,
      type: 'matrixdynamic',
      columnLayout: 'horizontal',
      columns: question['Sub-Question Matrix'] ? extractDynamicMatrixColumns(question['Sub-Question Matrix']) : [
        {
          name: 'Value',
          cellType: 'text',
          inputType: 'text',
        },
      ],
      minRowCount: 1,
      rowCount: 1,
      choices: [1],
      cellType: 'comment',
      confirmDelete: true,
      addRowText: 'Add +',
      removeRowText: 'Remove',
    };
  } else {
    subQuestion['type'] = 'text';
    if (question['Sub-Question Input Type'] === 'month and year') {
      subQuestion['inputType'] = 'month';
      subQuestion['dateFormat'] = 'mm/yyyy';
    } else {
      subQuestion['inputType'] = question['Sub-Question Input Type'] || 'text';
    }
  }
  if (question['Conditional Logic']) {
    subQuestion.visibleIf = question['Conditional Logic'];
    return subQuestion;
  }
  return subQuestion;
};

const mapQuestion = (question, buckets) => {
  let returnQuestion = {};
  switch (question['Question Type']) {
    case 'time':
      returnQuestion = {
        type: 'text',
        inputType: 'time',
        ...genericQuestion(question, buckets),
      };
      break;
    case 'number':
      returnQuestion = {
        type: 'text',
        inputType: 'number',
        validators: [{ type: 'numeric', text: 'Value must be a number' }],
        ...genericQuestion(question, buckets),
      };
      break;
    case 'date':
      returnQuestion = {
        type: 'text',
        inputType: 'date',
        ...genericQuestion(question, buckets),
      };
      break;
    case 'text':
      returnQuestion = {
        type: 'text',
        ...genericQuestion(question, buckets),
      };
      break;
    case 'panel':
      const { subPanels, ...questionWithoutSubPanel } = question;
      const newQuestion = {
        ...questionWithoutSubPanel,
        type: 'panel',
        title: questionWithoutSubPanel.name,
      };
      newQuestion.elements = question.elements
        .map((el) => {
          return mapQuestion(el, buckets);
        })
        .flat(1);
      if (question.subPanels) {
        question.subPanels.forEach((subPanel) => {
          const newQuestionSub = {
            ...subPanel,
            seq: subPanel['Seq #'],
            type: 'panel',
            title: subPanel.name,
          };
          newQuestionSub.elements = newQuestionSub.elements
            .map((el) => {
              return mapQuestion(el, buckets);
            })
            .flat(1);
          newQuestion.elements.splice(
            newQuestion.elements.indexOf((el) => el.seq > subPanel['Seq #']),
            0,
            newQuestionSub
          );
        });
      }
      newQuestion.elements.sort((a, b) => a.seq - b.seq);
      const logic = question.elements.filter(
        (el) => el['Panel and Sub-Panel Conditional Logic']
      );
      if (logic.length) {
        newQuestion.visibleIf =
          logic[0]['Panel and Sub-Panel Conditional Logic'];
      }
      returnQuestion = newQuestion;

      break;
    case 'checkbox':
    case 'checkbox without None':
    case 'radiogroup without None':
    case 'radiogroup':
      if (!question['Choices']) {
        throw new Error(
          `Radio ${question.id} - ${question.Question} has no choices`
        );
      }
      const choices = question['Choices'].split(';').map((el) => el.trim());
      // e.g. "Score Choices": "1, 2, 3, 4, 5"
      const choicesScore = question['Score Choices']
        ? question['Score Choices']
          .split(';')
          .map((el) => el.trim())
          .map((el) => Number(el))
        : [];
      const updatedQuestion = {
        type: question['Question Type'],
        ...genericQuestion(question, buckets),
        ...(choices.length < 3 ? {} : { colCount: choices.length > 8 ? 3 : 2 }),
        choices: choices.map((choice, index) => {
          return {
            value: choice,
            text: choice,
            score: choicesScore.at(index),
          };
        }),
      };
      if (question['Score Type'] !== 'Overall') {
        const { score, ...returnQuestionNoScore } = updatedQuestion;
        returnQuestion = returnQuestionNoScore;
      } else {
        returnQuestion = updatedQuestion;
      }
      returnQuestion.showNoneItem = question['Question Type'] === 'checkbox';
      break;
    case 'dynamic matrix':
    case 'singular matrix':
      const columnsDynamicMatrix = extractDynamicMatrixColumns(question['Matrix']);
      if (!columnsDynamicMatrix || !columnsDynamicMatrix.length) {
        throw new Error(
          `Dynamic Matrix ${question.id} - ${question.Question} has no columns`
        );
      }
      const updatedQuestionDynamicMatrix = {
        ...genericQuestion(question, buckets),
        type: 'matrixdynamic',
        allowAddRows: question['Question Type'] === 'dynamic matrix',
        columnLayout: 'horizontal',
        columns: columnsDynamicMatrix,
        minRowCount: 1,
        rowCount: 1,
        choices: [1],
        cellType: 'comment',
      };
      returnQuestion = updatedQuestionDynamicMatrix;
      break;
    case 'matrix-multi':
      const updatedQuestionMatrix = genericQuestion(
        question.elements[0],
        buckets
      );
      updatedQuestionMatrix.type = 'matrix';
      const choicesScoreMatrix = question.elements[0]['Score Choices']
        ? question.elements[0]['Score Choices']
          .split(';')
          .map((el) => el.trim())
          .map((el) => Number(el))
        : [];
      updatedQuestionMatrix.columns = question.elements[0].Choices.split(';')
        .map((el) => el.trim())
        .map((el, index) => {
          return {
            value: el,
            text: el,
            score: choicesScoreMatrix.at(index),
          };
        });
      if (question.elements[0]['Multi-Question Title and Choices']) {
        updatedQuestionMatrix.title = question.elements[0][
          'Multi-Question Title and Choices'
        ]
          .split('|')[0]
          .trim();
      }
      updatedQuestionMatrix.rows = question.elements
        .filter(
          (element) =>
            !element['Multi-Question Title and Choices'].startsWith('NA -')
        )
        .map((element) => {
          // e.g. "Multi-Question Title and Choices": "Do you have any of the following? |  Asthma",
          const choices =
            element['Multi-Question Title and Choices'].split('|');
          if (choices.length !== 2) {
            throw new Error(
              `Checkbox Multi ${question.id} - ${question.Question} has wrong format`
            );
          }
          return {
            value: choices[1].trim(),
            text: choices[1].trim(),
            score: element.Score,
          };
        });
      if (question['Score Type'] !== 'Overall') {
        const { score, ...returnQuestionNoScore } = updatedQuestionMatrix;
        returnQuestion = returnQuestionNoScore;
      } else {
        returnQuestion = updatedQuestionMatrix;
      }
      returnQuestion.isAllRowRequired = true;
      break;
    case 'checkbox without None-multi':
    case 'checkbox-multi':
      returnQuestion = genericQuestion(question.elements[0], buckets);
      returnQuestion.showNoneItem = question['Question Type'] === 'checkbox-multi';
    //pass through
    case 'radio-multi':
      if (question.elements.length < 2) {
        throw new Error(
          `Checkbox Multi ${question.elements[0].id} - ${question.elements[0].Question} has less than 2 options`
        );
      }
      returnQuestion.title = question.elements[0][
        'Multi-Question Title and Choices'
      ]
        .split('|')[0]
        .trim();
      returnQuestion.type = question['Question Type'] === 'checkbox without None-multi' ? 'checkbox' : question.elements[0]['Question Type'];
      returnQuestion.choices = question.elements.map((element) => {
        // e.g. "Multi-Question Title and Choices": "Do you have any of the following? |  Asthma",
        const choices = element['Multi-Question Title and Choices'].split('|');
        if (choices.length !== 2) {
          throw new Error(
            `Checkbox Multi ${question.id} - ${question.Question} has wrong format`
          );
        }
        return {
          value: choices[1].trim(),
          text: choices[1].trim(),
          score: element.Score,
        };
      });
      if (returnQuestion.choices.length > 3) {
        returnQuestion.colCount = returnQuestion.choices.length > 8 ? 3 : 2;
      }
      break;
    case 'boolean':
      returnQuestion = {
        type: 'boolean',
        ...genericQuestion(question, buckets),
      };
      break;
    default:
      throw new Error(
        `Question Type ${question['Question Type']} is not supported, for id ${question.id} - ${question.Question}`
      );
  }

  if (question['Sub-Question Name']) {
    if (question['Sub-Question Input Type']) {
      return [returnQuestion, extractSubQuestion(question, buckets)];
    } else {
      throw new Error(
        `Sub-Question Type ${question['Sub-Question Name']} is missing 'Sub-Question Input Type'`
      );
    }
  } else if (question['Conditional Logic']) {
    returnQuestion.visibleIf = question['Conditional Logic'];
  } else if (question.elements && question.elements[0]['Conditional Logic']) {
    if (question.elements[0]['Sub-Question Name']) {
      if (question.elements[0]['Sub-Question Input Type']) {
        return [
          returnQuestion,
          extractSubQuestion(question.elements[0], buckets),
        ];
      } else {
        throw new Error(
          `Sub-Question Type ${question.elements[0]['Sub-Question Name']} is missing 'Sub-Question Input Type'`
        );
      }
    }
    returnQuestion.visibleIf = question.elements[0]['Conditional Logic'];
  }
  return returnQuestion;
};

const fetchAllRecords = async (url, records = [], offset = '') => {
  const { data } = await axios.get(offset ? `${url}&offset=${offset}` : url, {
    headers: {
      Authorization: `Bearer patBXdoJZG3Kzufpv.1eed8a835d2bde77751506e3728dd6e35615a861d2ca5d6e6105bd2d3702d8b4`,
    },
  });
  records = [...records, ...data.records];
  if (data.offset) {
    // wait for few seconds to avoid rate limit
    await new Promise((resolve) => setTimeout(resolve, 200));
    return fetchAllRecords(url, records, data.offset);
  }

  return records;
};

const run = async (url) => {
  try {
    const pages = {};
    const jsonSurvey = {
      title: 'Scoring Test Survey',
      logoPosition: 'right',
      pages: [],
    };
    const insertIntoPages = (page, question) => {
      if (!pages[page]) {
        pages[page] = [];
      }
      pages[page].push(question);
    };
    // https://airtable.com/appm0O3SmgVN4ZPYV/tblPJmfS09X0aWQKO/viwfr1wwD5IkYNqoL?blocks=hide
    const tenBuckets = await axios.get(
      'https://api.airtable.com/v0/appm0O3SmgVN4ZPYV/tblPJmfS09X0aWQKO?view=viwfr1wwD5IkYNqoL',
      {
        headers: {
          Authorization: `Bearer patBXdoJZG3Kzufpv.1eed8a835d2bde77751506e3728dd6e35615a861d2ca5d6e6105bd2d3702d8b4`,
        },
      }
    );
    const allBuckets = tenBuckets.data.records.map((record) => {
      return {
        id: record.id,
        name: record.fields['Terrain Ten Area'],
        abbreviation: record.fields['Abbreviation'],
      };
    });
    // Full:  https://airtable.com/appm0O3SmgVN4ZPYV/tblhKn1YoDu34SHDy/viwWdiNDneOclPEb7?blocks=hide
    const allRecords = await fetchAllRecords(url);
    const records = allRecords.map((record) => {
      return { id: record.id, ...record.fields };
    });
    await schema.validate(records);
    records.forEach((record) => {
      insertIntoPages(record.Page, record);
    });
    // validate pages sequentially

    const allPages = Object.keys(pages)
      .map((el) => Number(el))
      .sort((a, b) => a - b);

    for (let i = 0; i < allPages.length; i++) {
      if (allPages[i] !== i) {
        throw new Error(`Page ${i} is missing. All pages: ${allPages}`);
      }
    }
    const mapVisibility = (el, parsedQuestions) => {
      if (el.type === 'panel') {
        if (el.visibleIf) {
          el.visibleIf = mapConditionLogic(
            el.visibleIf,
            undefined,
            parsedQuestions
          );
        }
        el.element = el.elements.map((element) => {
          return mapVisibility(element, parsedQuestions);
        });
        return el;
      }
      if (el.visibleIf) {

        el.visibleIf = mapConditionLogic(
          el.visibleIf,
          undefined,
          parsedQuestions
        );
      }
      return el;
    };

    jsonSurvey.pages = allPages.map((page) => {
      // validate Seq # sequentially
      if (hasDuplicates(pages[page].map((question) => question['Seq #']))) {
        throw new Error(`Page ${page} has duplicate Seq #`);
      }
      const _elements = pages[page]
        .sort((a, b) => a['Seq #'] - b['Seq #'])
        .reduce((newArray, element) => {
          // group multi questions with the same name
          if (
            (element['Question Type'] === 'checkbox' &&
              element['Multi-Question Title and Choices']) ||
            (element['Question Type'] === 'checkbox without None' &&
              element['Multi-Question Title and Choices']) ||
            (element['Question Type'] === 'radio' &&
              element['Multi-Question Title and Choices']) ||
            element['Question Type'] === 'matrix'
          ) {
            const multi = newArray.find(
              (el) =>
                el['Question Type'] === `${element['Question Type']}-multi` &&
                el.name === element['Question Name']
            );
            if (multi) {
              multi.elements = multi.elements.concat([element]);
            } else {
              newArray.push({
                'Question Type': `${element['Question Type']}-multi`,
                Panel: element.Panel,
                name: element['Question Name'],
                elements: [element],
                'Seq #': element['Seq #']
              });
            }
          } else {
            newArray.push(element);
          }
          return newArray;
        }, [])
        .reduce((newArray, element) => {
          // group panel's questions
          if (element.Panel) {
            const panel = newArray.find(
              (el) =>
                el['Question Type'] === 'panel' && el.name === element.Panel[0]
            );

            function handlePanelDescription(element, panelType) {
              return element.Description?.toLowerCase().includes(`${panelType.toLowerCase()} |`)
                ? {
                  description: element.Description.split('|')[1].trim(),
                }
                : {};
            }

            if (panel) {
              const modifiedElement = {
                ...element,
                ...noDescription(element),
              };
              if (element.Panel.length === 2) {
                if (panel.subPanels) {
                  if (
                    panel.subPanels.find(
                      (el) => el.nameOrig === element.Panel[1]
                    )
                  ) {
                    panel.subPanels[panel.subPanels.length - 1].elements.push(
                      modifiedElement
                    );
                    panel.subPanels[panel.subPanels.length - 1].elements =
                      panel.subPanels[panel.subPanels.length - 1].elements.sort(
                        (a, b) => a['Seq #'] - b['Seq #']
                      );
                    Object.assign(
                      panel.subPanels[panel.subPanels.length - 1],
                      handlePanelDescription(element, 'sub - panel')
                    );
                  } else {
                    panel.subPanels.push({
                      nameOrig: element.Panel[1],
                      'Question Type': 'panel',
                      ...(element['Panel and Sub-Panel Conditional Logic']
                        ? {
                          visibleIf: mapConditionLogic(
                            element['Panel and Sub-Panel Conditional Logic'],
                            1
                          ),
                        }
                        : {}),
                      name: element.Panel[1]
                        .replace('SUB - ', '')
                        .replace('Sub - ', ''),
                      'Seq #': element['Seq #'],

                      nameOrig: element.Panel[1],
                      elements: [modifiedElement],
                      ...handlePanelDescription(element, 'sub - panel'),
                    });
                  }
                  panel.subPanels = panel.subPanels.sort(
                    (a, b) => a['Seq #'] - b['Seq #']
                  );
                } else {
                  panel.subPanels = [
                    {
                      nameOrig: element.Panel[1],
                      'Seq #': element['Seq #'],
                      'Question Type': 'panel',
                      ...(element['Panel and Sub-Panel Conditional Logic']
                        ? {
                          visibleIf: mapConditionLogic(
                            element['Panel and Sub-Panel Conditional Logic'],
                            1
                          ),
                        }
                        : {}),
                      name: element.Panel[1]
                        .replace('SUB - ', '')
                        .replace('Sub - ', ''),
                      elements: [modifiedElement],
                      ...handlePanelDescription(element, 'sub - panel'),
                    },
                  ];
                }
              } else {
                panel.elements.push(modifiedElement);
                panel.elements.sort((a, b) => a['Seq #'] - b['Seq #']);
                Object.assign(panel, handlePanelDescription(element, 'PANEL'));
              }
            } else {
              newArray.push({
                'Question Type': 'panel',
                name: element.Panel[0],
                'Seq #': element['Seq #'],
                elements: [
                  {
                    ...element,
                    ...noDescription(element),
                  },
                ],
                ...(element['Panel and Sub-Panel Conditional Logic']
                  ? {
                    visibleIf: mapConditionLogic(
                      element['Panel and Sub-Panel Conditional Logic']
                    ),
                  }
                  : {}),
                ...(element.description &&
                  element.description.includes('PANEL |')
                  ? { description: element.description.split('|')[1] }
                  : {}),
              });
            }
          } else {
            newArray.push(element);
          }
          return newArray;
        }, [])
        .filter((el) => el && el !== undefined)
        .sort((a, b) => a['Seq #'] - b['Seq #'])
        .map((el) => {
          return mapQuestion(el, allBuckets);
        })
        .flat(1);
      return {
        name: `Page ${page}`,
        title: _elements.find((el) => el.section)?.section,
        elements: _elements,
      };
    });
    const allElements = jsonSurvey.pages.map((page) => page.elements).flat(1).map((el) => {
      if (el.type === 'panel') {
        return el.elements;
      }
      return el;
    }).flat(1);

    jsonSurvey.pages = jsonSurvey.pages.map((page) => {
      const _elements = page.elements.map((el) => mapVisibility(el, allElements));
      return {
        ...page,
        elements: _elements,
      };
    });
    // Verify that all Condition references are valid
    const allParsedQuestions = jsonSurvey.pages
      .map((page) =>
        page.elements.map((el) => {
          return el.elements && el.elements.length ? el.elements : el;
        })
      )
      .flat(1);

    const allRefs = allParsedQuestions
      .filter((el) => el.visibleIf)
      .map((el) => el.visibleIf)
      .map((el) => /({)(.*)(})/.exec(el)[2]);
    const allNames = allParsedQuestions.map((el) => el['Question Name']);
    allRefs.forEach((name) => {
      if (allNames.includes(name)) {
        throw new Error(
          `Question ${name} is referenced in Condition Logic but not found in the survey`
        );
      }
    });
    return jsonSurvey;
  } catch (error) {
    captureException(error);
    console.error(error);
    throw error;
  }
};

export default run;

export const fetchAirTable = run;
