import { useState } from 'react';


export const useGroupVisibilityToggle = <T>(
    groups: T[],
    hideAll: boolean = true
): [
    T[],
    (groupName: T) => void,
] => {
    const [hiddenGroups, setHiddenGroups] = useState(hideAll ? groups : []);

    const toggleGroupVisibility = (groupName: T) => {
        const updatedHiddenGroups =
            hiddenGroups.includes(groupName)
                ? hiddenGroups.filter(hiddenGroup => hiddenGroup !== groupName)
                : [...hiddenGroups, groupName];

        setHiddenGroups(updatedHiddenGroups);
    };

    return [hiddenGroups, toggleGroupVisibility];
};
