import { ReactNode } from 'react';
import { ArrowDown, ArrowRight } from '../Icons';


interface Props {
    children: ReactNode,
    onClick: () => void,
    isCollapsed: boolean,
    colspan?: number,
}


export const TableGroupHeader = ({ children, onClick, isCollapsed, colspan = 6 }: Props) => (
    <tr>
        <td
            colSpan={colspan}
            onClick={onClick}
            className="whitespace-nowrap px-4 py-5 text-sm text-left select-none cursor-pointer bg-basic/50"
        >
            <div className="flex flex-nowrap items-center justify-start font-semibold capitalize">
                {children}
                {isCollapsed ? <ArrowRight /> : <ArrowDown />}
            </div>
        </td>
    </tr>
);
