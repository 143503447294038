import { Body, Head, Table, Td, Th } from '../../components/Table';
import { SurveyStatus } from '../../components/SurveyStatus';
import { Link } from '../../components/Link';

interface Props {
  surveys: {
    id: string,
    name: string,
    status: 'published',
  }[]
}

export const SurveyListSimple = ({ surveys }: Props) => {
  return (
    <Table>
      <Head>
        <Th>Name</Th>
        <Th align="center">Status</Th>
        <Th align="center">Actions</Th>
      </Head>

      <Body>
        {surveys.map((survey) => (
          <tr key={survey.id} className="even:bg-basic/10">
            <Td>
              {survey.name}
            </Td>

            <Td align="center">
              <SurveyStatus status={survey.status} type="editorial" />
            </Td>

            <Td align="center">
              <Link to={`/create/survey/${survey.id}?name=` + encodeURIComponent(survey.name)}>Take survey</Link>
            </Td>

          </tr>
        ))}
      </Body>
    </Table>
  );
};
