import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const useUpdateSurveyProgress = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (survey: { [k: string]: unknown }) => {
            return sendRequest(`user/survey/${survey.id}`, {
                body: survey,
                method: HttpMethod.PUT,
            });
        },
        onSuccess: () => queryClient.invalidateQueries(), // @todo: uh?
        onError: (error) => console.log(error),
        retry: 3,
    });
};
