import { ComponentProps } from 'react';
import { UUID } from 'crypto';
import { Modal } from '../../../components/Modal';
import { UserSearchForm } from '../UserSearchForm';
import { Button } from '../../../components/Button/index';

type Props = { label: string; onClick: (userId: UUID) => void } & Omit<ComponentProps<typeof Modal>, 'children'>;

export const UserSearchModal = ({ label, isOpen, onClick, onClose }: Props) => {
    return (
        <Modal
            size="large"
            isOpen={isOpen}
            onClose={onClose}
        >
            <UserSearchForm onSubmit={({ userId }) => onClick(userId)} assignLabel={label} />
            <div className="flex flex-auto flex-col justify-between my-4">
                <Button onClick={onClose}>Close</Button>
            </div>
        </Modal>
    );
};
