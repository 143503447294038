import { UUID } from 'crypto';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { SurveyStatus } from '@/components/SurveyStatus';
import { SurveyProgress } from '@/components/SurveyProgress';
import { Td } from '@/components/Table';
import { Pdf } from '@/components/Icons/Pdf';
import { Excel } from '@/components/Icons/Excel';
import { Trash } from '@/components/Icons/Trash';
import { DocumentMagnifyingGlass } from '@/components/Icons/DocumentMagnifyingGlass';
import { Button } from '@/components/Button/index';
import { Link } from '@/components/Link';
import { LinkButton } from '@/components/LinkButton';
import { useReportDownload } from '@/hooks/useReportDownload';
import { ButtonWithConfirmation } from '@/components/ButtonWithConfirmation';
import { formatDate, formatName } from '@/helpers/index';
import { Invitation, AllowedSurveyActions } from '../../../../types';
import { isExcelReportEnabled } from '@/helpers/report';

interface Props extends Invitation {
    allowedActions: AllowedSurveyActions[];
    onClick: (invitationId: UUID) => void;
    onDelete?: (id: UUID) => void;
    onUserSearchClick?: (surveyId: UUID) => void;
}

export const TableRow = ({ allowedActions, onClick, onDelete, onUserSearchClick, ...invitation }: Props) => {
    const downloadReport = useReportDownload();
    const hasSendReminderAction = ['sent', 'assigned', 'started'].includes(invitation.status) && Boolean(invitation.invitationId);
    const deleteConfirmationMessage = `Delete invitation for ${formatName(invitation, { separator: ' ', order: 'asc' })}?`;

    const isActionAllowed = (action: AllowedSurveyActions) => allowedActions.includes(action);

    return (
        <tr className="odd:bg-basic/10">
            <Td>
                {formatName(invitation)}
                <div className="text-xs text-gray-400">{invitation.email}</div>
            </Td>

            <Td>{invitation.surveyName}</Td>

            <Td align="center">
                <SurveyStatus status={invitation.status} />
            </Td>

            <Td>{formatDate(invitation.date)}</Td>

            <Td align="center">
                <SurveyProgress progress={invitation.completion} status={invitation.status} />
            </Td>

            <Td align="center">
                <div className="flex flex-row justify-center items-center gap-1">
                    {hasSendReminderAction && isActionAllowed(AllowedSurveyActions.SEND_REMINDER) && (
                        <Link onClick={() => onClick(invitation.invitationId)}>Send reminder</Link>
                    )}

                    {invitation.status === 'finished' && isActionAllowed(AllowedSurveyActions.VIEW_RESULTS) && (
                        <>
                            <LinkButton size="small" title="View results" to={`/advocate/survey/${invitation.surveyId}`}>
                                <span className="text-slate-600 hover:text-slate-800">
                                    <DocumentMagnifyingGlass />
                                </span>
                            </LinkButton>

                            <Button size="small" title="Download as pdf" onClick={() => downloadReport('pdf', invitation.surveyId!)}>
                                <span className="text-red-600 hover:text-red-900">
                                    <Pdf />
                                </span>
                            </Button>

                            {isExcelReportEnabled(invitation.surveyId!) && (
                                <Button size="small" title="Download as excel" onClick={() => downloadReport('excel', invitation.surveyId!)}>
                                    <span className="text-green-600 hover:text-green-900">
                                        <Excel />
                                    </span>
                                </Button>
                            )}
                        </>
                    )}

                    {invitation.status === 'finished' && onUserSearchClick && isActionAllowed(AllowedSurveyActions.RE_ASSIGN) && (
                        <Button size="small" title="Re-assign" onClick={() => onUserSearchClick(invitation.surveyId!)}>
                            <UserCircleIcon className="p-0.5 text-slate-600 hover:text-slate-800" aria-hidden="true" />
                        </Button>
                    )}

                    {onDelete && isActionAllowed(AllowedSurveyActions.DELETE) && (
                        <ButtonWithConfirmation
                            message={deleteConfirmationMessage}
                            onConfirm={() => onDelete(invitation.invitationId || invitation.surveyId)}
                        >
                            <Trash className="text-red-600 hover:text-red-900" />
                        </ButtonWithConfirmation>
                    )}
                </div>
            </Td>
        </tr>
    );
};
