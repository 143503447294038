import { format, fromUnixTime } from 'date-fns';
import { AdvocateInvitation, Invitation, Survey, User, SystemSurvey } from '../types';
import { UUID } from 'crypto';

const STATUS_MAP = {
    sent: 0,
    assigned: 1,
    started: 2,
    finished: 3,
    registered: 4,
};

export const extractUUID = (str: string) => {
    const regex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const match = str.match(regex);

    return match?.[0] as UUID | null;
};

export const compareCaseInsensitive = (a: unknown, b: unknown) => {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
    }

    return a === b;
};

export const compareByFullName = <T extends GetFullNameParams>(a: T, b: T) => {
    if (!a) return 1;
    if (!b) return -1;

    const nameA = formatName(a);
    const nameB = formatName(b);

    if (!nameA) return 1;
    if (!nameB) return -1;

    return nameA.localeCompare(nameB);
};

export const sortByStatus = <T extends { status: keyof typeof STATUS_MAP }>(a: T, b: T) => STATUS_MAP[a.status] - STATUS_MAP[b.status];

export const sortByInvitiationSender =
    (sender: 'advocate' | 'clinician') =>
    <T extends { [k in typeof sender]: User }>(a: T, b: T) =>
        compareByFullName(a[sender], b[sender]);

export const groupBy = <T>(predicate: (item: T) => string, items: T[]) => {
    return items.reduce((acc, item) => {
        const value = predicate(item);

        return {
            ...acc,
            [value]: [...(acc[value] || []), item],
        };
    }, {} as { [key: string]: T[] });
};

export const formatDate = (date: Date | number) => {
    const value = typeof date === 'number' ? fromUnixTime(date) : date;

    return format(value, 'yyyy-MM-dd HH:mm');
};

type GetFullNameParams = Partial<Pick<User, 'firstName' | 'lastName'>>;
type GetFullNameOptions = Partial<{ separator: string; order: 'asc' | 'dsec' }>;

export const formatName = <T extends GetFullNameParams>(params: T, options: GetFullNameOptions = {}) => {
    const { separator = ', ', order = 'desc' } = options;
    let { firstName, lastName } = params;

    if (order === 'asc') {
        [firstName, lastName] = [lastName, firstName];
    }

    if (firstName && lastName) {
        return `${lastName}${separator}${firstName}`;
    }

    return '';
};

export const getCurrentTimestamp = () => (Date.now() / 1000) | 0;

export const getInvitationDisplayName = (selectedSurveys: SystemSurvey[]) => {
    const mainSurvey = selectedSurveys.find(el => el.airtable) || selectedSurveys[0];
    return mainSurvey?.name + (selectedSurveys.length >= 2 ? ` & ${selectedSurveys.length - 1} more` : '');
}

export const transformInvitation = ({ invitation, survey }: Partial<{ invitation: Invitation; survey: Survey }>) => {
    const firstName = survey?.user?.firstName || invitation?.firstName;
    const lastName = survey?.user?.lastName || invitation?.lastName;
    const displayName = survey?.name || (invitation?.surveyName || getInvitationDisplayName(invitation?.systemSurveys || []));
    const result = {
        id: invitation?.id || survey?.id,
        invitationId: invitation?.id,
        surveyId: survey?.id,
        userSurveyId: survey?.id,
        firstName,
        lastName,
        email: survey?.user?.email || invitation?.email,
        surveyName: displayName,
        date: survey?.updated || invitation?.date,
        status: survey?.status || invitation?.status || 'sent',
        completion: survey?.completion || 0,
        advocate: invitation?.advocate,
        surveyIds: invitation?.surveyIds || []
    };

    return { ...result, fullName: formatName(result) };
};

export const findMatchingInvitation = (survey: Survey, invitations: Invitation[]) =>
    invitations.find((invitation) => compareCaseInsensitive(invitation.email, survey.user?.email));

export const isInvitationMatchedWithSurvey = (advocateSurveys: Survey[], invitation: Invitation) =>
    !!advocateSurveys?.find((survey) => compareCaseInsensitive(survey.user?.email, invitation.email));

const processAdvocate = (advocate: User) => {
    const { advocateSurveys = [], invitations = [] } = advocate;

    const surveysMapped = advocateSurveys.map((survey) => ({ survey, invitation: findMatchingInvitation(survey, invitations) }));
    const unmatchedInvitations = invitations.filter((invitation) => !isInvitationMatchedWithSurvey(advocateSurveys, invitation));
    const invitationsMapped = unmatchedInvitations.map((invitation) => ({ invitation }));

    const result = [...surveysMapped, ...invitationsMapped].map((item) => transformInvitation(item));

    return [advocate, result] as [User, Invitation[]];
};

export const transformInvitationGroupList = (advocates: User[]) => {
    return advocates.map(processAdvocate);
};

export const copyToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
};

export const cx = (...classes: any[]) => classes.filter(Boolean).join(' ');

export const isBoolean = (value: any): value is boolean => typeof value === 'boolean';

export const isString = (value: any): value is string => typeof value === 'string';

export const findAdvocateById = (advocates: User[], advocateId: string) => advocates.find((advocate) => advocate.userId === advocateId);

export const findInvitationById = (invitations: Invitation[], invitationId: string) => invitations.find((invitation) => invitation.id === invitationId);

export const findAdvocateInvitationById = (invitations: AdvocateInvitation[], invitationId: string) =>
    invitations.find((invitation) => invitation.id === invitationId);

export const getSortDirection = (key: string, currentKey: string, isReverse: boolean) => {
    if (key === currentKey) {
        return isReverse ? 'desc' : 'asc';
    }

    return 'none';
};

export const pluralize = (n: number, variants: [singular: string, plural: string]) => n === 1 ? variants[0] : variants[1]
