import { Routes, Route, Navigate } from 'react-router-dom';
import { Navigation } from '@/widgets/Navigation';
import { useAuth, withAuth } from '@/hooks/useAuth';
import * as Pages from '@/pages';

function App() {
    const { isPatient, isAdmin, isClinician, isAdvocate, isManager } = useAuth();

    return (
        <div className="flex">
            <Navigation />

            <main className="p-4 text-sm shadow w-full">
                <Routes>
                    {isPatient && (
                        <>
                            <Route path="/" element={<Pages.PatientSurveyList />} />
                            <Route path="/advocate/survey/:slug" element={<Pages.AdvocateSurveyDetails />} />
                        </>
                    )}

                    {(isClinician || isAdvocate) && (
                        <>
                            <Route path="/" element={<Pages.PatientList />} />
                            <Route path="/surveys" element={<Pages.PatientList />} />
                            <Route path="/advocate/survey/:slug" element={<Pages.AdvocateSurveyDetails />} />
                        </>
                    )}

                    {isManager && (
                        <>
                            <Route path="/" element={<Pages.AdminInvitations />} />
                            <Route path="/surveys" element={<Pages.AdminInvitations />} />
                            <Route path="/manager/users" element={<Pages.UserListPage />} />
                            <Route path="/admin/advocate/invitations/:slug" element={<Pages.AdminAdvocateInvitations />} />
                        </>
                    )}

                    {isAdmin && (
                        <>
                            <Route path="/" element={<Pages.AdminInvitations />} />
                            <Route path="/surveys" element={<Pages.AdminInvitations />} />
                            <Route path="/admin/new/survey/:slug" element={<Pages.NewSurveyEditor />} />
                            <Route path="/admin/advocate/invitations/:slug" element={<Pages.AdminAdvocateInvitations />} />
                            <Route path="/admin/survey/edit/:slug" element={<Pages.EditSurvey />} />
                            <Route path="/admin/survey/versions/:slug" element={<Pages.SurveyVersions />} />
                            <Route path="/advocate/survey/:slug" element={<Pages.AdvocateSurveyDetails />} />
                            <Route path="/admin/users" element={<Pages.UserListPage />} />
                            <Route path="/admin/actions" element={<Pages.UserActionListPage />} />
                        </>
                    )}

                    <Route path="/admin/surveys" element={<Pages.AdminSurveysList />} />
                    <Route path="/allsurveys" element={<Pages.AllPublishedSurveys />} />
                    <Route path="/create/survey/:slug" element={<Pages.TakeNewSurvey />} />
                    <Route path="/survey/:slug" element={<Pages.Survey />} />

                    <Route path="/new/survey/:proxy" element={<Navigate to="/" />} />
                    <Route path="*" element={<Pages.NotFound />} />
                </Routes>
            </main>
        </div>
    );
}

export default withAuth(App);
