import { ComponentProps } from 'react';
import { cx } from '../../helpers';
import { ButtonType, ButtonSize, ButtonWidth } from '../Button/index';
import { Link } from '../Link';

interface Props extends Omit<ComponentProps<typeof Link>, 'theme'> {
    theme?: keyof typeof ButtonType;
    size?: keyof typeof ButtonSize;
    width?: keyof typeof ButtonWidth;
}

export const LinkButton = ({ theme, size, width, ...props }: Props) => {
    const className = cx(
        ButtonType[theme ?? 'button'],
        ButtonSize[size ?? 'medium'],
        ButtonWidth[width ?? 'auto'],
    );

    return (
        <Link {...props} className={className}>
            {props.children}
        </Link>
    );
};
