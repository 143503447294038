import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchAirTable } from '../../services/airTable';
import { useAuth } from '../../hooks/useAuth';
import { sendRequest } from '../../services/api';

export const getAdminSurveyPath = (id: string, isVersions: boolean = false) => {
  return isVersions ? `admin/survey/versions/${id}` : `admin/survey/${id}`;
};

export const useAdminSurvey = (
  id: string,
  { isVersions, version }: { isVersions: boolean; version: string }
) => {
  const path = getAdminSurveyPath(id!, isVersions);

  return useQuery({
    queryKey: [path],
    queryFn: async () => {
      if (id === 'airtable') {
        const survey = await fetchAirTable();

        return { survey };
      }

      return sendRequest(version ? `${path}?version=${version}` : path);
    },
  });
};

export const useSurvey = (path: string) => {
  return useQuery({
    queryKey: [path],
    queryFn: () => sendRequest(path),
  });
};

export const useNewSurvey = (id: string, advocateId?: string) => useSurvey(advocateId ? `user/new/survey/${id}?advocateId=${advocateId}` : `user/new/survey/${id}`);

export const useAdvocateSurvey = (id: string) =>
  useSurvey(`advocate/survey/${id}`);

export const useUserSurvey = (id: string) => useSurvey(`user/survey/${id}`);

const usePathByUserRole = () => {
  const { isAdvocate, isClinician } = useAuth();

  if (isAdvocate || isClinician) {
    return 'advocate/survey';
  }

  return 'user/survey';
};

export const useSurveyMutation = () => {
  const path = usePathByUserRole();

  return useMutation({
    mutationFn: ({ surveyId }: { surveyId: string }) => sendRequest(`${path}/${surveyId}`),
  });
};
