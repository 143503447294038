// list of user aciton logs entries
import { Body, Head, Table, Td, Th } from '../../components/Table';
import { formatDate, formatName } from '../../helpers';

interface Props {
    userActionLog: {
        id: string;
        type: string;
        body: any;
        user: {
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            role: string;
        };
        updated: number;
    }[];
}

const userActionTypeLabels: { [key: string]: string } = {
    'CREATE_SURVEY': 'Created survey',
    'SEND_SURVEY_REMINDER': 'Sent survey reminder',
    'INVITE_ADVOCATE': 'Invited advocate',
    'DELETE_SURVEY': 'Deleted survey',
    'DELETE_INVITATION': 'Deleted invitation',
    'DELETE_ADVOCATE_INVITATION': 'Deleted advocate invitation',
    'DELETE_USER': 'Deleted user',
    'USER_ASSIGN_ROLE': 'Assigned role',
    'USER_REMOVE_ROLE': 'Removed role',
};

export const UserActionLog = ({ userActionLog }: Props) => (
    <Table>
        <Head>
            <Th>User</Th>
            <Th align="center">Type</Th>
            <Th>Created</Th>
        </Head>

        <Body>
            {userActionLog.map((logEntry) => (
                <tr key={logEntry.id} className="even:bg-basic/10">
                    <Td>
                        {formatName(logEntry.user, { separator: ' ', order: 'asc' })} - [{logEntry.user.role}]

                        <div className="text-gray-400">{logEntry.user.email}</div>
                    </Td>

                    <Td align="center">
                        {userActionTypeLabels[logEntry.type]}
                    </Td>

                    <Td>{formatDate(logEntry?.updated)}</Td>
                </tr>
            ))}
        </Body>
    </Table>
);
