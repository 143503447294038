import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAdminSurveyPath } from "../useSurvey";
import { HttpMethod, sendRequest } from "../../services/api";

interface Data {
    id: string,
    updated: number,
    name: string,
    status: 'published',
}

export const useCreateSurvey = () => {
    const path = 'admin/survey';
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: Data) => {
            return sendRequest(path, { body, method: HttpMethod.POST });
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries();

            queryClient.setQueryData(
                [getAdminSurveyPath((data as { id: string }).id, false)],
                () => ({ survey: data }),
            );
        },
        onError: (error) => {
            console.log(error);
        },
        retry: 3,
    });
}
