import { ComponentProps, ReactNode } from 'react';
import { Modal } from '../Modal';
import { Button } from '../Button/index';


type BaseProps = ComponentProps<typeof Modal>;

type Props = BaseProps & {
    onConfirm: () => void,
    onCancel: () => void,
    children: ReactNode,
    confirmButtonText?: string,
    cancelButtonText?: string,
}


export const ConfirmationModal = ({ isOpen, children, onClose, onConfirm, onCancel, confirmButtonText = 'Ok', cancelButtonText = 'Cancel' }: Props) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <div className="flex flex-col flex-wrap items-center justify-center">
            <div className="mb-6">
                {children}
            </div>
            <div className="flex gap-4">
                <Button type="submit" onClick={onConfirm}>{confirmButtonText}</Button>
                <Button onClick={onCancel}>{cancelButtonText}</Button>
            </div>
        </div>
    </Modal>
);
