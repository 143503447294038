import { useNavigate } from 'react-router-dom';
import { AdminSurveyList } from '../widgets/SurveyListAdmin';
import { useAdminSurveyList } from '../queries/useSurveyList';
import { Button } from '../components/Button/index';
import { Title } from '../components/Title';


const AdminSurveys = () => {
  const { isLoading, isError, data, error } = useAdminSurveyList();
  const navigate = useNavigate();

  return (
    <>
      {isLoading && <p> Loading ...</p>}
      {isError && <p> Error while loading: {error.message} </p>}
      {data && console.log(data)}
      {data && (
        <>
          <Title>
            All Surveys

            <Button onClick={() => navigate(`/admin/new/survey/${globalThis.crypto.randomUUID()}`)}>
              Create Survey
            </Button>
          </Title>

          <AdminSurveyList surveys={data.sort((a, b) => b.updated - a.updated)} />
        </>
      )}
    </>
  );
};

export default AdminSurveys;
