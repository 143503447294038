export const SurveyResultAnswer = ({ value }: { value: string[] | string[][] }) => {
    const children = value.map((item, index) => {
        if (Array.isArray(item))
            return (
                <div key={index} className="mb-2 even:text-gray-800/75">
                    {item.map(subitem => <p key={subitem}>{subitem}</p>)}
                </div>
            );

        return <p className="mb-2" key={item}>{item}</p>
    });

    return (
        <div>{children}</div>
    );
};
