import { useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { captureException } from '@sentry/react';
import { Link } from '@/components/Link';
import { InputField } from '@/components/Input';
import { Button } from '@/components/Button/index';
import { Spinner } from '@/components/Spinner';
import { confirmSignUp, resendSignUp } from '@/services/auth';
import { verifyEmailSchema } from '../helpers/validation';
import { useSearchParams } from 'react-router-dom';

export default function Verification({ setMode }: { setMode: (mode: 'signin') => void }) {
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);

    const email = searchParams.get('email') ?? '';

    const onSubmit = async (code: string) => {
        setLoading(true);

        try {
            if (!email) {
                throw new Error('Invalid email');
            }

            await confirmSignUp(email, code);
            setMode('signin');
        } catch (error) {
            captureException(error);
            console.error('error confirming signup', error);
            toast.error('Error submitting verification code');
        }

        setLoading(false);
    };

    const onResend = async () => {
        try {
            setLoading(true);
            await resendSignUp(email);
            toast.success('Confirmation code was successfully sent');
        } catch (error) {
            captureException(error);
            console.error('error resending confirmation code');
            toast.error('Error sending confirmation code');
        }

        setLoading(false);
    };

    return (
        <>
            <Formik
                initialValues={{ code: '' }}
                validationSchema={verifyEmailSchema}
                onSubmit={(values) => onSubmit(values.code)}
            >
                {(props) => (
                    <Form>
                        <InputField
                            label="Code"
                            name="code"
                            type="text"
                            required={true}
                            placeholder="Verification code"
                        />

                        <div className="mt-8">
                            <Button type="submit" width="full" disabled={!props.dirty || !props.isValid || isLoading}>
                                {isLoading && <Spinner />}
                                Confirm
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <p className="mt-3 text-center text-sm text-slate-800">
                Haven't received a code? <Link onClick={onResend}>Resend it</Link>
            </p>
        </>
    );
}
