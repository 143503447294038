import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

interface Data {
    surveyId: string;
    advocateId: string;
}

//useManagerSurveyAssignMutation
export const useAssignSurvey = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: Data) => {
            return sendRequest('manager/survey/' + body.surveyId, { body, method: HttpMethod.PUT });
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['manager/invitations'] });
        },
        onError: (error) => {
            console.log('manager/survey mutation error:', error);
        },
    });
};
