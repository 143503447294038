import { UUID } from 'crypto';
import { ReactNode, useEffect, useState } from 'react';
import { Field, Form, ErrorMessage as FormikErrorMessage, FormikProvider, useFormik } from 'formik';
import { useUserByEmail } from '../../queries/useUserByEmail';
import { Button } from '../../components/Button/index';
import { Spinner } from '../../components/Spinner';
import { getInitialFormValues, getInvitationValidationSchema } from './helpers';
import { formatName } from '../../helpers';
import { useMyProfile } from '../../queries/useMyProfile';
import { getLoginMessage, getRegisterMessage, getHtmlBody, isEmailValid } from '../../helpers/email';
import { SystemSurvey } from '../../types';
import { getInvitationDisplayName } from '../../helpers';
import { Select } from '@/components/Select';

const ErrorMessage = ({ name }: { name: string }) => <FormikErrorMessage component="span" className="mt-1 text-red-500 text-sm" name={name} />;

const Label = ({ title, children }: { title: string; children: ReactNode }) => (
    <label className="w-full flex flex-col font-normal text-sm text-gray-700 last:mb-0">
        <div className="mb-2">{title}</div>
        {children}
    </label>
);

type FormValues = ReturnType<typeof getInitialFormValues>;

type Props = {
    surveys: SystemSurvey[];
    onCreate: (values: FormValues & { surveyName?: string; surveyIds: string[] }) => void;
};

export const InvitationForm = ({ surveys, onCreate }: Props) => {
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState('');
    const { data: fetchedUserData, isFetching: isFetchingUserName } = useUserByEmail(email || '');
    const { data: advocate } = useMyProfile();
    const [selectedSurveyIds, setSelectedSurveyIds] = useState<UUID[]>([]);

    const selectedSurveys = selectedSurveyIds
        .map((id) => surveys.find((survey) => survey.id === id))
        .filter((item) => item !== undefined);

    const onSubmit = (values: ReturnType<typeof getInitialFormValues>) => {
        onCreate({
            ...values,
            surveyName: getInvitationDisplayName(selectedSurveys),
            surveyIds: selectedSurveys.map(el => el.id),
            message: getHtmlBody(message),
        });
    }

    const onEmailValidate = (value: string) => setEmail(isEmailValid(value) ? value : undefined);

    const formik = useFormik({
        onSubmit,
        initialValues: getInitialFormValues(),
        validationSchema: getInvitationValidationSchema(),
        validateOnBlur: true,
        validateOnChange: false,
    });

    const { values: formData, setFieldValue } = formik;

    useEffect(() => {
        if (!fetchedUserData || !fetchedUserData.firstName || !fetchedUserData.lastName) {
            return;
        }

        setFieldValue('firstName', fetchedUserData.firstName);
        setFieldValue('lastName', fetchedUserData.lastName);
    }, [fetchedUserData]);

    useEffect(() => {
        if (!fetchedUserData || !fetchedUserData.firstName) {
            return;
        }

        setFieldValue('firstName', fetchedUserData.firstName);
        setFieldValue('lastName', fetchedUserData.lastName);
    }, [fetchedUserData]);

    useEffect(() => {
        if (!selectedSurveyIds.length) {
            return;
        }

        const mainSurvey = selectedSurveys.find((survey) => survey.airtable) || selectedSurveys[0];

        const isRegisteredUser = fetchedUserData && fetchedUserData.email.localeCompare(formData.email, undefined, { sensitivity: 'accent' }) === 0;
        const fullName = formatName(formData, { separator: ' ', order: 'asc' });
        const advocateEmail = advocate?.email || '';
        const advocateFullName = formatName(advocate || {}, { separator: ' ', order: 'asc' });

        const isUserExists = fetchedUserData && fetchedUserData.firstName;

        const url = new URL(`${location.protocol}//${location.host}/`);

        if (!isUserExists) {
            url.searchParams.append('mode', 'signup');
        }

        if (formData.email) {
            url.searchParams.append('email', formData.email);
        }

        const link = url.toString();

        const surveyName = selectedSurveys.map(({ name }) => name).join(', ');
        const args = [surveyName, fullName, advocateEmail, advocateFullName, link] as const;

        const newMessage = isRegisteredUser ? getLoginMessage(...args) : getRegisterMessage(...args);

        // @todo: fix two sources of truth
        setMessage(newMessage);

        setFieldValue('message', newMessage);
        setFieldValue('subject', `You've been assigned the ${mainSurvey!.name}` + (selectedSurveyIds.length >= 2 ? ` & ${selectedSurveyIds.length - 1} more` : ''));
    }, [selectedSurveyIds, formData.firstName, formData.lastName]);

    return (
        <FormikProvider value={formik}>
            <Form action="#" className="relative flex flex-col gap-6">
                <Label title="Email address:">
                    <div className="relative">
                        <Field
                            required
                            type="email"
                            name="email"
                            placeholder="you@example.com"
                            validate={onEmailValidate}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />

                        {isFetchingUserName && (
                            <div className="text-black-100 invert absolute top-1/2 -translate-y-1/2 right-0.5 opacity-40">
                                <Spinner />
                            </div>
                        )}
                    </div>

                    <ErrorMessage name="email" />
                </Label>

                <div className="w-full flex flex-col font-normal text-sm text-gray-700 last:mb-0">
                    <div className="mb-2">Survey</div>

                    <Select
                        label="Select a survey"
                        items={surveys.map((survey) => ({ label: survey.name, value: survey.id }))}
                        selectedItems={selectedSurveyIds}
                        onSelect={setSelectedSurveyIds}
                    />

                    <ErrorMessage name="surveyId" />
                </div>

                <div className="flex justify-between gap-4">
                    <Label title="First name:">
                        <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="First Name"
                            required
                        />

                        <ErrorMessage name="firstName" />
                    </Label>

                    <Label title="Last name:">
                        <Field
                            label="Last Name"
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Last Name"
                            required
                        />

                        <ErrorMessage name="lastName" />
                    </Label>
                </div>

                <div className="relative rounded shadow-sm flex flex-col focus-within:ring-2 ring-offset-1 focus-within:ring-indigo-500">
                    <Field
                        type="text"
                        name="subject"
                        aria-label="subject"
                        placeholder="Subject"
                        className="text-lg font-normal placeholder-gray-500 rounded-t outline:none border-gray-300 focus:border-gray-300 focus:ring-0 border-b-0"
                    />

                    <div
                        className="h-[25vh] border p-3 sm:text-sm flex-grow outline:none border-gray-300 focus:ring-0 border-t-0 focus:outline-none overflow-y-scroll"
                        contentEditable={true}
                        dangerouslySetInnerHTML={{ __html: formik.values.message }}
                        onInput={(event) => setMessage(event.currentTarget.innerHTML)}
                    />

                    <div className="p-2 border-gray-300 border border-t-0 rounded-b bg-white gap-2">
                        <Button type="submit" onClick={formik.handleSubmit}>
                            Send
                        </Button>{' '}
                        <ErrorMessage name="message" />
                    </div>
                </div>
            </Form>
        </FormikProvider>
    );
};
