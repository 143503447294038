import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { Dropdown } from '@/components/Dropdown';

interface Props<T> {
    label?: string;
    items: { label: string; value: T }[];
    selectedItems?: T[];
    onSelect: (items: T[]) => void;
}

export const Select = <T,>({ items, selectedItems = [], label = 'Select', onSelect }: Props<T>) => {
    const onClick = (target: T) => {
        const updatedItems = selectedItems.includes(target)
            ? selectedItems.filter((item) => item !== target)
            : [...selectedItems, target];

        onSelect(updatedItems);
    };

    return (
        <div className="w-full" role="combobox" aria-haspopup="listbox">
            <Dropdown title={
                <Button width="full">
                    <div className="flex justify-between w-full items-center after:content-['▼'] after:text-xs after:ml-1 after:text-slate-400">
                        {label}
                    </div>
                </Button>
            }>
                {items.map((item, index) => (
                    <div
                        key={index}
                        role="option"
                        className="py-1 flex gap-2 items-center cursor-pointer"
                        onClick={() => onClick(item.value)}
                    >
                        <Checkbox name={item.label} checked={selectedItems.includes(item.value)} />
                        {item.label}
                    </div>
                ))}
            </Dropdown>

            <div className="flex flex-wrap gap-2 text-sm mt-2">
                {selectedItems
                    .map((value) => items.find((item) => item.value === value))
                    .filter((item) => item !== undefined)
                    .map((item) => (
                        <div key={item.label} className="bg-slate-100 rounded-md px-2 py-1 flex items-center">
                            <span className="mr-1">{item.label}</span>
                            <button
                                type="button"
                                onClick={() => onClick(item.value)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ×
                            </button>
                        </div>
                    ))}
            </div>
        </div>
    )
};
