import { Formik, Field, Form } from 'formik';
import { Button } from '@/components/Button';
import { User, UserRole } from '@/types';
import * as Yup from 'yup';

interface Props {
    user: User,
    roles: UserRole[],
    onSubmit: (params: {
        userId: string,
        newRole: UserRole,
        prevRole: UserRole,
    }) => void,
}

const roleLabels: Record<UserRole, string> = {
    [UserRole.advocate]: 'Advocate',
    [UserRole.clinician]: 'Clinician',
    [UserRole.manager]: 'Manager',
    [UserRole.admin]: 'Admin',
    [UserRole.user]: 'User',
    [UserRole.patient]: 'Patient',
};

const invitationSchema = Yup.object().shape({
    role: Yup.string().required('Please select role'),
});

export const UserEditForm = ({ user, roles, onSubmit }: Props) => {
    const props = {
        onSubmit: ({ role: newRole }: { role: UserRole | undefined }) => {
            const { userId, role: prevRole } = user;
            if (newRole) {
                onSubmit({ userId, newRole, prevRole });
            }
        },
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        initialValues: {
            role: undefined
        },
    };

    return (
        <Formik {...props} validationSchema={invitationSchema}>
            {(formProps) => (
                <Form className="flex flex-col flex-wrap items-center justify-center w-screen max-w-full gap-4">
                    <label className="w-full flex items-center">
                        <span className="mr-2">Role:</span>
                        <Field as="select" name="role" id="role" className="flex-grow text-sm rounded-md border-gray-300">
                            <option value={''} disabled selected={true}>Select role</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>{roleLabels[role]}</option>
                            ))}
                        </Field>
                    </label>

                    <Button type="submit" width="full" disabled={!formProps.isValid}>
                        Save
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
