import { UUID } from 'crypto';

export enum AllowedSurveyActions {
    SEND_REMINDER = 'send_reminder',
    VIEW_RESULTS = 'view_results',
    RE_ASSIGN = 're_assign',
    DELETE = 'delete',
}

export const AllowedSurveyActionsAll = [AllowedSurveyActions.SEND_REMINDER, AllowedSurveyActions.VIEW_RESULTS, AllowedSurveyActions.RE_ASSIGN, AllowedSurveyActions.DELETE];

export enum UserRole {
    user = 'user',
    admin = 'admin',
    manager = 'manager',
    patient = 'patient',
    advocate = 'advocate',
    clinician = 'clinician',
}

export interface SystemSurvey {
    id: UUID; 
    name: string; 
    airtable?: string
    status?: string;
    updated?: number;
}

// @todo: replace with something useful
export interface Survey {
    id: UUID;
    // @todo: separate Surveys entities
    surveyId: UUID;
    surveyIds: UUID[];
    userId: UUID;
    advocateId: UUID;

    updated: number;
    name: string;
    status: 'sent' | 'registered' | 'started' | 'finished';
    completion: number;
    region: 'US' | 'EU';
    version: number | null;
    pageNo: number | null;
    responses: any;

    user: User;
}

export interface User {
    userId: UUID;
    role: UserRole;
    lastLogin: number;
    email: string;
    fullName?: string;
    firstName: string;
    lastName: string;
    city?: string;
    status?: string;
    postal?: string;
    region?: string;
    country?: string;
    state?: string;
    userAccess: User[];
    advocate?: User;
    advoacteId?: UUID;
    invitations?: Invitation[];
    advocateSurveys: Survey[];
}

export const hasAdvocate = (user: User) => user.userAccess.find((user) => user.role === 'advocate');

export const hasClinician = (user: User) => user.userAccess.find((user) => user.role === 'clinician');

export interface Invitation {
    id: UUID;
    email: string;
    userSurveyId?: UUID;
    surveyIds: UUID[];
    surveyId?: UUID;
    surveyName: string;

    invitationId: UUID;

    advocateId?: UUID;
    advocate?: User;

    firstName: string;
    lastName: string;
    fullName?: string;

    user?: User;
    systemSurveys?: SystemSurvey[];

    status: 'sent' | 'registered' | 'started' | 'finished' | 'assigned';
    date: number;
    completion: number;

    role?: string;

    used?: number;
}

export interface AdvocateInvitation {
    id: UUID;
    email?: string;
    role?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    date?: number;
}
