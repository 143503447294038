import { string, object } from 'yup';
import { Field, Form, ErrorMessage as FormikErrorMessage, useFormik, FormikProvider } from 'formik';
import { Button } from '../../components/Button/index';
import { useState, ReactNode, useEffect } from 'react';
import { getRegisterAdvocateMessage, isEmailValid } from '../../helpers/email';

const invitationValidationSchema = object().shape({
  email: string().email().required('Required'),
  subject: string().required('Please specify the subject'),
  message: string().required('Please write some message'),
});

type Props = {
  subject?: string;
  message?: string;
  onSubmit: (values: any) => void;
};

const ErrorMessage = ({ name }: { name: string }) => <FormikErrorMessage component="span" className="mt-1 text-red-500 text-sm" name={name} />;

const Label = ({ title, children }: { title: string; children: ReactNode }) => (
    <label className="w-full flex flex-col font-normal text-sm text-gray-700 last:mb-0">
        <div className="mb-2">{title}</div>
        {children}
    </label>
);

export const AdminInviteAdvocateForm = ({ onSubmit, subject = '', message: initialMessage = '' }: Props) => {
    const [message, setMessage] = useState(initialMessage);
    const [, setEmail] = useState<string>();

    const formik = useFormik({
        onSubmit,
        initialValues: { subject, message, firstName: '', lastName: '', email: '', role: 'advocate' },
        validationSchema: invitationValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
    });
    const { values: formData, setFieldValue } = formik;

    const onEmailValidate = (value: string) => setEmail(isEmailValid(value) ? value : undefined);

    useEffect(() => {
        const link = `https://${window.location.host}?mode=signup&type=bba`;

        setFieldValue('message', getRegisterAdvocateMessage(formData, formData.role === 'advocate' ? 'an advocate' : 'a clinician', link));
    }, [formData.firstName, formData.lastName, formData.role]);

    return (
        <FormikProvider value={formik}>
            <Form action="#" className="relative flex flex-col gap-6">
                <Label title="Email address:">
                    <div className="relative">
                        <Field
                            required
                            type="email"
                            name="email"
                            placeholder="you@example.com"
                            validate={onEmailValidate}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>

                    <ErrorMessage name="email" />
                </Label>

                <div className="flex justify-between gap-4">
                    <Label title="First name:">
                        <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="First Name"
                            required
                        />

                        <ErrorMessage name="firstName" />
                    </Label>

                    <Label title="Last name:">
                        <Field
                            label="Last Name"
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Last Name"
                            required
                        />

                        <ErrorMessage name="lastName" />
                    </Label>
                </div>

                <Label title="Role:">
                    <Field as="select" name="role" className="flex-grow text-sm rounded-md border-gray-300">
                        <option value="advocate">Advocate</option>
                        <option value="clinician">Clinician</option>
                    </Field>
                </Label>

                <div className="gap-0 w-full flex flex-col">
                    <Field
                        type="text"
                        name="subject"
                        aria-label="subject"
                        placeholder="Subject"
                        className="text-lg font-normal placeholder-gray-500 rounded-t outline:none border-gray-300 focus:border-gray-300 focus:ring-0 border-b-0"
                    />

                    <div
                        className="h-[25vh] border p-3 sm:text-sm flex-grow outline:none border-gray-300 focus:ring-0 border-t-0 focus:outline-none overflow-y-scroll"
                        contentEditable={true}
                        dangerouslySetInnerHTML={{ __html: formik.values.message }}
                        onInput={(event) => setMessage(event.currentTarget.innerHTML)}
                    />

                    <div className="p-2 border-gray-300 border border-t-0 rounded-b bg-white">
                        <Button type="submit" onClick={formik.handleSubmit}>
                            Send
                        </Button>

                        <ErrorMessage name="message" />
                    </div>
                </div>
            </Form>
        </FormikProvider>
    );
};
