import { Body, Head, Table, Td, Th } from '../../components/Table';
import { SurveyStatus } from '../../components/SurveyStatus';
import { formatDate } from '../../helpers';
import { Link } from '../../components/Link';


interface Props {
  id: string,
  surveys: {
    versions: {
      VersionId: string,
      isLatest: boolean,
      LastModified: number,
      Comment: string,
    }[]
  }
}


export const SurveyVersionList = ({ id, surveys }: Props) => {
  return (
    <Table>
      <Head>
        <Th>ID</Th>
        <Th align="center">Status</Th>
        <Th>Last modified</Th>
        <Th align="center">Actions</Th>
      </Head>

      <Body>
        {surveys.versions.map((survey) => (
          <tr key={survey.VersionId} className="even:bg-basic/10">
            <Td>
              {survey.Comment || survey.VersionId}
            </Td>

            <Td align="center">
              <SurveyStatus status={survey.isLatest ? 'published' : 'archived'} type="editorial" />
            </Td>

            <Td>
              {formatDate(new Date(survey?.LastModified))}
            </Td>

            <Td>
              <Link to={`/admin/survey/edit/${id}?version=` + survey.VersionId}>Edit survey</Link>
            </Td>
          </tr>
        ))}
      </Body>
    </Table >
  );
};
