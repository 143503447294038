import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StylesManager, Action } from 'survey-core';
import {
  CreatorBase,
  SurveyCreatorComponent,
  SurveyCreator as SurveyCreatorReact,
} from 'survey-creator-react';
import { captureException } from '@sentry/react';

import LoadingModal from '../../components/LoadingModal';
import NewSurveyVersionModal from '../../components/NewSurveyVersionModal';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button/index';
import { ErrorList } from './elements/ErrorList';

import { useUpdateSurvey } from '../../queries/useUpdateSurvey';
import { useCreateSurvey } from '../../queries/useCreateSurvey';

import { fetchAirTable } from '../../services/airTable';

import 'survey-creator-core/survey-creator-core.min.css';
import 'survey-core/defaultV2.min.css';

StylesManager.applyTheme('defaultV2');

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  haveCommercialLicense: true,
  // showErrorOnFailedSave: false,
  designerHeight: '',
};

interface Props {
  id: string;
  surveyData: {
    title: string;
    logoPosition: string;
    pages: {
      name: string;
      title: string;
      elements: any[];
    }[];
  };
  surveyRecord: {
    id: string;
    airtable: string;
    name: string;
    status: string;
    updated: number;
  };
  version: string;
}

export const SurveyCreator = ({
  id,
  surveyData,
  surveyRecord,
}: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const surveyElement = React.useRef<SurveyCreatorComponent | null>(null);
  const [loadedSurvey, setLoadedSurvey] =
    React.useState<Awaited<ReturnType<typeof fetchAirTable>>>();
  const [latestJSON, setLatestJSON] = React.useState(null);

  const [issues, setIssues] = React.useState<
    { title: string; description: string }[]
  >([]);
  const { mutateAsync: updateSurvey } = useUpdateSurvey();
  const { mutateAsync: createSurvey } = useCreateSurvey();
  const navigate = useNavigate();

  const creator = new SurveyCreatorReact(creatorOptions);

  creator.onQuestionAdded.add(function (_, options) {
    options.question.isRequired = true;
  });

  creator.onModified.add((sender) => {
    setLatestJSON(sender.JSON);
  });

  function createButtonForPopup(options: any, property: string, lbl: string) {
    //Add a button;
    var btn = document.createElement('span');
    //Show button if popupDescription property is not empty
    btn.style.visibility =
      options.question[property] === undefined ? '' : 'hidden';
    // btn.type = 'button';
    btn.className = 'rounded-full bg-red-100 text-red-800 text-[0.75rem]';

    btn.style.position = 'relative';
    btn.style.marginLeft = '10px';
    btn.style.padding = '0 3px';
    btn.style.border = 'none';
    btn.innerHTML = lbl;
    //Change button visibility on changing the property
    options.question.registerPropertyChangedHandlers(
      [property],
      function (newValue?: unknown) {
        btn.style.visibility = newValue === undefined ? '' : 'hidden';
      }
    );
    var header = options.htmlElement.querySelector('h5');
    var span = document.createElement('span');
    span.innerHTML = '  ';
    header.appendChild(span);
    header.appendChild(btn);
  }

  function renderQuestionInDesigner(_: CreatorBase, options: any) {
    createButtonForPopup(options, 'section', 'sec');
    createButtonForPopup(options, 'score', 'score');
  }
  function renderQuestionInTestSurvey(_: CreatorBase, options: any) {
    createButtonForPopup(options, 'section', 'sec');
    createButtonForPopup(options, 'score', 'score');
  }
  //Calls when creator needs to create a new survey instance.
  //In most cases you need to check options.reason for "designer" and "test".
  //Survey is created for designer survey or "Test Survey" tab.
  //Several properties editors create survey as well. In this case the reason will be: "conditionEditor", "defaultValueEditor", "restfulEditor"
  creator.onSurveyInstanceCreated.add(function (_, options) {
    //If we are creating a surface for designer surface
    if (options.reason == 'designer') {
      options.survey.onAfterRenderQuestion.add(renderQuestionInDesigner);
    }
    //If we are creating a surface for "Test Survey" tab
    if (options.reason == 'test') {
      options.survey.onAfterRenderQuestion.add(renderQuestionInTestSurvey);
    }
  });

  creator.saveSurveyFunc = React.useCallback(
    async (saveNo: number, callback: (...args: [number, boolean]) => void) => {
      callback(saveNo, true);
    },
    []
  );

  creator.toolbarItems.push(
    new Action({
      id: 'custom-preview',
      visible: true,
      title: 'Publish',
      action: React.useCallback(async () => {
        setShowModal(true);
      }, []),
    })
  );
  if (surveyRecord && surveyRecord.airtable) {
    creator.toolbarItems.push(
      new Action({
        id: 'custom-preview',
        visible: true,
        title: 'Load AirTable',
        action: React.useCallback(async () => {
          setShowLoading(true);
          try {
            const survey = await fetchAirTable(surveyRecord.airtable);
            setLoadedSurvey({ ...survey, title: surveyRecord.name });
          } catch (error) {
            captureException(error);
            setIssues([
              { title: 'AirTable Error', description: `${error instanceof Error ? error.message : error}` },
            ]);
          } finally {
            setShowLoading(false);
          }
        }, []),
      })
    );
  }

  const loadedSurveyData = () => {
    if (latestJSON) {
      creator.text = JSON.stringify(latestJSON);
    } else if (loadedSurvey) {
      creator.text = JSON.stringify(loadedSurvey);
    } else if (surveyData) {
      creator.text = JSON.stringify(surveyData);
    }
  };
  loadedSurveyData();
  React.useEffect(() => {
    loadedSurveyData();
  }, [surveyData, loadedSurvey, issues]);

  return (
    <div style={{ height: '100vh' }}>
      {showModal && (
        <NewSurveyVersionModal
          setOpenModal={setShowModal}
          onSubmit={async ({ message }: { message: string }) => {
            setShowLoading(true);
            const jsonData = latestJSON || creator.JSON;
            try {
              await updateSurvey({
                data: jsonData,
                id,
                comment: message,
              });
              // We are creating a new one
              if (!surveyData) {
                await createSurvey({
                  id,
                  updated: Math.floor(Date.now() / 1000),
                  name: jsonData.title,
                  status: 'published',
                });
              }
              navigate('/admin/surveys');
            } catch (error) {
              captureException(error);
              console.error(error);
            } finally {
              setShowLoading(false);
            }
          }}
        />
      )}

      <Modal isOpen={issues.length > 0} onClose={() => setIssues([])}>
        <ErrorList issues={issues} />
        <Button onClick={() => setIssues([])}>Close</Button>
      </Modal>

      {showLoading && <LoadingModal text='Processing survey...' />}
      <SurveyCreatorComponent creator={creator} ref={surveyElement} />
    </div>
  );
};
