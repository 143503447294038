import { Link } from "react-router-dom";


export const CompletedMessage = () => (
    <div className="w-full h-96 flex flex-col items-center justify-center">
        <h1 className="text-center text-2xl font-normal tracking-tight text-gray-900">
            Thank you for completing the survey
        </h1>
        <Link to="/" className="text-accent cursor-pointer inline-block m-1 text-lg">
            Back to dashboard
        </Link>
    </div>
);
