const getSurveyProgressColor = (status) => {
    switch (status) {
        case 'assigned':
            return 'bg-red-500';
        case 'started':
            return 'bg-orange-500';
        case 'finished':
            return 'bg-green-500';
    }
};

const getSurveyProgressBgColor = (status) => {
    switch (status) {
        case 'assigned':
            return 'bg-red-200';
        case 'started':
            return 'bg-orange-200';
        case 'finished':
            return 'bg-green-200';
        default:
            return 'bg-red-200';
    }
};


export const SurveyProgress = ({ progress, status }) => {
    const color = getSurveyProgressColor(status);
    const backgroundColor = getSurveyProgressBgColor(status);

    return (
        <div className="flex items-center">
            <span className="mr-2 text-xs">
                {progress}%
            </span>
            <div className={`relative w-full overflow-hidden h-2 text-xs flex rounded ${backgroundColor}`}>
                <div
                    style={{ width: `${progress}%` }}
                    className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${color}`}
                />
            </div>
        </div>
    );
};
