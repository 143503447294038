import { useQuery } from '@tanstack/react-query';
import { User, hasAdvocate, AdvocateInvitation } from '../../types';
import { formatName } from '../../helpers';
import { sendRequest } from '../../services/api';

const transformUsers = (data: { users: User[]; invitations: AdvocateInvitation[] }) => {
    return {
        users: data.users.map((user) => {
            const fullName = formatName(user);

            if (hasAdvocate(user)) {
                const { advocate, ...rest } = user;

                return {
                    ...rest,
                    fullName,
                    ...(advocate ? { [advocate.role]: advocate } : {}),
                };
            }

            return {
                ...user,
                status: 'registered',
                fullName,
            };
        }),
        invitations: data.invitations
            ? data.invitations.map((invitation) => {
                const fullName = formatName(invitation);

                return {
                    ...invitation,
                    status: 'pending',
                    fullName,
                };
            })
            : [],
    };
};

export const useUserList = () =>
    useQuery<{ users: User[]; invitations: AdvocateInvitation[] }, { message: string }, { users: User[]; invitations: AdvocateInvitation[] }>({
        queryKey: ['users'],
        queryFn: () => sendRequest('manager/users'),
        select: transformUsers,
    });

export const useAdvocatesUserList = ({ query, limit, enabled }: { query?: string; limit: number; enabled: boolean }) =>
    useQuery<{ users: User[]; invitations: AdvocateInvitation[] }, { message: string }, { users: User[]; invitations: AdvocateInvitation[] }>({
        queryKey: ['advocates', query, limit],
        queryFn: async () => {
            const params = new URLSearchParams();

            if (query) { params.append('query', query); }
            if (limit) { params.append('limit', limit.toString()); }

            return sendRequest(`manager/advocates?query=${encodeURIComponent(query ?? '')}&limit=${limit}`);
        },
        select: transformUsers,
        enabled,
    });
