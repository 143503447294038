import { useMutation } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const useCreateSurveyReminder = ({ isAdmin }: { isAdmin: boolean }) => {
    return useMutation({
        mutationFn: async ({ id, ...data }: { [k: string]: unknown }) => {
            const path = isAdmin
                ? `admin/survey/reminder/${id}`
                : `advocate/survey/reminder/${id}`;

            return sendRequest(path, { body: data, method: HttpMethod.POST });
        },
    });
};
