import { cx } from '../../helpers';


export const Switch = ({ checked, onChange }: { checked: boolean, onChange: (value: boolean) => void }) => (
    <div
        onClick={() => onChange(!checked)}
        className={cx(
            checked ? 'bg-blue-500' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
    >
        <span
            aria-hidden="true"
            className={cx(
                checked ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
    </div>
);
