import { ReactNode } from 'react';


export const Title = ({ children }: { children: ReactNode }) => (
    // <div className='min-w-0 flex-1'>
        <h2 className="
                text-xl
                font-normal
                leading-7
                text-slate-800

                sm:truncate
                sm:text-3xl
                sm:tracking-tight

                flex
                justify-between
                items-center

                mb-8
            ">
            {children}
        </h2>
    // </div>
)
