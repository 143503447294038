import { UUID } from 'crypto';
import { format, fromUnixTime } from 'date-fns';
import { PageModel, PanelModel, Question, IElement } from 'survey-core';
import { isBoolean, isString } from '.';

interface QuestionMetadata {
    id: string,
    visibleIf?: string,
    title: string,
    choices: (string | { text: string, value: string })[],
    type: keyof typeof Map | 'file',
    rows: { text: string, value: string }[],
    items: { name: string, value: string; title?: string }[],
    columns: { text: string, value?: string; cellType?: string; title?: string; name: string; choices: (string | { text: string, value: string })[], }[],
}

const parseDisplayValue = (cell: any, value: any) => {
    switch (cell.cellType) {
        case 'checkbox':
            if (cell.choices) {
                const choice = cell.choices.find((el: any) => isString(el) ? el === value : el.value === value);
                return isString(choice) ? choice : (choice?.text || value);
            }
            return value ? (cell.labelTrue || 'Yes') : (cell.labelFalse || 'No');
        case 'boolean':
            return value ? (cell.labelTrue || 'Yes') : (cell.labelFalse || 'No');
        case 'dropdown':
            const choice = cell.choices.find((el: any) => isString(el) ? el === value : el.value === value);
            return isString(choice) ? choice : (choice?.text || value);
        default:
            return value;
    }
}

const parseMatrixDynamic = (question: Question, metadata: QuestionMetadata) => {
    return (question.response.value as { [k: string]: string }[]).map((value) => {
        return Object.keys(value).map(key => { 
            const column = metadata.columns.find((el) => el.name === key);
            let returnValue = value[key];
            if (column?.cellType) {
                returnValue = parseDisplayValue(column, value[key]);
            }
            const name = column?.title || column?.name || key;
            return `${name}: ${returnValue}`;
        });
    });
};

const parseMatrixDropdown = (question: Question, metadata: QuestionMetadata) => {
    const result = [];
    for (const rowName in question.response.value) {
        for (const colName in question.response.value[rowName]) {
            const row = metadata.rows.find((el) => isString(el) ? el === rowName : el.value === rowName);
            const column = metadata.columns.find((el) => (el.value || el.name) === colName);
            if (column?.cellType) {
                const value = question.response.value[rowName][colName];
                let responseValue = Array.isArray(value) ? value.map((el) => parseDisplayValue(column, el)) : parseDisplayValue(column, value);
                let subTitle = value?.title ? value?.title + ' - ' : '';
                if (!subTitle && column.title) {
                    subTitle = column.title + ' - ';
                }
                result.push(`${row?.text || row}: ${subTitle} ${responseValue}`);
            } else {
                result.push(`${row}: ${column?.text || column}`);
            }
        }
    }

    return result;
};

const parseMatrix = (question: Question, metadata: QuestionMetadata) => {
    return Object.entries(question.response.value).map(([subQuestion]) => {
        const metadataValue = metadata.columns.find((el) => el.value === question.response.value[subQuestion]);

        const name = metadata.rows.find((el) => el.value === subQuestion)?.text || subQuestion;
        const value = metadataValue?.text || metadataValue?.value || subQuestion;

        return `${name}: ${value}`;
    });
};

const parseRadioGroup = (question: Question, metadata: QuestionMetadata) => {
    const isArray = Array.isArray(question.response.value);
    const isNone = question.response.value[0].toLowerCase() === 'none';

    if (isArray && isNone) {
        return ['None'];
    }

    return metadata.choices.reduce((result, choice) => {
        const choiceValue = isString(choice) ? choice : choice.value;
        const value = isString(choice) ? choice : (choice.text || choice.value);
        const isExists = isArray ? question.response.value.includes(choiceValue) : choiceValue === question.response.value;

        return isExists ? [...result, value] : result;
    }, [] as string[])
};

const parseMultipleText = (question: Question, metadata: QuestionMetadata) => {
    return Object.keys(question.response.value).map((key: string) => {
        const value = question.response.value[key];
        const metadataValue = metadata.items.find((el) => el.name === key);

        return `${metadataValue?.title || metadataValue?.name}: ${value}`;
    });
}

const parseTagbox = (question: Question, metadata: QuestionMetadata) => {
    if (metadata.choices) {
        return question.response.value
        .map((el: any) => 
            metadata.choices.find((choice: any) => isString(choice) ? choice === el : choice.value === el))
        .map((el: any) => el?.text || el).join(', ');
    }

    return question.response.value.map((el: any) => isString(el) ? el : el.text).join(', ');
}

const TypeToParserMap = {
    'checkbox': parseRadioGroup,
    'radiogroup': parseRadioGroup,
    'dropdown': parseRadioGroup,
    'matrix': parseMatrix,
    'matrixdynamic': parseMatrixDynamic,
    'matrixdropdown': parseMatrixDropdown,
    'multipletext': parseMultipleText,
    'tagbox': parseTagbox,
} as const;

const parseValue = (question: Question, questionMetadata: QuestionMetadata) => {
    const type = questionMetadata.type as keyof typeof TypeToParserMap;
    const fn = TypeToParserMap[type];

    if (fn) {
        return fn(question, questionMetadata);
    }

    if (Array.isArray(question.response.value)) {
        return (question.response.value as any[]).map((el) => el.Value ? el.Value : el);
    }

    if (isBoolean(question.response.value)) {
        return question.response.value ? 'Yes' : 'No';
    }

    return `${question.response.value}`;
};


const castArray = <T>(value: T) => Array.isArray(value) ? value : [value];

const isPanel = (value: any): value is PanelModel => (typeof value === 'object' && value.type === 'panel');

const flatPanel = (el: IElement): IElement[] => isPanel(el) ? el.elements.flatMap(flatPanel) : [el];

const getQuestionMetadata = (pages: PageModel[]) => pages.flatMap(page => page.elements).flatMap(flatPanel) as unknown as QuestionMetadata[];


const groupAnswersBySections = (responses: Question[], questions: QuestionMetadata[], scored: boolean) => {

    return responses
        .reduce<{ [k: string]: { score: number | undefined, questions: { hint?: any, files?: any; text: string, index: number, score: number }[] } }>((result, question) => {
            const sectionId = question.section;
            const section = result[sectionId] || { score: scored ? 0 : undefined, questions: [] };
            const questionIndex = questions.findIndex((q) => q.id === question.questionId)
            const questionData = questions[questionIndex];

            const prefix = question.questionId.endsWith('-sub') || questionData?.visibleIf ? '└───  ' : '';
            if (questionData?.type === 'file') {
                section.questions.push({
                    files: question.response.value,
                    hint: '',
                    text: `${prefix} ${questionData?.title}`,
                    index: questionIndex,
                    score: scored ? question.score : undefined,
                });
            } else {
                const hint = castArray(questionData ? parseValue(question, questionData) : `${question.questionId} not found`);
                section.questions.push({
                    hint,
                    text: `${prefix} ${questionData?.title}`,
                    index: questionIndex,
                    score: scored ? question.score : undefined,
                });
            }

            if (scored) {
                section.score += question.score || 0;
            }

            return {
                ...result,
                [sectionId]: section,
            };
        }, {});
};

export const transformSections = (survey: any, surveyInfo: { pages: PageModel[]; scored?: boolean }) => {
    const metadata = getQuestionMetadata(surveyInfo.pages);
    const sections = groupAnswersBySections(survey.userResponses, metadata, surveyInfo.scored || surveyInfo.scored === undefined);

    return Object
        .entries(sections)
        .map(([sectionId, section]) => ({
            ...section,
            section: sectionId,
            questions: section.questions.sort((a, b) => a.index - b.index),
        }))
        .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
        // remove score from basic demographics
        .map((section) => ({
            ...section,
            score: section.section.toLowerCase() === 'basic demographics' ? undefined : section.score,
        }))
        // @todo: put the basic demographics first the other way
        .sort((a, _) => a.section.toLowerCase() === 'basic demographics' ? -1 : 1)
};

export const getFileName = <T extends Partial<{
    name: string,
    updated: number,
    user: {
        firstName: string,
        lastName: string,
    }
}>>(survey: T, extension = '') => {
    const a = survey.user?.firstName?.charAt(0);
    const b = survey.user?.lastName?.charAt(0);
    const c = survey?.name || 'survey';
    const date = survey?.updated ? fromUnixTime(survey.updated) : new Date();

    const fileName = `${a}${b}_${c}_${format(date, 'yyyyMMdd')}`;

    return extension ? `${fileName}.${extension}` : fileName;
};

export const getExcelData = (sections: any[]) => ({
    sections: sections.map(({ section: name, score }) => ({ name, score })),
    questions: sections.reduce((result, section) => [
        ...result,
        ...section.questions.map((question: Question) => ({
            section: section.section,
            text: question.text,
            response: question.hint.join('\n'),
            score: question.score,
        })),
    ], [])
});

export const getPdfData = (sections: any[]) => sections.reduce((result, section) => ({
    ...result,
    [section.section]: {
        score: section.score,
        questions: section.questions.reduce((
            result: { text: string, answer: string, score: number }[],
            question: Question,
        ) => [...result, {
            text: question.text,
            answer: question.hint || '',
            score: question.score,
        }], []),
    },
}), {});

export const download = (url: string, fileName: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();
    link.remove();
};

export const isExcelReportEnabled = (surveyId: UUID) => {
    return ['af89c3e6-8d6c-4db4-86fe-884bf5df11ee'].includes(surveyId);
};
