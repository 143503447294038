import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from '@/components/Link';
import { Carousel } from '@/components/Carousel';
import Login from './elements/Login';
import Register from './elements/Register';
import Verification from './elements/Verification';
import ResetPassword from './elements/ResetPassword';

type Mode = 'signin' | 'signup' | 'verify' | 'reset-password';

const modeToComponentMap = {
    'signin': Login,
    'signup': Register,
    'verify': Verification,
    'reset-password': ResetPassword,
};

const backgroundImages = [
    '/images/auth/carousel/picture-1.jpg',
    '/images/auth/carousel/picture-2.jpg',
    '/images/auth/carousel/picture-3.jpg',
    '/images/auth/carousel/picture-4.jpg',
    '/images/auth/carousel/picture-5.jpg',
    '/images/auth/carousel/picture-6.jpg',
    '/images/auth/carousel/picture-7.jpg',
    '/images/auth/carousel/picture-8.jpg',
    '/images/auth/carousel/picture-9.jpg',
    '/images/auth/carousel/picture-10.jpg',
];

export const Auth = () => {
    const [searchParams] = useSearchParams();
    const initialMode = searchParams.get('mode') as Mode ?? 'signin';
    const [mode, setMode] = useState<Mode>(initialMode);

    const Component = modeToComponentMap[mode];

    return (
        <main className="flex flex-row bg-white max-w-full 2xl:max-w-7xl mx-auto min-h-[100vh] text-sm">
            <div className="max-w-1/2 w-full flex items-center justify-end max-w-1/2">
                <div className="p-20 flex flex-col">
                    <Link to="/" className="mb-16">
                        <img src="/images/logo.png" className="w-1/2" />
                    </Link>

                    <Component setMode={setMode} />
                </div>
            </div>

            <div className="sticky top-0 w-full h-screen max-w-1/2">
                <Carousel items={backgroundImages} />
            </div>
        </main>
    );
};
