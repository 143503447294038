import { useEffect, useState } from 'react';

interface Props {
    items: string[];
    interval?: number;
    initialIndex?: number;
}

export const Carousel = ({ items, interval = 5000, initialIndex = 0 }: Props) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const nextIndex = (currentIndex + 1) % items.length;
            const image = new Image();

            image.onload = () => setCurrentIndex(nextIndex);
            image.src = items[nextIndex]!;
        }, interval);

        return () => clearTimeout(timeoutId);
    }, [currentIndex]);

    const previousIndex = Math.max(currentIndex - 1, 0);

    return (
        <div className="w-full h-full overflow-hidden relative">
            <img
                src={items[previousIndex]}
                className="absolute w-full h-full object-cover"
            />

            <img
                key={items[currentIndex]}
                src={items[currentIndex]}
                className="absolute w-full h-full transition-all duration-200 object-cover animate-fade"
            />
        </div>
    );
};
