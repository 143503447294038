import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UUID } from 'crypto';
import { HttpMethod, sendRequest } from '../../services/api';

interface RequestPayload {
    userId: UUID;
    removeList: UUID[];
    addList: UUID[];
}

export const useUpdateAccessList = () => {
    const queryClient = useQueryClient();
    const queryKey = 'manager/access/user/';

    return useMutation({
        mutationFn: async (data: RequestPayload) => {
            if (data.addList.length) {
                await sendRequest(queryKey + data.userId, {
                    body: { advocates: data.addList },
                    method: HttpMethod.PUT,
                });
            }

            if (data.removeList.length) {
                await sendRequest(queryKey + data.userId, {
                    body: { advocates: data.removeList },
                    method: HttpMethod.DELETE,
                });
            }

            return;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['users'] });
        },
        onError: (error) => {
            console.log(error);
        },
    });
};
