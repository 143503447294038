import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from './Button/index';

const NewSurveyVersionModal = ({ setOpenModal, onSubmit }) => {
  const invitationSchema = Yup.object().shape({
    message: Yup.string().required('Please write some message'),
  });

  return (
    <>
      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div
          className='fixed inset-0 w-full h-full bg-black opacity-40'
          onClick={() => setOpenModal(false)}
        ></div>
        <div className='flex items-center min-h-screen px-4 py-8'>
          <div className='relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg'>
            <div className='mt-3'>
              <Formik
                initialValues={{
                  message: '',
                }}
                validationSchema={invitationSchema}
                onSubmit={(values) => {
                  setOpenModal(false);
                  onSubmit({
                    ...values,
                  });
                }}
              >
                {(props) => (
                  <Form action='#' className='relative'>
                    <div className='grid grid-cols-2 gap-6'>
                      <div className='col-span-full overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500'>
                        <label htmlFor='description' className='sr-only'>
                          Comment
                        </label>
                        <Field
                          as='textarea'
                          rows={2}
                          name='message'
                          id='message'
                          className='block w-full resize-none border-0 py-0 placeholder-gray-500 focus:ring-0 sm:text-sm'
                          placeholder='Write a message...'
                        />

                        {/* Spacer element to match the height of the toolbar */}
                        <div aria-hidden='true'>
                          <div className='py-2'>
                            <div className='h-9' />
                          </div>
                          <div className='h-px' />
                          <div className='py-2'>
                            <div className='py-px'>
                              <div className='h-9' />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-span-full absolute inset-x-px bottom-0'>
                        <div className='flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3'>
                          <div className='flex-shrink-0'>
                            <Button type='submit'>
                              Publish
                            </Button>
                            <ErrorMessage
                              component='a'
                              className='text-red-500 text-sm ml-5'
                              name='message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSurveyVersionModal;
