import { ReactNode } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

export const client = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 0,
            staleTime: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
        },
        mutations: {
            onError: (error) => {
                captureException(error);
            },
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            captureException(error);
        },
    }),
});

export const QueryContextProvider = ({ children }: { children: ReactNode }) => {
    return (
        <QueryClientProvider client={client}>
            {children}
        </QueryClientProvider>
    );
};
