import { Model, SurveyModel, PageModel as SurveyPage, Question as SurveyQuestion } from 'survey-core';

function isNumeric(str: string) {
    return typeof str === 'number';
}

const nop = () => { };

export const isCompleted = (area: SurveyPage) => {
    const questions = getVisibleQuestions(area.questions, { requiredOnly: true });
    const answers = getAnsweredQuestions(questions);

    return (questions.length !== 0 && answers.length !== 0) && questions.length === answers.length;
};

export const isNotCompleted = (area: SurveyPage) => !isCompleted(area);

export const getVisibleQuestions = (
    questions: SurveyQuestion[], { requiredOnly } = { requiredOnly: false }
) =>
    questions.filter(
        (question) =>
            question.isParentVisible &&
            question.isVisible &&
            (!requiredOnly || question.isRequired)
    );

const isDynamicMatrixQuestionAnswered = (question: SurveyQuestion) => {
    const minRowsCount = question.minRowCount;
    const visibleRows = question.getVisibleRows();
    const hasEmptyRows = visibleRows.some(({ isEmpty }: { isEmpty: boolean }) => isEmpty);

    return !hasEmptyRows && (visibleRows.length >= minRowsCount);
}

const isAnswered = (question: SurveyQuestion) => {
    // dynamic matrix questions are not considered as answered by surveyjs
    if (question.getType() === 'matrixdynamic') {
        return isDynamicMatrixQuestionAnswered(question);
    }

    return question.isAnswered;
};

export const getAnsweredQuestions = (questions: SurveyQuestion[]) => questions.filter(isAnswered);

export const getCompleteAreasCount = (areas: SurveyPage[]) =>
    areas.reduce((result, page) => (isCompleted(page) ? result + 1 : result), 0);

export const getSurveyProgress = (model: SurveyModel) => {
    let questions = getVisibleQuestions(model.getAllQuestions(), {
        requiredOnly: true,
    });
    if (questions.length === 0) {
        questions = getVisibleQuestions(model.getAllQuestions());
    }
    const answers = getAnsweredQuestions(questions);
    const result = (answers.length / questions.length) * 100;

    return result.toFixed();
};

/**
 * @param {SurveyModel} survey
 * @returns { [questionId: string]: { name: string, value: unknown, score: number, section: string } }
 */
export const formatModelData = (survey: SurveyModel) => {
    const resultData: {
        [key: string]: {
            name: string;
            value: unknown;
            score?: number;
            section: string;
        }
    } = {};

    for (const key in survey.data) {
        const question = survey.getQuestionByName(key);

        if (!!question) {
            let score: number | undefined;
            let choices = question.choices;
            if (!choices || !Array.isArray(choices)) {
                choices = [];
            }
            if (question.choicesFromQuestion) {
                choices = survey.getQuestionByName(
                    question.choicesFromQuestion
                ).choices;
            }

            if (survey.scored || survey.scored === undefined) {
                switch (question.getType()) {
                    case 'boolean':
                        if (question.score !== undefined) {
                            score =
                                question.scoreType === 'Negative' ?
                                    question.value ?
                                        0 :
                                        question.score :
                                    question.value ?
                                        question.score :
                                        0;
                        }
                        break;
                    case 'radiogroup':
                        score = choices.find(
                            (choice: { value: string }) => choice.value === question.value
                        ).score;
                        break;
                    case 'matrix':
                        score = 0;
                        for (const rowName in question.value) {
                            const row = question.rows.find((el: { value: string }) => el.value === rowName).score;
                            const column = question.columns.find(
                                (el: { value: string }) => el.value === question.value[rowName]
                            ).score;
                            score += row * column;
                        }
                        break;
                    case 'matrixdropdown':
                        if (question.scoreType === 'Overall') {
                            score = question.score;
                        } else {
                            for (const rowName in question.value) {
                                score = 0;
                                try {
                                    for (const colName in question.value[rowName]) {
                                        if (question.value[rowName][colName] && Array.isArray(question.value[rowName][colName]) &&
                                            choices && Array.isArray(choices)) {
                                            question.value[rowName][colName].forEach(
                                                (val: string) => {
                                                    (score += (choices && Array.isArray(choices)) ? (choices.find(
                                                        (choice: { value: string }) => choice.value === val
                                                    ).score) : 0)
                                                }
                                            );
                                        }
                                    }
                                } catch { }
                            }
                        }
                        break;
                    case 'checkbox':
                        const mapped = question.value
                            .map((el: string) =>
                                choices.find((choice: { value: string }) => choice.value === el) ? true : false
                            )
                            .filter((el: boolean) => el);
                        if (question.scoreType === 'Overall') {
                            score = mapped.length > 0 ? question.score : 0;
                        } else {
                            const allScores = question.value
                                .filter((el: string) => choices.find((choice: { value: string }) => choice.value === el))
                                .filter(
                                    (el: string) =>
                                        choices.find((choice: { value: string }) => choice.value === el).score !==
                                        undefined
                                )
                                .map((el: string) => choices.find((choice: { value: string }) => choice.value === el).score);
                            if (allScores.length) {
                                score = allScores.reduce((a: number, b: number) => a + b, 0);
                            } else if (choices.filter((el: { score: number }) => el.score).length > 0) {
                                score = 0;
                            }
                        }
                        break;
                    default:
                        if (question.scoreType === 'Positive' && isNumeric(question.value)) {
                            if (Number(question.value) > 0) {
                                score = question.score;
                            } else {
                                score = 0;
                            }
                        } else if (question.score !== undefined) {
                            score = question.score;
                        }
                        break;
                }
            }

            resultData[question.id] = {
                name: key,
                value: question.value,
                score,
                // @ts-ignore
                section: question.section || question.page.title
            };
        }
    }

    return resultData;
};

export const serializeSurveyModel = (model: SurveyModel, isComplete: boolean) => ({
    responses: { ...formatModelData(model) },
    pageNo: model.currentPageNo,
    // @todo: fix properly after the demo
    completion: isComplete ? 100 : getSurveyProgress(model),
    updated: Math.floor(Date.now() / 1000),
    status: isComplete ? 'finished' : 'started',
});

// maps survey data from { name, value } to { [name]: value }
export const mapSurveyData = (survey: { name: string, value: unknown }[]) => {
    return Object.keys(survey).reduce(
        (result: { [key: string]: { name: string, value: unknown } }, key: string) =>
            // @ts-ignore
            survey[key].name ? { ...result, [survey[key].name]: survey[key].value } :
                result, {}
    );
};

const getInitialPageIndex = (pages: SurveyPage[]) => {
    const firstIncompletePageIndex = pages.findIndex(isNotCompleted);

    return firstIncompletePageIndex === -1 ?
        pages.length - 1 :
        firstIncompletePageIndex;
};

export const createSurveyModel = (
    data: { surveyData: any, survey: any },
    onChange = nop,
    onSave: (model: SurveyModel, isComplete: boolean) => void = nop
) => {
    const model = new Model(data.surveyData);

    model.data = data.survey.responses ? mapSurveyData(data.survey.responses) : {};
    model.currentPageNo = getInitialPageIndex(model.pages);
    model.sendResultOnPageNext = true;

    // hide native titles and navigation as custom components are used instead
    model.showTitle = false;
    model.showPageTitles = false;
    model.showNavigationButtons = 'none';

    // remove default margin bottom for container
    model.css = {
        container: 'sd-container-modern mb-0',
    };

    model.onValueChanged.add(onChange);
    model.onCurrentPageChanged.add(onChange);
    model.onAfterRenderSurvey.add(onChange);

    model.onComplete.add((model) => onSave(model, true));
    model.onPartialSend.add((model) => onSave(model, false));

    return model;
};
