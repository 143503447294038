import { ReactNode, useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../Button/index';


export const ButtonWithConfirmation = (props: { children: ReactNode, message?: string, onConfirm: () => void }) => {
    const [isConfirmationVisible, setConfirmationVisible] = useState(false);
    const message = props.message || 'Confirm?';

    const onClick = () => setConfirmationVisible(true);

    const onCancel = () => setConfirmationVisible(false);

    const onConfirm = () => {
        setConfirmationVisible(false);
        props.onConfirm();
    };

    return (
        <>
            <ConfirmationModal
                isOpen={isConfirmationVisible}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onClose={onCancel}
            >
                <div>{message}</div>
            </ConfirmationModal>

            <Button size="small" title="Remove invitation" onClick={onClick}>
                {props.children}
            </Button>
        </>
    )
};
