import { string, object } from 'yup';

export const getInvitationValidationSchema = () =>
    object().shape({
        firstName: string().min(1).required('First name is too short'),
        lastName: string().min(1).required('Last name is too short'),
        email: string().email().required('Required'),
        message: string().required('Please write some message'),
    });

export const getInitialFormValues = () => ({
    subject: '',
    email: '',
    message: '',
    firstName: '',
    lastName: '',
    surveyId: '',
});

export const getSurveyById = <T extends { id: string }>(id: string, surveyList: T[]) => surveyList.find((survey) => survey.id === id);
