import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Survey } from 'survey-react-ui';
import { StylesManager } from 'survey-core';
import { useUpdateSurveyProgress } from '../../queries/useUpdateSurveyProgress';
import { Header } from './elements/Header';
import { Navigation } from './elements/Navigation';
import { CompletedMessage } from './elements/CompletedMessage';
import { ConfirmationModal } from '../../components/ConfirmationModal';

import {
  createSurveyModel,
  getAnsweredQuestions,
  getVisibleQuestions,
  getCompleteAreasCount,
  getSurveyProgress,
  serializeSurveyModel,
} from './helpers';

import * as styles from 'survey-core/defaultV2.min.css';

StylesManager.applyTheme('defaultV2');

const SingleSurvey = ({ data }) => {
  const [headerState, setHeaderState] = useState({});
  const [isModalOpened, setModalOpened] = useState(false);
  const { mutate: updateSurveyProgress } = useUpdateSurveyProgress();
  const navigate = useNavigate();

  const closeModal = () => setModalOpened(false);

  const onFinish = (model) => setModalOpened(model.validateCurrentPage());
  const onConfirm = (model) => setModalOpened(!model.completeLastPage());

  const onSave = (...args) =>
    updateSurveyProgress({
      ...data.survey,
      ...serializeSurveyModel(...args),
    });
  const onExit = (model) => {
    onSave(model, false);
    navigate('/');
  };

  const onChange = (model) => {
    const { currentPage, pageCount, title } = model;
    const { title: pageTitle, questions } = currentPage;

    const areaQuestions = getVisibleQuestions(questions);
    const areaAnswers = getAnsweredQuestions(areaQuestions);

    const progress = getSurveyProgress(model);
    const completeAreas = getCompleteAreasCount(model.pages);

    setHeaderState({
      title,
      pageTitle,
      progress,
      areasProgress: `${completeAreas} of ${pageCount}`,
      questionsProgress: `${areaAnswers.length} of ${areaQuestions.length}`,
    });
  };

  const [model] = useState(createSurveyModel(data, onChange, onSave));
  const { isFirstPage, isLastPage } = model;

  if (model.state === 'completed') {
    return <CompletedMessage />;
  }

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpened}
        onClose={closeModal}
        onCancel={closeModal}
        onConfirm={() => onConfirm(model)}
        confirmButtonText="Finish"
        cancelButtonText="Back"
      >
        <p className="mb-1">Once the survey is completed, it cannot be changed.</p>
        <p>Would you like to proceed?</p>
      </ConfirmationModal>

      <Header {...headerState} />

      <div className="max-w-full">
        <Survey model={model} />
      </div>

      <Navigation
        onPrev={isFirstPage ? null : () => model.prevPage()}
        onNext={isLastPage ? null : () => model.nextPage()}
        onExit={() => onExit(model)}
        onFinish={() => onFinish(model)}
      />
    </>
  );
};

export default SingleSurvey;
