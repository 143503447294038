import { formatName } from '.';
import { Invitation } from '../types';
import { string } from 'yup';
import { getInvitationDisplayName } from './index';

export const isEmailValid = (value: string) => string().email().required().isValidSync(value);

export const getLoginMessage = (surveyName: string, fullName = '', advocateEmail: string, advocateFullName: string, link: string) => `
    <p>${fullName},</p>
    <br>
    <p>
        Please use <a style="color: #91b1bf; text-decoration: underline" href="${link}">this link</a> 
        to take the ${surveyName}.
    </p>
    <br>
    <p>
        If you have any questions, please send an email to 
        <a style="color: #91b1bf; text-decoration: underline" href="mailto:${advocateEmail}">${advocateEmail}</a>.
    </p>
    <br>
    <p>
        Sincerely,
        <br>
        ${advocateFullName}
    </p>
    <br>
    <p>
        <a style="color: #91b1bf" href="https://${window.location.host}">Sign In Here</a>
    </p>
`;

export const getRegisterMessage = (surveyName: string, fullName: string, advocateEmail: string, advocateFullName: string, link: string) => `
    <p>${fullName},</p>
    <br>
    <p>
        Please use <a style="color: #91b1bf; text-decoration: underline" href="${link}">this link</a> 
        to register for the MTOmics platform and to take the ${surveyName}.
    </p>
    <br>
    <p>
        If you have any questions, please send an email to 
        <a style="color: #91b1bf; text-decoration: underline" href="mailto:${advocateEmail}">${advocateEmail}</a>.
    </p>
    <br>
    <p>
        Sincerely, 
        </br>
        ${advocateFullName}
    </p>
    <br>
    <p>
        <a style="color: #91b1bf; text-decoration: underline" href="${link}">Sign Up Now</a>
    </p>
`;

export const getRegisterAdvocateMessage = (name: { firstName: string; lastName: string }, role: string, link: string) => `
  <p>${formatName(name, { separator: ' ', order: 'asc' })},</p>
  <br>
  <p>
      Welcome to the Metabolic Terrain Network.
  </p>
  <p>
      Please use <a style="color: #91b1bf; text-decoration: underline" href="${link}">this link</a> to register for the MTOmics platform as ${role}.
  </p>
  <p>
      You will need to register using the same email address to which this invitation was sent as your ID.
  </p>
  <br>
  <p>
      If you have any questions, please send an email to 
      <a style="color: #91b1bf; text-decoration: underline" href="mailto:support@mtomics.com">support@mtomics.com</a>.
  </p>
  <br>
  <p>
      Sincerely, 
      </br>
      MTOmics Customer Support Team
  </p>
  <br>
  <p>
      <a style="color: #91b1bf; text-decoration: underline" href="${link}">Sign Up Now</a>
  </p>
`;

export const getInvitationSignupReminderMessage = (invitation: Invitation, link: string) => `
    <p>${formatName(invitation.user || invitation, { separator: ' ', order: 'asc' })},</p>
    <br>
    <p>This is a reminder that your ${invitation.surveyName || getInvitationDisplayName(invitation.systemSurveys || [])} has not yet been completed.</p>
    <br>
    <p>
        Please use <a style="color: #91b1bf; text-decoration: underline" href="${link}">this link</a> 
        to register for the MTOmics platform and to take the ${invitation.surveyName || getInvitationDisplayName(invitation.systemSurveys || [])}.
    </p>
    <br>
    <p>
        Sincerely,
        <br>
        ${formatName(invitation.advocate || {}, { separator: ' ', order: 'asc' })}
    </p>
    <br>
    <p>
        <a href="https://${window.location.host}">Sign In Here</a>
    </p>
`;

export const getInvitationReminderMessage = (invitation: Invitation, link: string) => `
    <p>${formatName(invitation.user || invitation, { separator: ' ', order: 'asc' })},</p>
    <br>
    <p>This is a reminder that your ${invitation.surveyName || getInvitationDisplayName(invitation.systemSurveys || [])} has not yet been completed.</p>
    <br>
    <p>Please use <a href="${link}">this link</a> to finish the survey so we can meet to discuss the results.</p>
    <br>
    <p>If you have any questions, please send an email to <a href="mailto:${invitation.advocate?.email}">${invitation.advocate?.email}</a>.</p>
    <br>
    <p>
        Sincerely,
        <br>
        ${formatName(invitation.advocate || {}, { separator: ' ', order: 'asc' })}
    </p>
    <br>
    <p>
        <a href="https://${window.location.host}">Sign In Here</a>
    </p>
`;

export const getAdvocateInvitationReminderMessage = (fullName: string, link: string) => `
    <p>${fullName},</p>
    <br>
    <p>This is a reminder that you have not registered for the MTOmics platform.</p>
    <br>
    <p>Please use <a href="${link}">this link</a> to register.</p>
    <br>
    <p>If you have any questions, please send an email to <a href="mailto:support@mtomics.com">support@mtomics.com</a>.</p>
    <br>
    <p>
        Sincerely,
        <br>
        MTOMics Customer Support Team
    </p>
    <br>
    <p>
        <a href="https://${window.location.host}">Sign In Here</a>
    </p>
`;

export const getHtmlBody = (html: string) => `
    <html>
        <body>
            ${html}
        </body>
    </html>
`;
