import { PointerEvent, ReactNode } from 'react';
import { cx } from '../../helpers';

interface Props {
    to?: string,
    title?: string,
    target?: '_blank',
    theme?: keyof typeof LinkTheme;
    children: ReactNode,
    className?: string,
    onClick?: (event: PointerEvent<HTMLAnchorElement>) => void,
}

const LinkTheme = {
    default: 'text-indigo-600 hover:text-indigo-900',
    light: 'text-accent hover:text-secondary',
    dark: 'text-slate-400 hover:text-slate-600',
}

export const Link = ({ to, target, title, theme = 'default', children, className = '', onClick }: Props) => (
    <a
        href={to}
        title={title}
        target={target}
        onClick={onClick}
        className={cx(className, LinkTheme[theme], 'cursor-pointer transition-colors')}
    >
        {children}
    </a>
);
