import { useState } from 'react';
import { Button } from '@/components/Button';
import { InvitationModal } from './modal';

export const InviteButton = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setOpen(true)} ariaLabel="new invite">
                New&nbsp;Invite
            </Button>

            {isOpen && (
                <InvitationModal isOpen={isOpen} onClose={() => setOpen(false)} />
            )}
        </>
    );
};
