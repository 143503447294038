import { SurveyListSimple } from '../widgets/SurveyListSimple';
import { Title } from '../components/Title';
import { useUserAvailableSurveyList } from '../queries/useSurveyList';

const AllPublishedSurveys = () => {
  const { isLoading, isError, data, error } = useUserAvailableSurveyList();

  return (
    <>
      {isLoading && <p> Loading ...</p>}
      {isError && <p> Error while loading: {error.message} </p>}
      {data && console.log(data)}
      {data && (
        <>
          <Title>
            All Surveys
          </Title>

          <SurveyListSimple surveys={data} />
        </>
      )}
    </>
  );
};

export default AllPublishedSurveys;
