import { ReactNode } from 'react';
import { Field, Form, ErrorMessage as FormikErrorMessage, FormikProvider, useFormik } from 'formik';
import { Button } from '../../components/Button/index';
import { string, object } from 'yup';

const ErrorMessage = ({ name }: { name: string }) => <FormikErrorMessage component="span" className="mt-1 text-red-500 text-sm" name={name} />;

const Label = ({ title, children }: { title: string; children: ReactNode }) => (
    <label className="w-full flex flex-col font-normal text-sm text-gray-700 last:mb-0">
        <div className="mb-2">{title}</div>
        {children}
    </label>
);

type FormValues = { surveyId: string };

type Props = {
    surveys: { id: string; name: string }[];
    onCreate: (values: FormValues) => void;
};

export const InvitationLinkForm = ({ surveys, onCreate }: Props) => {
    const onSubmit = (values: FormValues) => onCreate(values);

    const formik = useFormik({
        onSubmit,
        initialValues: { surveyId: '' },
        validationSchema: object().shape({
            surveyId: string().required('Required'),
        }),
        validateOnBlur: true,
        validateOnChange: false,
    });

    return (
        <FormikProvider value={formik}>
            <Form action="#" className="relative flex flex-col gap-6">
                <label className="w-full flex flex-col font-normal text-base text-gray-700 last:mb-0">
                    <div className="mb-2">Create unique link connected to your account to publish or send.</div>
                </label>
                <Label title="Survey">
                    <Field
                        as="select"
                        id="surveyId"
                        name="surveyId"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                        <option disabled value="">
                            -- select a survey --
                        </option>

                        {surveys.map((survey) => (
                            <option key={survey.id} value={survey.id}>
                                {survey.name}
                            </option>
                        ))}
                    </Field>

                    <ErrorMessage name="surveyId" />
                </Label>

                <div className="bg-white gap-2">
                    <Button type="submit" onClick={formik.handleSubmit}>
                        Copy Link
                    </Button>{' '}
                    <ErrorMessage name="message" />
                </div>
            </Form>
        </FormikProvider>
    );
};
