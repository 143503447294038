
import { Head, Table, Th, Body, Td } from '@/components/Table';
import { SurveyProgress } from '@/components/SurveyProgress';
import { SurveyStatus } from '@/components/SurveyStatus';
import { Link } from '@/components/Link';
import { useUserSurveyList } from '@/queries/useSurveyList';
import { formatName, formatDate, compareByFullName } from '@/helpers';
import { useSortBy } from '@/hooks/useSortBy';
import { SortArrows } from '@/components/SortArrows';
import { User } from '@/types';

const sortFunctionsMap = {
    advocate: <T extends { advocate?: User }>(a: T, b: T) => {
        if (a.advocate && b.advocate) {
            return compareByFullName(a.advocate, b.advocate);
        }

        if (a.advocate) {
            return 1;
        }

        if (b.advocate) {
            return -1;
        }

        return 0;
    },
};

export const PatientSurveyListWidget = () => {
    const { isLoading, isError, data = [], error } = useUserSurveyList();

    const sorting = useSortBy(data, { sortFunctionsMap, defaultSortKey: 'updated', defaultSortOrder: 'desc' });

    const header = [
        { title: 'Name', sortKey: 'name' },
        { title: 'Sent From', sortKey: 'advocateFullName', align: 'center' },
        { title: 'Status', sortKey: 'status', align: 'center' },
        { title: 'Completion', sortKey: 'completion', align: 'center' },
        { title: 'Last Activity', sortKey: 'updated', align: 'center' },
        { title: 'Actions', align: 'center' },
    ];

    const getHeaderCellOptions = (key: string) => {
        return sorting.sortKey === key ? (sorting.isReverse ? 'desc' : 'asc') : 'none';
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error fetching list of surveys: {error}</>;
    }

    return (
        <Table>
            <Head>
                {header.map((item) => (
                    <Th
                        key={item.title}
                        align={item.align as 'center'}
                        onClick={item.sortKey ? () => sorting.setSorting(item.sortKey) : undefined}
                    >
                        {item.title}

                        {item.sortKey && <SortArrows direction={getHeaderCellOptions(item.sortKey)} />}
                    </Th>
                ))}
            </Head>

            <Body>
                {sorting.items.map((survey) => (
                    <tr key={survey.id} className="even:bg-basic/10">
                        <Td>{survey.name}</Td>

                        <Td>
                            {survey.advocate && (
                                <>
                                    {formatName(survey.advocate)} <div className="text-xs text-gray-400">{survey.advocate.email}</div>
                                </>
                            )}
                        </Td>

                        <Td align="center">
                            <SurveyStatus status={survey.status} />
                        </Td>

                        <Td align="center">
                            <SurveyProgress progress={survey.completion} status={survey.status} />
                        </Td>

                        <Td>{formatDate(survey.updated)}</Td>

                        <Td>
                            <div className="flex justify-center">
                                {survey.status !== 'finished' && (
                                    <Link to={`/survey/${survey.id}`}>{survey.completion === 0 ? 'Take Survey' : 'Resume Survey'}</Link>
                                )}

                                {survey.status === 'finished' && <Link to={`/advocate/survey/${survey.id}`}>View results</Link>}
                            </div>
                        </Td>
                    </tr>
                ))}
            </Body>
        </Table>
    );
};
