import { useQuery } from '@tanstack/react-query';
import { sendRequest } from '../../services/api';
import { UUID } from 'crypto';
import { User } from '@/types';

export const useSurveyList = <T>(path: string) => {
    return useQuery<T>({
        queryKey: [path],
        queryFn: () => sendRequest(path),
    });
};

export const useUserSurveyList = () => useSurveyList<({
    id: UUID;
    invitationId?: UUID;
    surveyId: UUID;
    userId: UUID;
    advocateId: UUID;
    updated: number;
    name: string;
    status: 'assigned' | 'finished';
    completion: number;
    region: string;
    version?: string;
    pageNo?: string;
    responses?: unknown;
    advocate?: User;
})[]>('user/my/surveys');

export const useUserAvailableSurveyList = () => useSurveyList('user/surveys');

export const useAdvocateSurveyList = () => useSurveyList('advocate/surveys');

export const useAdvocateAvailableSurveyList = () => useSurveyList<({
    id: UUID;
    name: string;
    airtable?: string;
    status?: string;
    updated?: number;
})[]>('advocate/published/surveys');

export const useManagerSurveyList = () => useSurveyList('manager/surveys');

export const useAdminSurveyList = () => useSurveyList('admin/surveys');
