import { ReactNode } from 'react';


interface Props {
    align?: 'center' | 'left' | 'right',
    children: ReactNode,
    onClick?: () => void,
}


const Align = {
    'center': 'justify-center',
    'left': 'justify-start',
    'right': 'justify-end',
};


export const Cell = ({ align = 'left', children, onClick }: Props) => (
    <th
        scope="col"
        onClick={onClick}
        className="py-3.5 pl-4 pr-4 text-sm font-semibold text-slate-800 first:rounded-tl-md last:rounded-tr-md"
    >
        <div className={`flex ${Align[align]} items-center select-none ${onClick ? 'cursor-pointer' : ''}`}>
            {children}
        </div>
    </th>
);
