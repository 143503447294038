import { UUID } from 'crypto';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Title } from '@/components/Title';
import { Button } from '@/components/Button/index';
import { Body, Head, Table, Td, Th } from '@/components/Table';

import { useSortBy } from '@/hooks/useSortBy';
import { useMyProfile } from '@/queries/useMyProfile';
import { useAssignSurvey } from '@/queries/useAssignSurvey';
import { useAdvocatePatientList } from '@/queries/usePatientList';
import { useAdminAdvocateInvitationList } from '@/queries/useInvitationList';

import { InvitationList } from '@/widgets/InvitationList';
import { UserSearchModal } from '@/widgets/UserSearch/UserSearchModal';
import { InvitationReminderModal } from '@/widgets/SendReminder/InvitationReminderModal';

import { findInvitationById, formatName, sortByStatus } from '@/helpers/index';

import { AllowedSurveyActionsAll } from '@/types';


const AdminAdvocateInvitations = () => {
    const { slug } = useParams<{ slug: UUID }>();
    const location = useLocation();
    const navigate = useNavigate();
    const hasBackHistory = location.key !== 'default';

    const { mutateAsync: assignSurvey } = useAssignSurvey();
    const { isLoading, isError, data: invitations = [], error } = useAdminAdvocateInvitationList(slug!);
    const { data: advocate = {} } = useMyProfile();
    const { data: patients = [] } = useAdvocatePatientList({ advocateId: slug! });

    const sorting = useSortBy(invitations, { sortFunctionsMap: { status: sortByStatus }, defaultSortKey: 'fullName' });

    const [invitationId, setInvitationId] = useState('');
    const [surveyId, setSurveyId] = useState('');

    const selectedInvitation = Object.assign({ advocate }, findInvitationById(invitations, invitationId));

    if (isLoading) {
        return <p>Loading ...</p>;
    }

    if (isError) {
        return <p>Error while loading: {(error as Error).message}</p>;
    }

    return (
        <>
            <InvitationReminderModal isAdmin={true} isOpen={Boolean(invitationId)} onClose={() => setInvitationId('')} invitation={selectedInvitation} />
            {hasBackHistory && (
                <div className="mb-6">
                    {' '}
                    <Button
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Back
                    </Button>
                </div>
            )}
            <UserSearchModal
                label="Assign"
                isOpen={Boolean(surveyId)}
                onClick={async (userId: string) => {
                    await toast.promise(assignSurvey({ advocateId: userId, surveyId }), {
                        pending: 'Assigning the survey...',
                        success: 'Survey has been assigned.',
                        error: 'Failed to assigned the survey.',
                    });
                    setSurveyId('');
                }}
                onClose={() => setSurveyId('')}
            />
            <Title> All Survey Invitations</Title>

            <InvitationList
                allowedActions={AllowedSurveyActionsAll}
                items={sorting.items}
                sort={sorting}
                onClick={setInvitationId}
                onUserSearchClick={setSurveyId}
            />

            <div className="mt-12">
                <Title>Patients List</Title>

                <Table>
                    <Head>
                        <Th>Id</Th>
                        <Th>Email</Th>
                        <Th>Name</Th>
                    </Head>

                    {!patients.length && (
                        <Body>
                            <tr>
                                <Td colspan={3}>No patients</Td>
                            </tr>
                        </Body>
                    )}

                    <Body>
                        {patients.map((patient) => (
                            <tr key={patient.userId}>
                                <Td>{patient.userId}</Td>
                                <Td>{patient.email}</Td>
                                <Td>{formatName(patient)}</Td>
                            </tr>
                        ))}
                    </Body>
                </Table>
            </div>
        </>
    );
};

export default AdminAdvocateInvitations;
