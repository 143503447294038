import { useState } from 'react';
import { useUpdateAccessList } from '../../queries/useUpdateAccessList';
import { UserSearchForm } from '../UserSearch/UserSearchForm';
import { User } from '../../types';
import { Head, Table, Body, Td, Th } from '../../components/Table';
import { formatDate, formatName } from '../../helpers';
import { Link } from '../../components/Link';
import { Spinner } from '../../components/Spinner';
import { Button } from '../../components/Button/index';
import { toast } from 'react-toastify';

interface Props {
    user: User;
    onClose: () => void;
}

export const UserAccessForm = ({ user, onClose }: Props) => {
    const { isPending: isAssigning, mutateAsync: updateList } = useUpdateAccessList();
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [removedUsers, setRemovedUsers] = useState<User[]>([]);

    const tableHeader = [
        { title: 'Name', sortKey: 'fullName' },
        { title: 'Role', sortKey: 'role' },
        { title: 'Last Activity', sortKey: 'lastLogin' },
        { title: 'Actions', align: 'center' },
    ];

    const hasUser = user.userAccess.filter((user) => !removedUsers.find((removed) => removed.userId === user.userId)).length > 0 || selectedUsers.length > 0;

    return (
        <div className="flex flex-col gap-3">
            {hasUser && (
                <Table>
                    <Head>
                        {tableHeader.map((item) => (
                            <Th key={item.title}>{item.title}</Th>
                        ))}
                    </Head>

                    <Body>
                        {user.userAccess
                            .filter((user) => !removedUsers.find((removed) => removed.userId === user.userId))
                            .map((user) => (
                                <tr key={user.userId} className="even:bg-basic/10">
                                    <Td>
                                        {formatName(user)} <p className="text-sm leading-6 text-gray-500">{user.email}</p>
                                    </Td>
                                    <Td>{user.role}</Td>
                                    <Td>{user.lastLogin ? formatDate(user.lastLogin) : '-'}</Td>
                                    <Td align="center">
                                        <Link
                                            onClick={() => {
                                                setRemovedUsers((old) => [...old, user]);
                                            }}
                                        >
                                            Remove
                                        </Link>
                                    </Td>
                                </tr>
                            ))}
                        {selectedUsers.map((user) => (
                            <tr key={user.userId} className="even:bg-basic/10">
                                <Td>
                                    {formatName(user)} <p className="text-sm leading-6 text-gray-500">{user.email}</p>
                                </Td>
                                <Td>{user.role}</Td>
                                <Td>{user.lastLogin ? formatDate(user.lastLogin) : '-'}</Td>
                                <Td align="center">
                                    <Link
                                        onClick={() => {
                                            setSelectedUsers((old) => old.filter((u) => u.userId !== user.userId));
                                        }}
                                    >
                                        Remove
                                    </Link>
                                </Td>
                            </tr>
                        ))}
                    </Body>
                </Table>
            )}

            <UserSearchForm
                onSubmit={(user) => {
                    setSelectedUsers((old) => (old.find((oldUser) => oldUser.userId === user.userId) ? old : [...old, user]));
                }}
                assignLabel="Add"
            />

            <Button
                type="submit"
                disabled={isAssigning}
                onClick={async () => {
                    await toast.promise(
                        updateList({ userId: user.userId, addList: selectedUsers.map((u) => u.userId), removeList: removedUsers.map((u) => u.userId) }),
                        {
                            pending: 'Updating access list...',
                            success: 'Access list has been updated.',
                            error: 'Failed to update the access list.',
                        },
                    );
                    onClose();
                }}
            >
                <div className="mt-4 flex md:mt-0 justify-center">{isAssigning && <Spinner />}Save</div>
            </Button>
        </div>
    );
};
