export default {
    Auth: {
        storage: sessionStorage,
        region: import.meta.env.VITE_REGION,
        userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        userPoolWebClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
        signupAttributes: ['EMAIL', 'NAME', 'FAMILY_NAME'],
        oauth: {
            domain: import.meta.env.VITE_COGNITO_DOMAIN,
            scope: ['phone', 'email', 'openid', 'profile'],
            redirectSignIn: import.meta.env.VITE_APP_URL,
            redirectSignOut: import.meta.env.VITE_APP_URL,
            responseType: 'code',
            options: {
                AdvancedSecurityDataCollectionFlag: true,
            },
        },
    },
    API: {
        endpoints: [
            {
                name: 'main',
                endpoint: import.meta.env.VITE_API_URL,
            },
        ],
    },
};
