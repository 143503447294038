import { UUID } from 'crypto';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Head, Table, Th, Body, Td } from '@/components/Table';
import { SurveyProgress } from '@/components/SurveyProgress';
import { SurveyStatus } from '@/components/SurveyStatus';
import { LinkButton } from '@/components/LinkButton';
import { SortArrows } from '@/components/SortArrows';
import { Button } from '@/components/Button';
import { Title } from '@/components/Title';
import { Excel } from '@/components/Icons/Excel';
import { Pdf } from '@/components/Icons/Pdf';
import { SurveyReminderButton } from '@/widgets/SendReminder';
import { useUserSurveyList } from '@/queries/useUserSurveyList';
import { useReportDownload } from '@/hooks/useReportDownload';
import { useSortBy } from '@/hooks/useSortBy';
import { isExcelReportEnabled } from '@/helpers/report';
import { formatDate } from '@/helpers';
import { Spinner } from '@/components/Spinner';

// @todo: rework
export const SurveyListWidget = ({ userId }: { userId: UUID }) => {
    const { data = [], isLoading } = useUserSurveyList(userId);
    const downloadReport = useReportDownload();

    const sorting = useSortBy(data, { defaultSortKey: 'updated', defaultSortOrder: 'desc' });

    const header = [
        { title: 'Survey', sortKey: 'name' },
        { title: 'Status', sortKey: 'status', align: 'center' },
        { title: 'Progress', sortKey: 'completion', align: 'center' },
        { title: 'Last Activity', sortKey: 'updated', align: 'center' },
        { title: 'Actions', align: 'center' },
    ];

    const getHeaderCellOptions = (key: string) => {
        return sorting.sortKey === key ? (sorting.isReverse ? 'desc' : 'asc') : 'none';
    };

    return (
        <div>
            <Title>
                Surveys
            </Title>

            <Table>
                <Head>
                    {header.map((item) => (
                        <Th
                            key={item.title}
                            align={item.align as 'center'}
                            onClick={item.sortKey ? () => sorting.setSorting(item.sortKey) : undefined}
                        >
                            {item.title}

                            {item.sortKey && <SortArrows direction={getHeaderCellOptions(item.sortKey)} />}
                        </Th>
                    ))}
                </Head>

                {isLoading && (
                    <Body>
                        <tr>
                            <Td align="center" colspan={5}>
                                <Spinner classList="text-slate-800 inline-block" />
                            </Td>
                        </tr>
                    </Body>
                )}

                {/* {!data.length && (
                    <Body>
                        <tr>
                            <Td align="center" colspan={5}>No assigned surveys</Td>
                        </tr>
                    </Body>
                )} */}

                <Body>
                    {sorting.items.map((item) => (
                        <tr key={item.id}>
                            <Td>
                                {item.name}
                            </Td>

                            <Td align="center">
                                <SurveyStatus status={item.status} />
                            </Td>

                            <Td>
                                <SurveyProgress progress={item.completion} status={item.status} />
                            </Td>

                            <Td align="center">
                                {formatDate(item.updated)}
                            </Td>

                            <Td>
                                <div className="flex flex-row justify-center items-center gap-1">
                                    {item.status !== 'finished' && (
                                        <>
                                            <SurveyReminderButton survey={item} />
                                        </>
                                    )}

                                    {item.status === 'finished' && ( /* && isActionAllowed(AllowedSurveyActions.VIEW_RESULTS) && ( */
                                        <>
                                            <LinkButton size="small" title="View results" to={`/advocate/survey/${item.id}`}>
                                                <DocumentMagnifyingGlassIcon className="w-4 h-4 text-slate-600 hover:text-slate-800" />
                                            </LinkButton>

                                            <Button size="small" title="Download as pdf" onClick={() => downloadReport('pdf', item.id)}>
                                                <span className="text-red-600 hover:text-red-900">
                                                    <Pdf />
                                                </span>
                                            </Button>

                                            {isExcelReportEnabled(item.surveyId) && (
                                                <Button size="small" title="Download as excel" onClick={() => downloadReport('excel', item.id)}>
                                                    <span className="text-green-600 hover:text-green-900">
                                                        <Excel />
                                                    </span>
                                                </Button>
                                            )}

                                            {/* @todo: move it to admin panel */}
                                            {/* <ReassignSurveyButton surveyId={item.id} /> */}
                                        </>
                                    )}
                                </div>
                            </Td>
                        </tr>
                    ))}
                </Body>
            </Table>
        </div>
    )
};
