import { ComponentProps, useState } from 'react';
import { Button } from '../../../components/Button/index';
import { Tabs } from '../../../components/Tabs';
import { Modal } from '../../../components/Modal';
import { UserEditForm } from '../UserEditForm';
import { UserAccessForm } from '../../UserAccess';
import { User, UserRole } from '../../../types';

type Props = {
    selectedUser: User | undefined;
    allowedEditRoles: UserRole[],
    onSubmit: (params: { userId: string; prevRole: UserRole; newRole: UserRole }) => void
} & Omit<
    ComponentProps<typeof Modal>,
    'children'
>;

export const UserEditModal = ({ selectedUser, isOpen, onSubmit, onClose, allowedEditRoles }: Props) => {
    const tabs = selectedUser?.role === 'patient' ? ['Role', 'Assign To'] : ['Role'];
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const _onClose = () => {
        setSelectedTab(undefined);
        onClose();
    };

    return (
        <Modal size="medium" isOpen={isOpen} onClose={_onClose}        >
            <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                onChange={setSelectedTab}
            />

            {selectedTab === 'Role' && selectedUser && (
                <UserEditForm user={selectedUser} onSubmit={onSubmit} roles={allowedEditRoles} />
            )}

            {selectedTab === 'Assign To' && (
                <UserAccessForm user={selectedUser!} onClose={onClose} />
            )}

            <div className="flex flex-auto flex-col justify-between my-4">
                <Button onClick={_onClose}>Close</Button>
            </div>
        </Modal>
    );
};
