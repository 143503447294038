import { useState, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../../../components/Spinner';
import { useAdvocatesUserList } from '../../../queries/useUserList';
import { cx, formatDate, formatName } from '../../../helpers';
import { Profile } from '../../../components/Icons/Profile';
import { Button } from '../../../components/Button/index';
import { User } from '../../../types';

interface Props {
    assignLabel: string;
    onSubmit: (advocate: User) => void;
}

export const UserSearchForm = ({ assignLabel, onSubmit }: Props) => {
    const [query, setQuery] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<any>();
    const [isWaiting, setIsWaiting] = useState(false);
    const { isLoading: isLoadingList, isError, data: advocates } = useAdvocatesUserList({ limit: 10, enabled: true });
    const { isLoading, isError: isErrorFiltering, data: advocatesFiltered, refetch } = useAdvocatesUserList({ query, limit: 10, enabled: false });

    useEffect(() => {
        if (query !== '') {
            const timeoutId = setTimeout(() => {
                setIsWaiting(false);
                refetch();
            }, 1000);
            return () => {
                clearTimeout(timeoutId);
                setIsWaiting(false);
            };
        }
        setIsWaiting(query !== '');
    }, [query]);

    if (isLoadingList) {
        return (
            <div className="flex ml-5 mt-2 w-full items-center justify-center">
                <Spinner classList="text-black" />
            </div>
        );
    }

    return (
        <Combobox
            onChange={(person: any) => {
                window.location === person.profileUrl;
                setSelectedPerson(person);
            }}
        >
            {({ activeOption }) => (
                <div className="h-116">
                    <div className="relative">
                        <MagnifyingGlassIcon className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <Combobox.Input
                            className="h-12 w-full border-1 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Search... (LastName FirstName)"
                            onChange={(event) => setQuery(event.target.value?.trim().toLowerCase())}
                        />
                    </div>

                    {(isLoading || isWaiting) && (
                        <div className="flex ml-5 mt-2">
                            {' '}
                            <Spinner classList="text-black" />
                        </div>
                    )}

                    {!isLoading && (query === '' || Boolean(advocatesFiltered?.users?.length)) && (
                        <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                            <div className={cx('max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96')}>
                                {query === '' && <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500"></h2>}
                                <div className="-mx-2 text-sm text-gray-700">
                                    {((query === '' ? advocates?.users : advocatesFiltered?.users) || []).map((person) => (
                                        <Combobox.Option
                                            as="div"
                                            key={person.userId}
                                            value={person}
                                            className={({ active }) =>
                                                cx('flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900')
                                            }
                                        >
                                            {({ active }) => (
                                                <>
                                                    <Profile />
                                                    <span className="ml-3 flex-auto truncate">
                                                        {formatName(person)}
                                                        <p className="text-sm leading-6 text-gray-500">{person.role}</p>
                                                    </span>
                                                    {active && <ChevronRightIcon className="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </div>
                            </div>

                            {selectedPerson && (
                                <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                    <div className="flex p-6 text-center flex-row">
                                        <div className="flex justify-center items-center">
                                            {' '}
                                            <Profile />
                                        </div>

                                        <div className="flex justify-center items-center pl-6">
                                            {' '}
                                            <h2 className="font-semibold text-gray-900">{formatName(selectedPerson)}</h2>
                                        </div>
                                    </div>
                                    <div className="flex flex-auto flex-col justify-between p-6">
                                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                            <dt className="col-end-1 font-semibold text-gray-900">Role</dt>
                                            <dd>{selectedPerson.role}</dd>
                                            <dt className="col-end-1 font-semibold text-gray-900">Region</dt>
                                            <dd>{selectedPerson.region}</dd>
                                            <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
                                            <dd className="truncate">
                                                <a href={`mailto:${selectedPerson.email}`} className="text-indigo-600 underline">
                                                    {selectedPerson.email}
                                                </a>
                                            </dd>
                                            <dt className="col-end-1 font-semibold text-gray-900">Last Activity</dt>
                                            <dd className="truncate">{selectedPerson?.lastLogin ? formatDate(selectedPerson?.lastLogin) : '-'}</dd>
                                        </dl>

                                        <Button onClick={() => onSubmit(selectedPerson)}>{assignLabel}</Button>
                                    </div>
                                </div>
                            )}
                        </Combobox.Options>
                    )}

                    {(isError || isErrorFiltering) && (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                            <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                            <p className="mt-4 font-semibold text-gray-900">Failed to load the list.</p>
                        </div>
                    )}

                    {!(isError || isErrorFiltering) && query !== '' && !isWaiting && !isLoading && !advocatesFiltered?.users?.length && (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                            <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                            <p className="mt-4 font-semibold text-gray-900">No advocates/clinicians found</p>
                            <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                        </div>
                    )}
                </div>
            )}
        </Combobox>
    );
};
