import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

interface RequestPayload {
    email: string;
    role: string;
    subject: string;
    message: string;
    firstName: string;
    lastName: string;
    date: number;
}


export const useCreateAdvocateInvitation = () => {
    const queryClient = useQueryClient();

    return useMutation<any, Error, RequestPayload, any>({
        mutationFn: async (body) => {
            return sendRequest('manager/invitation', { body, method: HttpMethod.POST });
        },
        onSuccess: () => {
            return queryClient.refetchQueries({ queryKey: ['users'] });
        },
        onError: (error) => {
            console.log(error);
        },
    });
};
