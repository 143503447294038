import { useQuery } from '@tanstack/react-query';
import { sendRequest } from '../../services/api';

interface QueryResult {
    advocates: [];
    unassignedSurveys: [];
}

export const useAdminInvitesList = () => {

    return useQuery<QueryResult>({
        queryKey: ['manager/invitations'],
        queryFn: async () => sendRequest<QueryResult>('manager/invitations'),
    });
};
