import { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute, useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { ErrorMessage, FieldAttributes, useField } from 'formik';
import * as InputComponent from '@/components/Input';

interface Props {
    name: string;
    value: string;
    type?: HTMLInputTypeAttribute;
    placeholder?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Input = (props: Props) => {
    return (
        <input
            {...props}
            className="shadow-sm w-full appearance-none rounded-lg border border-slate-200 bg-white text-slate-600 placeholder:text-slate-400 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 text-sm py-2 px-2"
        />
    )
};

export const InputPassword = (props: Omit<Props, 'type'>) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);

    return (
        <div className="relative">
            <Input type={isPasswordVisible ? 'text' : 'password'} {...props} />

            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-2.5 bottom-2 cursor-pointer"
            >
                {isPasswordVisible ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
            </button>
        </div>
    );
};

export const InputField = ({ label = '', ...props }: FieldAttributes<{}> & { label?: string }) => {
    const [inputProps, meta] = useField(props.name);

    return (
        <label className="flex flex-col grow gap-2 font-normal text-slate-700">
            <span>
                {label}

                {meta.touched && meta.error && (
                    <ErrorMessage
                        name={inputProps.name}
                        component="span"
                        className="text-red-400 before:content-['_-_']"
                    />
                )}
            </span>

            {(
                props.type === 'password'
                    ? <InputComponent.InputPassword {...inputProps} />
                    : <InputComponent.Input {...inputProps} />
            )}
        </label>
    );
};
