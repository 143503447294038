import { ComponentProps, useState } from 'react';
import { useCreateInvitation } from '../../queries/useCreateInvitation';
import { useAdvocateAvailableSurveyList } from '../../queries/useSurveyList';
import { InvitationForm } from './form';
import { InvitationLinkForm } from './linkForm';
import { getCurrentTimestamp, formatName } from '../../helpers';
import { Modal } from '../../components/Modal';
import { toast } from 'react-toastify';
import { copyToClipboard } from '../../helpers';
import { Tabs } from '../../components/Tabs';
import { useAuth } from '../../hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import { queryKey as invitationListQueryKey } from '@/queries/useInvitationList';

type Props = {} & Omit<ComponentProps<typeof Modal>, 'children'>;

const copySurveyUrl = (surveyId: string, userId: string) => {
    const url = location.protocol + '//' + location.host + '/new/survey/' + surveyId + `?advocateId=${userId}` + '&mode=signup';

    return copyToClipboard(url)
        .then(() => toast.success('Signup url copied'))
        .catch((error) => console.log('error copying url to clipboard:', error));
};

export const InvitationModal = ({ isOpen, onClose }: Props) => {
    const { mutateAsync: createInvitation } = useCreateInvitation();
    const { data: surveyList = [] } = useAdvocateAvailableSurveyList();
    const [selectedTab, setSelectedTab] = useState('Email');
    const { user } = useAuth();
    const queryClient = useQueryClient();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Tabs selectedTab={selectedTab} tabs={['Email', 'Link']} onChange={setSelectedTab} />

            {selectedTab === 'Email' && (
                <InvitationForm
                    surveys={surveyList}
                    onCreate={({ email, ...props }) => {
                        const action = async () => {
                            await createInvitation({
                                id: globalThis.crypto.randomUUID(),
                                status: 'sent',
                                fullName: formatName(props),
                                date: getCurrentTimestamp(),
                                email: email.toLowerCase(),
                                ...props,
                            });

                            await queryClient.invalidateQueries({
                                queryKey: [invitationListQueryKey]
                            });
                        };

                        toast.promise(action, { pending: 'Creating an invitation...' });

                        onClose();
                    }}
                />
            )}
            {selectedTab === 'Link' && (
                <InvitationLinkForm
                    surveys={surveyList}
                    onCreate={(values) => {
                        copySurveyUrl(values.surveyId, user.id);
                        onClose();
                    }}
                />
            )}
        </Modal>
    );
};
