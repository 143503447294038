import { UUID } from 'crypto';
import { ReactNode } from 'react';
import { Table, Head, Body, Th, Td } from '../../components/Table';
import { SortArrows } from '../../components/SortArrows';
import { TableGroupHeader } from '../../components/TableGroupHeader';
import { useGroupVisibilityToggle } from '../../hooks/useGroupVisiblityToggle';
import { TableRow } from './elements/TableRow';
import { formatDate, /* formatName, */ getSortDirection } from '../../helpers';
import { Invitation, AllowedSurveyActions } from '../../types';
import { useInvitationList } from '@/queries/useInvitationList';
import { SurveyStatus } from '@/components/SurveyStatus';
import { Title } from '@/components/Title';
import { SurveyProgress } from '@/components/SurveyProgress';
import { InvitationReminderButton } from '../SendReminder';
import { useSortBy } from '@/hooks/useSortBy';
import { Spinner } from '@/components/Spinner';
// import { InvitationDeleteButton } from '../InvitationDelete';

interface Props {
    type?: 'flat' | 'groups';
    allowedActions: AllowedSurveyActions[];
    items: Invitation[] | [ReactNode, Invitation[]][];
    sort: {
        sortKey: string;
        isReverse: boolean;
        setSorting: (sortKey: string) => void;
    };
    onClick: (invitationId: UUID) => void;
    onDelete?: (InvitationId: UUID) => void;
    onUserSearchClick?: (surveyId: UUID) => void;
}

const isGroup = (item: unknown): item is [ReactNode, Invitation[]] => Array.isArray(item) && item.length === 2;

const getTableHeaderConfiguration = () => [
    { title: 'Name', sortKey: 'fullName' },
    { title: 'Survey', sortKey: 'surveyName' },
    { title: 'Status', sortKey: 'status', align: 'center' },
    { title: 'Last Activity', sortKey: 'date' },
    { title: 'Progress', align: 'center' },
    { title: 'Actions', align: 'center' },
] as {
    title: string;
    sortKey?: string;
    align?: 'center';
}[];

export const InvitationListWidget = (props: { email: string }) => {
    const header = [
        { title: 'Survey', sortKey: 'surveyName' },
        { title: 'Status', sortKey: 'status', align: 'center' },
        { title: 'Progress', sortKey: 'completion', align: 'center' },
        { title: 'Last Activity', sortKey: 'date', align: 'center' },
        { title: 'Actions', align: 'center' },
    ];

    const { data = [], isLoading } = useInvitationList();

    const invitations = data
        .filter(({ email }) => email === props.email)
        .filter(({ used }) => !used)
        .map(({ id, createdOn, surveys }) => surveys.map((survey) => ({
            surveyId: survey.id,
            surveyName: survey.name,
            invitationId: id,
            invitationCreatedOn: createdOn,
        })))
        .flat();

    const sorting = useSortBy(invitations, {});

    const getHeaderCellOptions = (key: string) => {
        return sorting.sortKey === key ? (sorting.isReverse ? 'desc' : 'asc') : 'none';
    };

    return (
        <div>
            <Title>
                Invitations
            </Title>

            <Table>
                <Head>
                    {header.map((item) => (
                        <Th
                            key={item.title}
                            align={item.align as 'center' | undefined}
                            onClick={item.sortKey ? () => sorting.setSorting(item.sortKey) : undefined}
                        >
                            {item.title}

                            {item.sortKey && <SortArrows direction={getHeaderCellOptions(item.sortKey)} />}
                        </Th>
                    ))}
                </Head>

                {isLoading && (
                    <Body>
                        <tr>
                            <Td align="center" colspan={5}>
                                <Spinner classList="text-slate-800 inline-block" />
                            </Td>
                        </tr>
                    </Body>
                )}

                <Body>
                    {sorting.items.map((item) => (
                        <tr key={`${item.surveyId}_${item.invitationId}`}>
                            <Td>
                                {item.surveyName}
                            </Td>

                            <Td align="center">
                                <SurveyStatus status="sent" />
                            </Td>

                            <Td align="center">
                                <SurveyProgress progress={0} status="sent" />
                            </Td>

                            <Td align="center">
                                {formatDate(item.invitationCreatedOn)}
                            </Td>

                            <Td align="center">
                                <div className="flex gap-2 justify-center">
                                    <InvitationReminderButton invitationId={item.invitationId} />
                                    {/* <InvitationDeleteButton invitationId={invitation.id} message={`Delete invitation for ${formatName(invitation)}?`} /> */}
                                </div>
                            </Td>
                        </tr>
                    ))}
                </Body>
            </Table>
        </div>
    );
};

export const InvitationList = ({ allowedActions, items, sort, onClick, onDelete, onUserSearchClick, type = 'flat' }: Props) => {
    const { sortKey, isReverse, setSorting } = sort;

    const tableHeader = getTableHeaderConfiguration();
    const tableRowProps = { onClick, onDelete, onUserSearchClick };

    const [hiddenGroups, toggleGroupVisibility] = useGroupVisibilityToggle(type === 'groups' ? items.map((_, index) => index) : []);

    return (
        <Table>
            <Head>
                {tableHeader.map((item) => (
                    <Th onClick={() => item.sortKey && setSorting(item.sortKey)} align={item.align} key={item.title}>
                        {item.title}
                        {item.sortKey && <SortArrows direction={getSortDirection(item.sortKey, sortKey, isReverse)} />}
                    </Th>
                ))}
            </Head>
            {items.map((item, index) => (
                <Body key={index}>
                    {isGroup(item) && (
                        <TableGroupHeader onClick={() => toggleGroupVisibility(index)} isCollapsed={hiddenGroups.includes(index)}>
                            {item[0]}
                        </TableGroupHeader>
                    )}

                    {isGroup(item) && !hiddenGroups.includes(index) && (
                        item[1].map((item, index) => <TableRow {...item} {...tableRowProps} key={`${item.id}_${index}`} allowedActions={allowedActions} />)
                    )}

                    {!isGroup(item) && (
                        <TableRow {...item} {...tableRowProps} key={`${item.id}_${index}`} allowedActions={allowedActions} />
                    )}
                </Body>
            ))}
        </Table >
    );
};
