import { SurveyVersionList } from '../widgets/SurveyVersionList';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAdminSurvey } from '../queries/useSurvey';
import { Title } from '../components/Title';

const SurveyVersions = () => {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, isError, data, error } = useAdminSurvey(slug, {
    isVersions: true,
  });

  return (
    <>
      {isLoading && <p> Loading ...</p>}
      {isError && <p> Error while loading: {error.message} </p>}
      {data && console.log(data)}
      {data && (
        <>
          <Title>
            {searchParams.get('name')}
          </Title>
          <SurveyVersionList id={slug} surveys={data} />
        </>
      )}
    </>
  );
};

export default SurveyVersions;
