import { UUID } from 'crypto';

/**
 * Promotion for metabolic health day on 10/10/24
 * 
 * Shown when there is generic terrain ten survey link is presented and no advocate is associtaed
 *
 * @ref: https://app.asana.com/0/home/1204170414629771/1208263612036238
 */
export const isTerrainTenPromotion = (surveyId: UUID | null, advocateId?: UUID) => {
    const surveyIds: UUID[] = ['af89c3e6-8d6c-4db4-86fe-884bf5df11ee'];

    return !advocateId && surveyId && surveyIds.includes(surveyId);
};
