import { UUID } from 'crypto';
import { useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { captureException } from '@sentry/react';
import { Link } from '@/components/Link';
import { Modal } from '@/components/Modal';
import { Button } from '@/components/Button/index';
import { Checkbox } from '@/components/Checkbox';
import { InputField } from '@/components/Input';
import { Spinner } from '@/components/Spinner';
import { extractUUID } from '@/helpers';
import { signUp } from '@/services/auth';
import { isTerrainTenPromotion } from '@/services/promotions';
import { signupSchema } from '../helpers/validation';

const agreementList = [{
    name: 'businessAssociatesAgreement',
    url: 'https://mtomics.com/associates',
    label: 'Business Associates Agreement',
}, {
    name: 'termsOfUse',
    url: 'https://mtomics.com/terms',
    label: 'Terms of Use',
}, {
    label: 'Privacy Policy',
    url: 'https://mtomics.com/privacy',
    name: 'privacyPolicy',
}, {
    label: 'Disclaimer',
    url: 'https://mtomics.com/disclaimer',
    name: 'disclaimer',
}];

export default function Register({ setMode }: { setMode: (mode: 'verify' | 'signin') => void }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);

    const location = useLocation();

    const hasNewSurveyLink = location.pathname.startsWith('/new/survey');
    const hasBusinessAssociatesAgreement = searchParams.get('type') && searchParams.get('type') === 'bba';

    const surveyId = hasNewSurveyLink ? extractUUID(location.pathname) : searchParams.get('surveyId') as UUID;
    const advocateId = searchParams.get('advocateId') as UUID;

    const initialState = {
        first_name: '',
        last_name: '',
        email: searchParams.get('email') || '',
        password: '',
        password_confirmation: '',
        city: '',
        state: '',
        postal: '',
        country: '',
        region: 'US',
    };

    const onSubmit = async (values: typeof initialState) => {
        const { email, password, password_confirmation, first_name, last_name, ...address } = values;

        try {
            setLoading(true);

            await signUp({
                username: email.toLowerCase(),
                password,
                attributes: {
                    name: `${first_name} ${last_name}`,
                },
                clientMetadata: {
                    surveyId: surveyId || undefined,
                    advocateId: advocateId || undefined,
                    firstName: first_name,
                    lastName: last_name,
                    ...address,
                },
            });

            setSearchParams({ ...searchParams, email });

            setMode('verify');
        } catch (error) {
            captureException(error);
            console.error(error);
            toast.error('Error creating account');
        }

        setLoading(false);
    }

    const requiredAgreementNames = hasBusinessAssociatesAgreement
        ? ['businessAssociatesAgreement', 'termsOfUse', 'privacyPolicy', 'disclaimer']
        : ['termsOfUse', 'privacyPolicy', 'disclaimer'];

    const filteredAgreementList = agreementList.filter(({ name }) => requiredAgreementNames.includes(name));

    const [selectedState, setState] = useState<string[]>([]);

    const onChange = (key: string, value: boolean) => setState(
        value
            ? [...selectedState, key]
            : selectedState.filter((item) => item !== key)
    );

    const isSubmitDisabled = isLoading || requiredAgreementNames.some((name) => !selectedState.includes(name));

    const [selectedAgreementName, setSelectedAgreementName] = useState<string | null>(null);
    const selectedAgreement = agreementList.find(({ name }) => name === selectedAgreementName);

    return (
        <>
            <Modal size="large" isOpen={Boolean(selectedAgreementName)} onClose={() => setSelectedAgreementName(null)}>
                <iframe
                    name={globalThis.crypto.randomUUID()}
                    src={selectedAgreement?.url}
                    className="w-full h-[65vh] border border-slate-200 rounded-md p-6 mb-4"
                />
                <div className="flex justify-end">
                    <Button onClick={() => setSelectedAgreementName(null)}>Close</Button>
                </div>
            </Modal>

            {isTerrainTenPromotion(surveyId, advocateId) && (
                <p className="mb-16 p-4 rounded-md bg-slate-50 hyphens-auto">
                    The Terrain Ten survey assesses your health across 10 key areas, including angiogenesis, hormone balance, immune function, toxins, and more.
                    By understanding your health landscape, we can help you make informed decisions for a more vibrant life.
                    Start your journey by creating an account to access your personalized results.
                </p>
            )}

            <Formik
                initialValues={initialState}
                validationSchema={signupSchema}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <Form>
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between gap-4">
                                <InputField
                                    label="First name"
                                    name="first_name"
                                    autoComplete="given-name"
                                    required={true}
                                />

                                <InputField
                                    label="Last name"
                                    name="last_name"
                                    autoComplete="family-name"
                                    required={true}
                                />
                            </div>

                            <InputField
                                label="Email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required={true}
                            />

                            <InputField
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                required={true}
                            />

                            <InputField
                                label="Password confirmation"
                                name="password_confirmation"
                                type="password"
                                autoComplete="new-password"
                                required={true}
                            />

                            <div className="flex flex-col col-span-full mb-10 mt-8 font-normal text-sm text-slate-800">
                                <div className="mb-4">
                                    Click the linked documents to review them and enable the checkboxes:
                                    {/* <p>Your careful consideration of the content is essential.</p> */}
                                </div>

                                {filteredAgreementList.map(({ name, url, label }) => (
                                    <label className="flex items-center gap-2 mb-1" key={url}>
                                        <Checkbox
                                            name={name}
                                            checked={selectedState.includes(name)}
                                            onChange={() => onChange(name, !selectedState.includes(name))}
                                        />

                                        <span>
                                            I agree to the

                                            {' '}

                                            <Link
                                                to={url}
                                                target="_blank"
                                                theme="light"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setSelectedAgreementName(name);
                                                }}
                                            >
                                                {label}
                                            </Link>
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <Button
                            type="submit"
                            size="medium"
                            width="full"
                            disabled={!props.dirty || !props.isValid || isSubmitDisabled}
                        >
                            {isLoading && <Spinner />}
                            Create Account
                        </Button>

                        <div className="text-center my-8 text-slate-800">
                            Already have an account? <Link theme="light" onClick={() => setMode('signin')}>Sign in</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
