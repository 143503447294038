import { API } from 'aws-amplify';
import config from '../../services/config';
import { getAuthorizationHeader } from '../../services/auth';

export enum HttpMethod {
    POST = 'post',
    PUT = 'put',
    GET = 'get',
    DELETE = 'del',
}

interface Parameters<TBody> {
    body?: TBody;
    method?: HttpMethod;
}

export const sendRequest = async <
    TResult = any,
    TBody = null,
>(
    path: string,
    parameters?: Parameters<TBody>
): Promise<TResult> => {
    const api = String(config.API.endpoints[0]?.name);

    const headers = await getAuthorizationHeader();
    const method = parameters?.method ?? 'get';
    const body = parameters?.body ?? null;

    return API[method](api, path, { headers, body });
};
