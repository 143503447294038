import { useSortBy } from '../../../../hooks/useSortBy';
import { SortArrows } from '../../../../components/SortArrows';
import { Head, Th, Body, Td, Table } from '../../../../components/Table';
import { SurveyResultAnswer } from '../SurveyResultAnswer';
import { SurveyResultFiles } from '../SurveyResultFiles';

interface Props {
    score?: number;
    items: {
        text: string;
        hint: string[] | string[][];
        files: { name: string, content: string, type: string }[];
        score: undefined | null | number;
    }[];
    showAll?: boolean;
}

export const SurveyResultSection = (props: Props) => {
    const sorting = useSortBy(props.items, { defaultSortKey: 'index' });

    const sortableHeaderItems: { title: string; sortKey: string; align: 'center' | 'left' | 'right' }[] = [
        { title: 'Question', sortKey: 'text', align: 'left' },
        { title: 'Answer', sortKey: 'hint', align: 'left' },
    ];

    if (typeof props.score === 'number') {
        sortableHeaderItems.push({ title: 'Score', sortKey: 'score', align: 'right' });
    }

    const filteredItems = props.showAll ? sorting.items : sorting.items.filter((item: any) => item.score === undefined || item.score === null || item.score > 0);

    return (
        <div className="px-4 pb-4">
            <Table>
                <Head>
                    {sortableHeaderItems.map((headerItem) => (
                        <Th onClick={() => sorting.setSorting(headerItem.sortKey)} align={headerItem.align} key={headerItem.sortKey}>
                            {headerItem.title}
                            <SortArrows direction={sorting.sortKey === headerItem.sortKey ? (sorting.isReverse ? 'desc' : 'asc') : 'none'} />
                        </Th>
                    ))}
                </Head>

                <Body>
                    {filteredItems.map((item, index) => (
                        <tr key={index} className="even:bg-basic/10">
                            <Td>{item.text}</Td>
                            <Td>
                                {item.hint && <SurveyResultAnswer value={item.hint} />}
                                {item.files && <SurveyResultFiles value={item.files} />}
                            </Td>
                            {typeof props.score === 'number' && <Td align="right">{item.score! >= 0 ? item.score : ''}</Td>}
                        </tr>
                    ))}
                </Body>
            </Table>
        </div>
    );
};
