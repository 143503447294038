import * as Yup from 'yup';

const password = Yup.string()
    .required('Required')
    .min(6, 'Too short. 6 or more characters required');

const passwordConfirmation = Yup
    .string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords should match');

export const loginSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: password,
});

export const signupSchema = Yup.object().shape({
    first_name: Yup.string().min(1).required('Too short'),
    last_name: Yup.string().min(1).required('Too short'),
    email: Yup.string().email().required('Required'),
    password: password,
    password_confirmation: passwordConfirmation,
});

export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
});

export const verifyEmailSchema = Yup.object().shape({
    code: Yup.string().min(4).required('Required'),
});

export const resetPasswordSchema = Yup.object().shape({
    code: Yup.string().min(4).required('Required'),
    password: password,
    password_confirmation: passwordConfirmation,
});
