import { useQuery } from '@tanstack/react-query';
import { transformInvitation } from '../../helpers';
import { Invitation } from '../../types';
import { sendRequest } from '../../services/api';
import { UUID } from 'crypto';

const transformInvitations = (
    invitationList: {
        invitation: any;
        survey: any;
    }[],
): Invitation[] => invitationList.map(transformInvitation) as Invitation[];

interface QueryResponseItem { 
    id: UUID; 
    firstName: string; 
    lastName: string; 
    email: string; 
    type: 'invitation';
    used?: number;
    createdOn: number;
    status: 'sent';
    advocateId: UUID;
    surveys: Array<{ id: UUID; name: string; status?: string; updated?: number; airtable?: string }>;
}

// bringing here the idea of holding unique key for every query in particular
// so the queryKey, query hook and related interfaces are available within the query
export const queryKey = 'advocate/invitations';

export const useInvitationList = () => {
    return useQuery<{ invitations: Invitation[] }, Error, QueryResponseItem[]>({
        queryKey: [queryKey],
        queryFn: () => sendRequest(queryKey),
        // mapping the response to the internal entities used across the UI for consistant data flow
        select: (data) => data.invitations.map((invitation) => ({
            id: invitation.id,
            firstName: invitation.firstName,
            lastName: invitation.lastName,
            email: invitation.email,
            type: 'invitation',
            used: invitation.used,
            surveys: invitation.systemSurveys ?? [],
            createdOn: invitation.date,
            advocateId: invitation.advocateId!,
            status: 'sent',
        })),
    });
};

// @todo: move to the managerInvitationList or whatever, to keep only one query here
export const useAdminAdvocateInvitationList = (advocateId: string) => {
    const path = `manager/advocate/invitations/${advocateId}`;

    return useQuery<any, Error, Invitation[]>({
        queryKey: [path],
        queryFn: async () => sendRequest(path),
        select: transformInvitations,
    });
};
