import { ComponentProps } from 'react';
import { useCreateInvitationReminder } from '@/queries/useCreateInvitationReminder';
import { useCreateSurveyReminder } from '@/queries/useCreateSurveyReminder';
import { InvitationReminderForm } from '../InvitationReminderForm';
import { Modal } from '@/components/Modal';
import { Invitation } from '@/types';
import { getHtmlBody, getInvitationReminderMessage, getInvitationSignupReminderMessage } from '../../../helpers/email';
import { toast } from 'react-toastify';
import { getInvitationDisplayName } from '@/helpers';

type Props = { invitation?: Invitation; isAdmin?: boolean } & Omit<ComponentProps<typeof Modal>, 'children'>;

const createInvitationLink = (invitation: Invitation) => {
    const hasSentStatus = invitation.status && invitation.status === 'sent';

    const advocateId = invitation.advocateId || invitation.advocate?.userId;
    const email = invitation.email;

    const url = new URL(`${location.protocol}//${location.host}/`);

    url.pathname = hasSentStatus
        ? `/new/survey/${invitation.surveyId}`
        : `/survey/${invitation.id}`;

    if (hasSentStatus && advocateId) {
        url.searchParams.append('advocateId', advocateId);
    }

    if (hasSentStatus) {
        url.searchParams.append('mode', 'signup');
    }

    if (email) {
        url.searchParams.append('email', email);
    }

    return url.toString();
}

export const InvitationReminderModal = ({ invitation, isAdmin = false, isOpen, onClose }: Props) => {
    const { mutateAsync: createInvitationReminder } = useCreateInvitationReminder();
    const { mutateAsync: createSurveyReminder } = useCreateSurveyReminder({ isAdmin });

    if (!invitation) {
        return null;
    }

    const hasSentStatus = invitation?.status === 'sent';
    const link = createInvitationLink(invitation);

    const subject = `${invitation.surveyName || getInvitationDisplayName(invitation.systemSurveys || [])} Reminder`;

    const defaultMessage =
        hasSentStatus
            ? getInvitationSignupReminderMessage(invitation, link)
            : getInvitationReminderMessage(invitation, link);

    const onInvitationCreate = ({ subject, message }: { subject: string; message: string }) => {
        const func = hasSentStatus ? createInvitationReminder : createSurveyReminder;

        toast.promise(
            func({
                subject,
                id: invitation.userSurveyId || invitation.id,
                message: getHtmlBody(message),
            }),
            {
                pending: 'Creating reminder...',
                success: 'Invitation reminder has been sent.',
                error: 'Failed to send invitation reminder.',
            },
        );

        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <InvitationReminderForm subject={subject} message={defaultMessage} onSubmit={onInvitationCreate} />
        </Modal>
    );
};
