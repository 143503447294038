import { ReactNode } from 'react';

interface Props {
    children: ReactNode,
    align?: 'center' | 'left' | 'right',
    colspan?: number,
}

const Align = {
    'center': 'text-center',
    'left': 'text-left',
    'right': 'text-right',
};


export const Cell = ({ children, colspan, align = 'left' }: Props) => (
    <td className={`px-4 py-5 text-sm font-normal text-gray-900 ${Align[align]}`} colSpan={colspan}>
        {children}
    </td>
);
