import { UUID } from 'crypto';
import { Head, Table, Body, Td, Th } from '../../components/Table';
import { useSortBy } from '../../hooks/useSortBy';
import { useGroupBy } from '../../hooks/useGroupBy';
import { useGroupVisibilityToggle } from '../../hooks/useGroupVisiblityToggle';
import { TableGroupHeader } from '../../components/TableGroupHeader';
import { formatDate, formatName, getSortDirection, sortByInvitiationSender } from '../../helpers';
import { AdvocateInvitation, User, hasAdvocate, hasClinician } from '../../types';
import { SortArrows } from '../../components/SortArrows';
import { ButtonWithConfirmation } from '../../components/ButtonWithConfirmation';
import { Trash } from '../../components/Icons/Trash';
import { Edit } from '../../components/Icons/Edit';
import { Button } from '../../components/Button/index';
import { Envelope } from '../../components/Icons/Envelope';

interface Props {
    users: User[];
    invitations: AdvocateInvitation[];
    onClick?: (user: User) => void;
    onDelete?: (id: UUID, type: 'user' | 'invitation') => void;
    onClickInvitation?: (invitation: AdvocateInvitation) => void;
}

const sortFnMap = {
    advocateFullName: sortByInvitiationSender('advocate'),
    clinicianFullName: sortByInvitiationSender('clinician'),
};

const getUserAdvocate = (user: any) => user.userAccess.find((user: any) => user.role === 'advocate');

const getUserClinician = (user: any) => user.userAccess.find((user: any) => user.role === 'clinician');

export const UserList = ({ users, invitations, onClick, onDelete, onClickInvitation }: Props) => {
    // @todo: revisit, fails due to matching survey status against the invitation status
    const sorting = useSortBy(users, { sortFunctionsMap: sortFnMap as any, defaultSortKey: 'fullName' });

    const { items, groups } = useGroupBy(sorting.items, (item) => item.role);
    const [hiddenGroups, toggleGroupVisibility] = useGroupVisibilityToggle(groups.concat('invitations'));

    const tableHeader = [
        { title: 'Name', sortKey: 'fullName' },
        { title: 'Email', sortKey: 'email' },
        { title: 'Clinician', sortKey: 'clinicianFullName' },
        { title: 'Advocate', sortKey: 'advocateFullName' },
        { title: 'Role', sortKey: 'role' },
        { title: 'Last Activity', sortKey: 'lastLogin' },
        { title: 'Actions', align: 'center' },
    ];

    return (
        <Table>
            <Head>
                {tableHeader.map((item) => (
                    <Th onClick={() => item.sortKey && sorting.setSorting(item.sortKey)} key={item.title}>
                        {item.title}
                        {item.sortKey && <SortArrows direction={getSortDirection(item.sortKey, sorting.sortKey, sorting.isReverse)} />}
                    </Th>
                ))}
            </Head>

            {items.map(([group, groupItems]) => (
                <Body key={group}>
                    <TableGroupHeader colspan={tableHeader.length} onClick={() => toggleGroupVisibility(group)} isCollapsed={hiddenGroups.includes(group)}>
                        {group} ({(groupItems as any[]).length})
                    </TableGroupHeader>

                    {!hiddenGroups.includes(group) &&
                        (groupItems as User[]).map((user) => (
                            <tr key={user.userId} className="odd:bg-slate-100/50">
                                <Td>{formatName(user)}</Td>
                                <Td>{user.email}</Td>
                                <Td>{hasClinician(user) ? formatName(getUserClinician(user)) : '-'}</Td>
                                <Td>{hasAdvocate(user) ? formatName(getUserAdvocate(user)) : '-'}</Td>
                                <Td>{user.role}</Td>
                                <Td>{user.lastLogin ? formatDate(user.lastLogin) : '-'}</Td>
                                <Td align="center">
                                    <div className="flex gap-1">
                                        {onClick && <Button size="small" onClick={() => onClick(user)}>
                                            <Edit />
                                        </Button>}
                                        {onDelete &&
                                            <ButtonWithConfirmation
                                                onConfirm={() => onDelete(user.userId, 'user')}
                                                message={`Delete ${formatName(user, { separator: ' ', order: 'asc' })}?`}
                                            >
                                                <Trash className="text-red-600 hover:text-red-900" />
                                            </ButtonWithConfirmation>}
                                    </div>
                                </Td>
                            </tr>
                        ))}
                </Body>
            ))}
            {invitations && invitations.length > 0 && (
                <Body key="invitations">
                    <TableGroupHeader
                        colspan={tableHeader.length}
                        onClick={() => toggleGroupVisibility('invitations')}
                        isCollapsed={hiddenGroups.includes('invitations')}
                    >
                        Pending ({invitations.length})
                    </TableGroupHeader>

                    {!hiddenGroups.includes('invitations') &&
                        invitations.map((invitation) => (
                            <tr key={invitation.id} className="odd:bg-slate-100/50">
                                <Td>{formatName(invitation)}</Td>
                                <Td>{invitation.email}</Td>
                                <Td> - </Td>
                                <Td> - </Td>
                                <Td>{invitation.role}</Td>
                                <Td>{invitation.date ? formatDate(invitation.date) : '-'}</Td>
                                <Td align="center">
                                    <div className="flex gap-1">
                                        {onClickInvitation && <Button size="small" onClick={() => onClickInvitation(invitation)} title="Send reminder">
                                            <Envelope />
                                        </Button>}
                                        {onDelete &&
                                            <ButtonWithConfirmation onConfirm={() => onDelete(invitation.id, 'invitation')}>
                                                <Trash className="text-red-600 hover:text-red-900" />
                                            </ButtonWithConfirmation>}
                                    </div>
                                </Td>
                            </tr>
                        ))}
                </Body>
            )}
        </Table>
    );
};
