import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getAdminSurveyPath } from '../useSurvey';
import { sendRequest } from '../../services/api';

export const useUpdateSurvey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data, comment }: { id: string; data: unknown; comment: string }) => {
      const response = await sendRequest<{
        signedUrl: string;
      }>(`admin/survey/upload/${id}?comment=${encodeURIComponent(comment)}`);

      await fetch(`${response.signedUrl}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      });

      return Promise.resolve({ id, data });
    },
    onSuccess: async ({ id, data }: { id: string; data: any }) => {
      await queryClient.invalidateQueries();
      queryClient.setQueryData([getAdminSurveyPath(id, false)], () => ({ survey: data }));
    },
    onError: (error) => {
      console.log(error);
    },
    retry: 3,
  });
};
