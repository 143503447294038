import { UUID } from 'crypto';
import { useMutation } from '@tanstack/react-query';
import { sendRequest, HttpMethod } from '@/services/api';

interface QueryPayload {
    id: UUID;
    email: string;
    subject: string;
    message: string;
    status: 'sent';
    firstName: string;
    lastName: string;
    fullName: string;
    surveyName?: string;
    date: number;
}

const queryKey = 'advocate/invitation';

export const useCreateInvitation = () => useMutation<{}, Error, QueryPayload>({
    mutationKey: [queryKey],
    mutationFn: (body) => sendRequest(queryKey, { body, method: HttpMethod.POST }),
});
