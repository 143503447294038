import { useParams } from 'react-router-dom';
import { SurveyResult } from '../widgets/SurveyResult';
import { useAdvocateSurvey, useUserSurvey } from '../queries/useSurvey';
import { Title } from '../components/Title';
import { TableCellsIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { formatName } from '../helpers';
import { isExcelReportEnabled, transformSections } from '../helpers/report';
import { Button } from '../components/Button/index';
import { useReportDownload } from '../hooks/useReportDownload';
import { useAuth } from '../hooks/useAuth';
import { UserRole } from '../types';

const AdvocateSurveyDetails = () => {
  const { slug } = useParams();
  const { isPatient } = useAuth();

  const {
    isLoading,
    isError,
    data,
    error,
  } = isPatient ? useUserSurvey(slug) : useAdvocateSurvey(slug);

  const downloadReport = useReportDownload();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error while loading: {error.message}</p>;
  }

  const sections = transformSections(data.survey, data.surveyData);
  const userName = formatName(data.survey.user || data.survey); // @todo: align  data across endpoints, for user's request we do not have data.survey.user field
  const userEmail = data.survey.user?.email;

  return (
    <div>
      <Title>
        {data.survey.name}

        <div className="flex gap-4">
          {!isPatient && isExcelReportEnabled(data.survey.surveyId) && (
            <Button onClick={() => downloadReport('excel', data.survey.id)}>
              <TableCellsIcon className="w-5 h-5 mr-2" />
              Generate Spreadsheet
            </Button>
          )}

          <Button onClick={() => downloadReport('pdf', data.survey.id)}>
            <DocumentIcon className="w-5 h-5 mr-2" />
            Generate PDF
          </Button>
        </div>
      </Title>

      <div className='md:flex md:items-center md:justify-between'>
        <div className='min-w-0 flex-1'>
          {!isPatient && (
            <h4 className='text-xl leading-7 text-slate-800 sm:truncate sm:text-xl sm:tracking-tight'>
              {userName} ({userEmail})
            </h4>
          )}
        </div>
      </div>
      <SurveyResult sections={sections} />
    </div>
  );
};

export default AdvocateSurveyDetails;
