const getSurveyStatusColor = (status: string) => {
    switch (status) {
        case 'sent':
            return 'bg-red-100 text-red-800';
        case 'registered':
            return 'bg-green-100 text-green-800';
        case 'assigned':
            return 'bg-cyan-100 text-cyan-800';
        case 'started':
            return 'bg-orange-100 text-orange-800';
        case 'finished':
            return 'bg-green-100 text-green-800';
    }
};

const getSurveyEditorialStatusColor = (status: string) => {
    switch (status) {
        case 'assigned':
            return 'bg-red-100 text-red-800';
        case 'started':
        case 'archived':
            return 'bg-orange-100 text-orange-800';
        case 'published':
            return 'bg-green-100 text-green-800';
    }
};


interface DefaultStatusProps {
    status: string;//'sent' | 'registered' | 'started' | 'finished' | 'assigned',
    type?: 'default',
}

interface EditorialStatusProps {
    status: string;//'assigned' | 'started' | 'published' | 'archived',
    type: 'editorial',
}

type Props = DefaultStatusProps | EditorialStatusProps;


export const SurveyStatus = ({ status, type = 'default' }: Props) => {
    const getColor = type === 'editorial' ? getSurveyEditorialStatusColor : getSurveyStatusColor;

    return (
        <p className={`inline-flex text-xs font-semibold px-2.5 py-1 rounded-md capitalize ${getColor(status)}`}>
            {status}
        </p>
    );
};
