import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import { formatDate, formatName } from '../../helpers';
import { transformSections, getFileName, getPdfData, getExcelData, download } from '../../helpers/report';
import { useBuildExcel, useBuildPdf } from '../../queries/useBuildXLSX';
import { useSurveyMutation } from '../../queries/useSurvey';

export const useReportDownload = () => {
    const { mutateAsync: createExcelReport } = useBuildExcel();
    const { mutateAsync: createPdfReport } = useBuildPdf();
    const { mutateAsync: fetchSurveyById } = useSurveyMutation();

    return useCallback(async (type: 'excel' | 'pdf', surveyId: string) => {
        const toastMessage = `Preparing ${type} report`;
        const toastId = toast.loading(toastMessage);

        try {
            const { survey, surveyData } = await fetchSurveyById({ surveyId });

            const transformedData = transformSections(survey, surveyData);
            const fileName = getFileName(survey, type === 'excel' ? 'xlsx' : 'pdf');

            let result;

            const params = {
                surveyId,
                fileName,
                title: survey.name,
                user: {
                    name: formatName(survey.user),
                    email: survey.user.email,
                },
            };

            if (type === 'pdf') {
                result = await createPdfReport({
                    ...params,
                    hasScored: surveyData.scored || surveyData.scored === undefined,
                    areas: getPdfData(transformedData),
                    date: formatDate(survey.updated),
                });
            }

            if (type === 'excel') {
                result = await createExcelReport({
                    ...params,
                    ...getExcelData(transformedData),
                });
            }

            download(result.url, fileName);

            toast.update(toastId, {
                render: toastMessage,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            });
        } catch (error) {
            captureException(error);

            console.log('userReportDownload error = ', error);

            toast.update(toastId, {
                render: `Error creating ${type} report`,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            });
        }
    }, []);
};
