import { UUID } from 'crypto';
import { useMutation } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const useDeleteInvitation = () =>
    useMutation({
        mutationFn: async (options: { id: UUID, type?: string }) => {
            const path = options.type === 'advocate'
                ? 'admin/invitation/advocate'
                : 'admin/invitation';

            return sendRequest(`${path}/${options.id}`, { method: HttpMethod.DELETE });
        },
    });
