import { UUID } from 'crypto';
import { useState } from 'react';

import { useAdminInvitesList } from '@/queries/useAdminInvitesList';
import { InvitationList } from '@/widgets/InvitationList';
import { useSortGroupsBy } from '@/hooks/useSortBy';
import { findInvitationById, transformInvitationGroupList, sortByStatus, compareByFullName } from '@/helpers/index';

import { GroupTitle } from '../../elements/GroupTitle';
import { Invitation, User, AllowedSurveyActions } from '../../../../types';
import { RadioGroup } from '@/components/RadioGroup';

const sortFnMap = {
    fullName: compareByFullName,
    status: sortByStatus,
};

type InvitationListProps = {
    allowedActions: AllowedSurveyActions[];
    onUserReminderClick: (invitation: Invitation) => void;
    onDeleteInvitation?: (invitation: Invitation, refetch: () => void) => void;
    onUserSearchClick?: (id: UUID) => void;
};

const InvitationListElement = ({ allowedActions, onDeleteInvitation, onUserReminderClick, onUserSearchClick }: InvitationListProps) => {
    const { isLoading, isError, error, data: invitations, refetch: refetchInvitations } = useAdminInvitesList();
    const [filter, setFilter] = useState<'advocate' | 'clinician' | undefined>('advocate');
    const filteredItems = useFilteredItems(invitations, filter);
    const all = transformInvitationGroupList(filteredItems).map(([_, group]) => {
        return group as Invitation[];
    });
    const { items: sortedItems, sortKey, isReverse, setSorting } = useSortGroupsBy(all, sortFnMap);
    const groups = useGroupedItems(filteredItems, sortedItems);

    const getInvitation = (invitationId: string) => {
        const invitation = findInvitationById(groups.map((el) => el[1]).flat(1), invitationId);
        const advocate = filteredItems.find((advocate: User) => advocate.userId === invitation?.advocateId);
        return Object.assign({ advocate }, invitation);
    };

    if (isLoading) {
        return <p>Loading ...</p>;
    }

    if (isError) {
        return <p>Error while loading: {(error as Error).message}</p>;
    }

    return (
        <>
            <RadioGroup onClick={setFilter} selected={filter} options={{ 'By advocate': 'advocate', 'By clinician': 'clinician', 'Unassigned': undefined }} />

            <InvitationList
                type="groups"
                allowedActions={allowedActions}
                items={groups}
                sort={{
                    sortKey,
                    isReverse,
                    setSorting,
                }}
                onClick={(id: UUID) => {
                    const invitation = getInvitation(id);
                    if (invitation) {
                        onUserReminderClick(invitation);
                    }
                }}
                onDelete={onDeleteInvitation ? ((id: UUID) => {
                    const invitation = groups
                        .reduce<Invitation[]>((acc: Invitation[], group: [React.ReactNode, Invitation[]]) => [...acc, ...group[1]], [])
                        .find((invitation: Invitation) => invitation.invitationId === id || invitation.userSurveyId === id);
                    if (invitation) {
                        onDeleteInvitation(invitation, refetchInvitations);
                    }
                })
                    : undefined
                }
                onUserSearchClick={onUserSearchClick}
            />
        </>
    );
};

// @todo: revisit
export const useFilteredItems = (invitations: any, filter: string | undefined) => {
    if (filter) {
        return invitations?.advocates?.filter((item: User) => item.role === filter) || [];
    }

    return [{ advocateSurveys: invitations?.unassignedSurveys ?? [] }];
};

export const useGroupedItems = (filteredItems: any[], sortedItems: any[]) => {
    return transformInvitationGroupList(filteredItems)
        .map(([advocate], index) => [advocate, sortedItems[index]] as [User, Invitation[]])
        .sort((a, b) => compareByFullName(a[0], b[0]))
        .map(([advocate, group]) => [<GroupTitle advocate={advocate} group={group} />, group]) as [React.ReactNode, Invitation[]][];
};

export default InvitationListElement;
