import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { InvitationReminderModal } from '@/widgets/SendReminder/InvitationReminderModal';
import { Title } from '@/components/Title';
import { UserSearchModal } from '@/widgets/UserSearch/UserSearchModal';
import { Button } from '@/components/Button/index';

import { useAssignSurvey } from '@/queries/useAssignSurvey';
import { useDeleteInvitation } from '@/queries/useDeleteInvitation';
import { useDeleteSurvey } from '@/queries/useDeleteSurvey';

import InvitationListElement from './elements/InvitationList';
import { Invitation, AllowedSurveyActionsAll, AllowedSurveyActions } from '../../types';
import { useAuth } from '@/hooks/useAuth';

const AdminInvitations = () => {
    const { isAdmin } = useAuth();
    const navigate = useNavigate();
    const [surveyId, setSurveyId] = useState('');
    const [invitation, setInvitation] = useState<Invitation | undefined>(undefined);
    const [searchModal, setSearchModal] = useState(false);

    const { mutateAsync: assignSurvey } = useAssignSurvey();
    const { mutateAsync: deleteSurvey } = useDeleteSurvey();
    const { mutateAsync: deleteInvitation } = useDeleteInvitation();

    const onDeleteInvitation = (invitation: Invitation, refetchInvitations: () => void) => {
        const invitationSurveyId = invitation?.userSurveyId;
        const invitationId = invitation?.invitationId;

        const handler = async () => {
            if (invitationSurveyId) {
                await deleteSurvey(invitationSurveyId);
            }

            if (invitationId && invitation.role === 'advocate') {
                await deleteInvitation({ id: invitationId, type: 'advocate' });
            }

            if (invitationId && invitation.role === 'clinician') {
                await deleteInvitation({ id: invitationId, type: 'advocate' });
            }

            if (invitationId && !invitation.role) {
                await deleteInvitation({ id: invitationId });
            }

            refetchInvitations();
        };

        toast.promise(handler, {
            pending: 'Deleting invitation...',
            success: 'Invitation was deleted.',
            error: 'Failed to delete the invitation.',
        });
    };

    const handleAssignSurvey = (assignSurvey: Function, surveyId: string, setSurveyId: Function) => async (userId: string) => {
        await toast.promise(assignSurvey({ advocateId: userId, surveyId }), {
            pending: 'Assigning the survey...',
            success: 'Survey has been assigned.',
            error: 'Failed to assign the survey.',
        });
        setSurveyId('');
    };

    const handleViewUser = (navigate: Function, setSearchModal: Function) => (userId: string) => {
        setSearchModal(false);
        navigate(`/admin/advocate/invitations/${userId}`);
    };

    const allowedActions = () => isAdmin ? AllowedSurveyActionsAll : [AllowedSurveyActions.RE_ASSIGN];

    return (
        <>
            <InvitationReminderModal
                isAdmin={true}
                isOpen={Boolean(invitation)}
                onClose={() => setInvitation(undefined)}
                invitation={invitation}
            />

            <UserSearchModal
                label="Assign"
                isOpen={Boolean(surveyId)}
                onClick={handleAssignSurvey(assignSurvey, surveyId, setSurveyId)}
                onClose={() => setSurveyId('')}
            />

            <UserSearchModal
                label="View"
                isOpen={searchModal}
                onClick={handleViewUser(navigate, setSearchModal)}
                onClose={() => {
                    setSurveyId('');
                    setSearchModal(false);
                }}
            />

            <Title>
                All Survey Invitations
                <Button onClick={() => setSearchModal(true)}>Search</Button>
            </Title>

            <InvitationListElement
                allowedActions={allowedActions()}
                onUserReminderClick={(invitation: Invitation) => {
                    setInvitation(invitation);
                }}
                onUserSearchClick={setSurveyId}
                onDeleteInvitation={onDeleteInvitation}
            />
        </>
    );
};

export default AdminInvitations;
