import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { captureException } from '@sentry/react';
import { Link } from '@/components/Link';
import { Button } from '@/components/Button';
import { InputField } from '@/components/Input';
import { Spinner } from '@/components/Spinner';
import { forgotPassword, forgotPasswordSubmit } from '@/services/auth';
import { forgotPasswordSchema, resetPasswordSchema } from '../helpers/validation';

const EmailForm = ({ onSubmit }: { onSubmit: (email: string) => void }) => (
    <Formik
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values) => onSubmit(values.email.toLowerCase())}
    >
        {(props) => (
            <Form>
                <InputField
                    label="Email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required={true}
                    placeholder="name@wellness.com"
                />

                <div className="mt-4">
                    <Button type="submit" width="full" size="medium" disabled={!props.dirty || !props.isValid}>
                        Get Security Code
                    </Button>
                </div>
            </Form>
        )}
    </Formik>
);

const PasswordForm = (props: { onSubmit: (data: { code: string; password: string }) => Promise<void> }) => {
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async ({ code, password }: { code: string; password: string }) => {
        setLoading(true);

        try {
            await props.onSubmit({ code, password });
        } catch (error) {
            captureException(error);
            console.error('Password submit failed', error);
            toast.error('Failed to submit new password');
        }

        setLoading(false);
    }

    return (
        <Formik
            initialValues={{
                code: '',
                password: '',
                password_confirmation: '',
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={onSubmit}
        >
            {(props) => (
                <Form>
                    <div className='space-y-6'>
                        <InputField
                            label="Code"
                            name="code"
                            required={true}
                        />

                        <InputField
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            required={true}
                        />

                        <InputField
                            label="Password confirmation"
                            name="password_confirmation"
                            type="password"
                            autoComplete="new-password"
                            required={true}
                        />

                        <Button type="submit" width="full" size="medium" disabled={!props.dirty || !props.isValid || isLoading}>
                            {isLoading && <Spinner />}
                            Set Password
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default function ResetPassword({ setMode }: { setMode: (mode: 'signin') => void }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') ?? '');

    const onSubmitEmail = async (email: string) => {
        setEmail(email);
        try {
            await forgotPassword(email);
        } catch (error) {
            captureException(error);
            console.error('error initiating password reset', error);
            toast.error('Error initiating password reset');
        }
    };

    const onSubmitPassword = async (data: { code: string; password: string }) => {
        if (!email) {
            toast.error('Error submitting password');
            return;
        }

        try {
            await forgotPasswordSubmit(email, data.code, data.password);
            setSearchParams({ ...searchParams, email });
            setMode('signin');
        } catch (error) {
            captureException(error);
            console.error('reset password submission failed', error);
            toast.error('Password submission error');
        }
    };

    return (
        <>
            {(
                email
                    ? <PasswordForm onSubmit={onSubmitPassword} />
                    : <EmailForm onSubmit={onSubmitEmail} />
            )}

            <div className="text-center mt-4 mb-8 text-slate-800">
                Already have an account? <Link theme="light" onClick={() => setMode('signin')}>Sign in</Link>
            </div>
        </>
    );
};
