import { useState, useEffect, createContext, useContext, ComponentType } from 'react';
import { Hub } from 'aws-amplify';
import { UUID } from 'crypto';
import { Auth } from '@/pages/Auth';
import { getCurrentUser } from '@/services/auth';
import { UserRole } from '@/types';

interface User {
    id: UUID;
    name: string;
    email: string;
    roles: UserRole[];
}

export const AuthContext = createContext<{ user: User } | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('Auth Context used before authorization');
    }

    const { roles = [], ...user } = context.user;

    return {
        user,
        isAdmin: roles.includes(UserRole.admin),
        isAdvocate: roles.includes(UserRole.advocate),
        isClinician: roles.includes(UserRole.clinician),
        isManager: roles.includes(UserRole.manager),
        isPatient: roles.includes(UserRole.user) && roles.length === 1,
    };
};

export const withAuth = <P extends {}>(Wrapped: ComponentType<P>) => (props: P) => {
    const [user, setUser] = useState<User>();
    const [isLoading, setLoading] = useState(true);

    const onSignIn = () => getCurrentUser().then(setUser);

    const onSignOut = () => setUser(undefined);

    useEffect(() => {
        getCurrentUser()
            .then(setUser)
            .catch(() => { })
            .finally(() => setLoading(false));

        return Hub.listen('auth', (({ payload }) => {
            if (payload.event === 'signOut') {
                onSignOut();
            }

            if (payload.event === 'signIn') {
                onSignIn();
            }
        }));
    }, []);

    const session = user ? { user } : undefined;

    if (isLoading) {
        return null;
    }

    return (
        <AuthContext.Provider value={session}>
            {session ? <Wrapped {...props} /> : <Auth />}
        </AuthContext.Provider>
    );
};
