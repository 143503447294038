import { useQuery } from '@tanstack/react-query';
import { sendRequest } from '../../services/api';

export const useUserByEmail = (email: string, path = 'advocate/user/by-email') => {
    return useQuery({
        queryKey: ['email', email],
        queryFn: async ({ queryKey }) => {
            const [_, value] = queryKey;
            const escapedEmail = encodeURIComponent(value ?? '').toLowerCase();

            return sendRequest(path + `?email=${escapedEmail}`);
        },
        enabled: Boolean(email),
    });
};
