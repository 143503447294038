interface Props {
    name: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}

const CheckboxState = {
    disabled: 'text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary focus:ring-2 cursor-default',
    enabled: 'border-gray-300 text-secondary cursor-pointer focus:ring-secondary',
};

export const Checkbox = ({ name, checked, disabled, onChange }: Props) => (
    <input
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => onChange && onChange(!checked)}
        className={`h-4 w-4 rounded ${CheckboxState[disabled ? 'disabled' : 'enabled']}`}
    />
);
