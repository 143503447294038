import { useMutation } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const useCreateInvitationReminder = () => {
    return useMutation({
        mutationFn: async ({ id, ...body }: { [k: string]: unknown }) => {
            return sendRequest(`advocate/invitation/${id}`, { body, method: HttpMethod.PUT });
        },
    });
};
