import { Auth, Cache } from 'aws-amplify';
import { addMinutes } from 'date-fns';
import { UUID } from 'crypto';
import { client } from '@/queries/client';
import { UserRole } from '@/types';

const UserRoleMap: { [k in UserRole]: string } = {
    user: 'met-app-users',
    advocate: 'met-app-advocate',
    manager: 'met-app-manager',
    admin: 'met-app-admins',
    clinician: 'met-app-clinicians',
    patient: 'patient',
};

export const getAuthorizationHeader = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    return {
        Authorization: token,
    };
};

export const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();

    const token = session.getIdToken();

    const cognitoRoles: string[] = token.payload['cognito:groups'];
    const availableRoles = Object.entries(UserRoleMap) as [UserRole, string][];

    const userRoles = availableRoles
        .filter(([, value]) => cognitoRoles.includes(value))
        .map(([key]) => key);

    const attributes: { sub: UUID; name: string; email: string; } = user.attributes;

    return {
        id: attributes.sub,
        name: attributes.name,
        email: attributes.email,
        roles: userRoles,
    };
};

export const signIn = (email: string, password: string) => {
    return Auth.signIn(email, password);
};

export const signUp = (params: any) => {
    return Auth.signUp(params);
};

export const signOut = async () => {
    await Auth
        .forgetDevice()
        .catch((error) => console.log('forget device error', error));

    await Auth
        .signOut({ global: true })
        .catch((error) => console.log('sign out error', error));

    client.clear();
};

export const confirmSignUp = (email: string, code: string) => {
    return Auth.confirmSignUp(email, code, {});
};

export const resendSignUp = (email: string) => {
    return Auth.resendSignUp(email);
};

export const forgotPassword = async (email: string) => {
    // @todo: bring it back if necessary
    //
    // const cachedCodeDeliveryDetails = Cache.getItem('CodeDeliveryDetails');
    //
    // if (cachedCodeDeliveryDetails) {
    //     return cachedCodeDeliveryDetails;
    // }

    const { CodeDeliveryDetails } = await Auth.forgotPassword(email);

    Cache.setItem('CodeDeliveryDetails', { ...CodeDeliveryDetails, email }, {
        expires: addMinutes(Date.now(), 5).getTime(),
    });

    return CodeDeliveryDetails;
};

export const forgotPasswordSubmit = async (email: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, code, password);

    Cache.removeItem('CodeDeliveryDetails');
};
