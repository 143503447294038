import { formatName } from '../../../../helpers';
import { Invitation, User } from '../../../../types';

const getClientsCountString = (group: Invitation[]) => group.length + ' ' + (group.length === 1 ? 'survey' : 'surveys');

export const GroupTitle = ({ advocate, group }: { advocate: User | undefined; group: Invitation[] }) => {
    if (!advocate) {
        return <span>Unassigned ({getClientsCountString(group)})</span>;
    }

    const { email } = advocate;
    const name = formatName(advocate);

    return (
        <div className="flex gap-2 lowercase">
            <span className="capitalize empty:hidden">{name}</span>
            {name && <span> · </span>}
            {email}
            {email && <span> · </span>}
            {getClientsCountString(group)}
        </div>
    );
};
