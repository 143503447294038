import { Link } from '@/components/Link';

type Props = {
    tabs: string[];
    selectedTab?: string;
    onChange: (selectedTab: string) => void;
};

export const Tabs = ({ tabs, selectedTab, onChange }: Props) => {
    return (
        <nav className="w-full flex rounded-lg shadow overflow-hidden text-sm mb-3" aria-label="Tabs">
            {tabs.map((tab, index) => (
                <Link
                    key={index}
                    theme="dark"
                    onClick={() => onChange(tab)}
                    className={`
                        ${selectedTab === tab ? 'border-b-basic' : 'border-b-slate-100 hover:bg-basic/10'}
                        relative bg-white h-12 flex items-center justify-center grow text-slate-800 border-b-2 border-r border-r-slate-100
                    `}
                >
                    {tab}
                </Link>
            ))}
        </nav>
    );
};
