import { SurveyCreator } from '../widgets/SurveyCreator';
import { useParams } from 'react-router-dom';

const NewSurveyEditor = () => {
  const { slug } = useParams();

  return <SurveyCreator id={slug} />;
};

export default NewSurveyEditor;
