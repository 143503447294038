import { useMutation } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const userCreateAdminInvitationReminder = () => {
    return useMutation({
        mutationFn: async ({ id, ...data }: { [k: string]: unknown }) => {
            return sendRequest(`manager/invitation/${id}`, {
                body: data,
                method: HttpMethod.PUT,
            });
        },
    });
};
