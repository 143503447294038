import { ReactNode } from 'react';
import { cx } from '../../helpers';

interface Props {
    type?: keyof typeof ButtonType;
    size?: keyof typeof ButtonSize;
    width?: keyof typeof ButtonWidth;
    title?: string;
    ariaLabel?: string;
    children: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

export const ButtonType = {
    button: `
        rounded-md
        shadow-sm
        shadow-slate-100
        border
        border-slate-200
        bg-white
        text-slate-800
        hover:bg-slate-50

        transition-colors
        duration-200
        ease-in-out

        active:outline-none
        active:ring-2
        active:ring-indigo-500
        active:ring-offset-2

        flex
        items-center
        justify-center
    `,
    submit: `
        rounded-md
        shadow-sm
        text-white
        transition-all
        bg-secondary
        cursor-pointer
        hover:opacity-90
        active:bg-secondary
        active:text-white/80

        flex
        items-center
        justify-center

        disabled:bg-red
        disabled:opacity-50 
        disabled:cursor-default
    `,
};

export const ButtonSize = {
    medium: `
        py-2
        px-3
        font-normal
        text-sm
    `,
    small: `
        w-6
        h-6
        font-normal
        text-xs
    `,
};

export const ButtonWidth = {
    auto: ``,
    full: `w-full`,
};

export const Button = ({
    type = 'button',
    size = 'medium',
    width = 'auto',
    title,
    children,
    disabled,
    onClick,
    ariaLabel,
}: Props) => (
    <button
        type={type}
        title={title}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
        className={cx(ButtonType[type], ButtonSize[size], ButtonWidth[width])}
    >
        {children}
    </button>
);
