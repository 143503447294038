import { ReactNode } from 'react';


interface Props {
    children: ReactNode,
    onClick: () => void,
}


export const Button = ({ children, onClick }: Props) => (
    <button
        type="button"
        color="cyan"
        onClick={onClick}
        className="
            transition-all
            rounded-md
            drop-shadow
            bg-slate-100
            outline-slate-400
            hover:outline
            hover:outline-2
            py-4
            px-16
            text-surveyjs
        "
    >
        {children}
    </button>
);
