import { UUID } from 'crypto';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UserList } from '../../widgets/UserList';
import { useUserList } from '../../queries/useUserList';
import { useAddUserRole, useRemoveUserRole } from '../../queries/useUserRole';
import { useDeleteUser } from '../../queries/useDeleteUser';
import { useDeleteInvitation } from '../../queries/useDeleteInvitation';
import { User, UserRole } from '../../types';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button/index';
import { AdminInviteAdvocateModal } from '../../widgets/InvitationAdvocate/modal';
import { AdvocateInvitationReminderModal } from '../../widgets/InvitationAdvocate/reminder';
import { findAdvocateInvitationById } from '../../helpers';
import { UserEditModal } from '../../widgets/UserEdit/UserEditModal';
import { useAuth } from '@/hooks/useAuth';

const Page = () => {
    const { isAdmin } = useAuth();
    const { data, error, isLoading, isError, refetch: refetchUserListData } = useUserList();

    const [isModalOpen, setModalOpen] = useState(false);
    const [isInviteModalOpen, setInviteModalOpen] = useState(false);
    const [invitationId, setInvitationId] = useState('');

    const [selectedUser, setSelectedUser] = useState<User>();

    const { mutateAsync: addUserRole } = useAddUserRole();
    const { mutateAsync: removeUserRole } = useRemoveUserRole();

    const showModal = (user: User) => {
        setSelectedUser(user);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(undefined);
        setModalOpen(false);
    };

    const onSubmit = async (params: { userId: string; prevRole: UserRole; newRole: UserRole }) => {
        const { userId, prevRole, newRole } = params;
        const defaultGroupList = ['patient', 'user'];

        closeModal();

        await toast.promise(
            async () => {
                if (!defaultGroupList.includes(prevRole)) {
                    await removeUserRole({ userId, role: prevRole });
                }

                if (!defaultGroupList.includes(newRole)) {
                    await addUserRole({ userId, role: newRole });
                }
            },
            {
                pending: 'changing tole...',
                error: 'failed to change the role',
                success: 'role successfully changed',
            },
        );

        refetchUserListData();
    };

    const { mutateAsync: deleteUser } = useDeleteUser();
    const { mutateAsync: deleteInvitation } = useDeleteInvitation();

    const onDelete = async (id: UUID, type: 'user' | 'invitation') => {
        await toast.promise(
            async () => {
                if (type === 'user') {
                    await deleteUser(id);
                }

                if (type === 'invitation') {
                    const invitation = data?.invitations.find(item => item.id === id);
                    const isAdvocate = ['advocate', 'clinician'].includes(invitation?.role ?? '');

                    await deleteInvitation({ id, type: isAdvocate ? 'advocate' : undefined });
                }
            },
            {
                pending: 'deleting user...',
                error: 'failed to delete user',
                success: 'user successfully deleted',
            },
        );

        refetchUserListData();
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error while loading: {error.message}</p>;
    }

    const advocateInvitationReminder = findAdvocateInvitationById(data?.invitations || [], invitationId);

    return (
        <>
            <AdminInviteAdvocateModal isOpen={isInviteModalOpen} onClose={() => setInviteModalOpen(false)} />
            <AdvocateInvitationReminderModal onClose={() => setInvitationId('')} invitation={advocateInvitationReminder} />
            <UserEditModal
                isOpen={isModalOpen}
                selectedUser={selectedUser}
                onSubmit={onSubmit}
                onClose={closeModal}
                allowedEditRoles={isAdmin ? [UserRole.advocate, UserRole.clinician, UserRole.manager, UserRole.admin] : [UserRole.advocate, UserRole.clinician]}
            />

            <Title>
                Users
                <Button onClick={() => setInviteModalOpen(true)}>New Invite</Button>
            </Title>

            <UserList
                users={data?.users || []}
                invitations={data?.invitations || []}
                onClick={showModal}
                onDelete={isAdmin ? onDelete : undefined}
                onClickInvitation={(invitation) => setInvitationId(invitation.id)}
            />
        </>
    );
};

export default Page;
