import { groupBy } from '../../helpers';


export const useGroupBy = <T>(items: T[], predicate: (item: T) => string) => {
    const grouppedItems =
        Object
            .entries(groupBy(predicate, items))
            .sort(([groupA], [groupB]) => {
                // empty values are the lowest
                if (!groupA) {
                    return 1;
                }

                if (!groupB) {
                    return -1;
                }

                return groupA.localeCompare(groupB);
            })
            .map(item => item as [string, T[]]);

    const groups = grouppedItems.map(([groupName]) => groupName);

    return {
        groups,
        items: grouppedItems,
    };
};
