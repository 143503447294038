import { toast } from 'react-toastify';
import { Body, Head, Table, Td, Th } from '../../components/Table';
import { SurveyStatus } from '../../components/SurveyStatus';
import { copyToClipboard, formatDate } from '../../helpers';
import { Pencil } from '../../components/Icons/Pencil';
import { DocumentClipboard } from '../../components/Icons/DocumentClipboard';
import { DocumentDuplicate } from '../../components/Icons/DocumentDuplicate';
import { Button } from '../../components/Button/index';
import { LinkButton } from '../../components/LinkButton';

const copySurveyUrl = (surveyId: string) => {
    const url = location.protocol + '//' + location.host + '/?surveyId=' + surveyId + '&mode=signup';

    return copyToClipboard(url)
        .then(() => toast.success('Signup url copied'))
        .catch((error) => console.log('error copying url to clipboard:', error));
};

interface Props {
    surveys: {
        id: string;
        name: string;
        comment: string;
        status: 'assigned' | 'started' | 'published';
        lastModified: number;
        updated: number;
    }[];
}

export const AdminSurveyList = ({ surveys }: Props) => (
    <Table>
        <Head>
            <Th>Name</Th>
            <Th align="center">Status</Th>
            <Th>Last&nbsp;modified</Th>
            <Th align="center">Actions</Th>
        </Head>

        <Body>
            {surveys.map((survey) => (
                <tr key={survey.id} className="even:bg-slate-100/50">
                    <Td>
                        {survey.name}

                        <div className="text-gray-400">{survey.comment}</div>
                    </Td>

                    <Td align="center">
                        <SurveyStatus status={survey.status} type="editorial" />
                    </Td>

                    <Td>{formatDate(new Date(survey?.lastModified || survey?.updated))}</Td>

                    <Td align="center">
                        <div className="flex flex-row gap-1 justify-center items-center">
                            <LinkButton size="small" title="View versions" to={`/admin/survey/versions/${survey.id}?name=${encodeURIComponent(survey.name)}`}>
                                <span className="text-slate-600 hover:text-slate-800">
                                    <DocumentDuplicate />
                                </span>
                            </LinkButton>

                            <LinkButton size="small" title="Edit latest" to={`/admin/survey/edit/${survey.id}`}>
                                <span className="text-slate-600 hover:text-slate-800">
                                    <Pencil />
                                </span>
                            </LinkButton>

                            <Button size="small" title="Copy link" onClick={() => copySurveyUrl(survey.id)}>
                                <span className="text-slate-600 hover:text-slate-800">
                                    <DocumentClipboard />
                                </span>
                            </Button>
                        </div>
                    </Td>
                </tr>
            ))}
        </Body>
    </Table>
);
