import { captureException } from '@sentry/react';

export const SurveyResultFiles = ({ value }: { value: { name: string, content: string, type: string }[] }) => {
    const handleDownload = async (item: { name: string, content: string, type: string }) => {
        try {
            const blob = await fetch(item.content).then((response) => response.blob());
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = item.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            captureException(error);
            console.error("Error downloading file:", error);
        }
    };

    return (
        <div>
            {value.map((item, index) => (
                <p className="mb-2 text-blue-500" key={index}>
                    <a href="#" onClick={() => handleDownload(item)}>{item.name}</a>
                </p>
            ))}
        </div>
    );
};
