import { useMutation } from '@tanstack/react-query';
import { HttpMethod, sendRequest } from '../../services/api';

export const useBuildExcel = () => useMutation({
    mutationFn: async (options: {
        surveyId: string,
        fileName: string,
        title: string,
        user: {
            name: string,
            email: string,
        },
        sections: { name: string, score: number }[],
        questions: { section: any, score: number }[],
    }) => {
        const { surveyId, user, title: dataSource, ...body } = options;

        const userInfo = `Client: ${user.name}`;
        const userEmail = `Email: ${user.email}`;

        return sendRequest(`advocate/survey/xlsx/${surveyId}`, {
            method: HttpMethod.POST,
            body: { dataSource, userInfo, userEmail, ...body },
        });
    },
});

export const useBuildPdf = () => useMutation({
    mutationFn: async ({ surveyId, ...body }: {
        surveyId: string,
        fileName: string,
        title: string,
        date: string,
        user: {
            name: string,
            email: string,
        },
        hasScored: boolean,
        areas: {
            [area: string]: {
                score: number,
                questions: {
                    text: string,
                    answer: string,
                    score: number,
                }[],
            },
        },
    }) => {    
        return sendRequest(`user/survey/pdf/${surveyId}`, { body, method: HttpMethod.POST });
    },
});
