import { useState, useMemo } from 'react';

const getDefaultSortFunction = (key: any) => (a: any, b: any) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === undefined || valueA === null) {
        return 1;
    }

    if (valueB === undefined || valueB === null) {
        return -1;
    }

    if (typeof valueA === 'string') {
        return valueA.localeCompare(valueB);
    }

    return valueA - valueB;
};

type SortFunctionsMap<T> = {
    [k: string]: (a: T, b: T) => number;
};

export const useSortBy = <T>(
    items: T[],
    options: {
        sortFunctionsMap?: SortFunctionsMap<T>,
        defaultSortKey?: string;
        defaultSortOrder?: 'asc' | 'desc';
    }
) => {
    const { sortFunctionsMap = {}, defaultSortKey = '', defaultSortOrder = 'asc' } = options;

    const [sortKey, setSortKey] = useState<string>(defaultSortKey);
    const [isReverse, setReverse] = useState(defaultSortOrder === 'desc');

    const sortFn = sortKey in sortFunctionsMap ? sortFunctionsMap[sortKey] : getDefaultSortFunction(sortKey);

    const sortedItems = useMemo(() => [...items].sort(sortFn), [items, sortKey]);

    const reversedItems = useMemo(() => (isReverse ? [...sortedItems].reverse() : sortedItems), [sortedItems, isReverse]);

    return {
        sortKey,
        isReverse,
        items: reversedItems,
        setSorting: (value: string) => {
            setReverse((prev) => (sortKey === value ? !prev : false));
            setSortKey(value);
        },
    };
};

/**
 * @deprecated useSortBy instead
 */
export const useSortGroupsBy = <T>(items: T[][], sortFunctionsMap: SortFunctionsMap<T> = {}, initialSorting: string = '') => {
    const [sortKey, setSortKey] = useState<string>(initialSorting);
    const [isReverse, setReverse] = useState(false);

    const sortFn = sortKey in sortFunctionsMap ? sortFunctionsMap[sortKey] : getDefaultSortFunction(sortKey);

    const sortedItems = useMemo(() => [...items].map((i) => i.sort(sortFn)), [items, sortKey]);

    const reversedItems = useMemo(() => (isReverse ? [...sortedItems].map((i) => i.reverse()) : sortedItems), [sortedItems, isReverse]);

    return {
        sortKey,
        isReverse,
        items: reversedItems,
        setSorting: (value: string) => {
            setReverse((prev) => (sortKey === value ? !prev : false));
            setSortKey(value);
        },
    };
};
