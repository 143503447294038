import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { captureException } from '@sentry/react';
import { Link } from '@/components/Link';
import { InputField } from '@/components/Input';
import { Button } from '@/components/Button/index';
// import { Checkbox } from '@/components/Checkbox';
import { Spinner } from '@/components/Spinner';
import { signIn } from '@/services/auth';
import { loginSchema } from '../helpers/validation';

const isAuthError = (error: unknown): error is { code: string } => {
    if (typeof error !== 'object') {
        return false;
    }

    if (error === null) {
        return false;
    }

    if ('code' in error) {
        return true;
    }

    return false;
};

export default function Login({ setMode }: { setMode: (mode: 'verify' | 'signup' | 'reset-password') => void }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async ({ email, password }: { email: string; password: string }) => {
        try {
            setLoading(true);

            await signIn(email, password);

            searchParams.delete('email');

            setSearchParams({ ...searchParams });
        } catch (error) {
            captureException(error);

            const code = isAuthError(error) ? error.code : undefined;

            console.log('Sign In Error', error);

            if (code === 'UserNotConfirmedException') {
                setSearchParams({ ...searchParams, email });
                setMode('verify');
            } else {
                toast.error('Error occured on a login attempt');
            }
        }

        setLoading(false);
    };

    return (
        <Formik
            initialValues={{ email: searchParams.get('email') || '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
        >
            {(props) => (
                <Form>
                    <div className="font-bold text-2xl text-slate-800">Welcome Back!</div>
                    <div className="text-slate-400 mt-2">Wellness account and embark on your journey to holistic health.</div>

                    <div className="flex flex-col mt-8 gap-4">
                        <InputField
                            label="Email"
                            type="email"
                            name="email"
                            required={true}
                            placeholder="name@wellness.com"
                            autoComplete="email"
                        />

                        <InputField
                            label="Password"
                            type="password"
                            name="password"
                            required={true}
                            placeholder="at least 6 characters"
                            autoComplete="current-password"
                        />
                    </div>

                    <div className="flex flex-row justify-between mt-4 mb-8 text-slate-800">
                        <label className="flex items-center gap-2">
                            {/* <Checkbox name="rememberMe" checked={true} /> Remember me */}
                        </label>

                        <Link theme="light" onClick={() => setMode('reset-password')}>
                            Forgot password?
                        </Link>
                    </div>

                    <Button type="submit" width="full" disabled={!props.isValid || isLoading}>
                        {isLoading && <Spinner />}
                        Login
                    </Button>

                    <div className="text-center my-8 text-slate-800">
                        Not registered yet? <Link theme="light" onClick={() => setMode('signup')}>Create an Account</Link>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
