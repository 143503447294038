import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { Serializer } from 'survey-core';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { QueryContextProvider } from './queries/client';
import { ToastWrapper } from './components/ToastWrapper';
import config from './services/config';
import App from './App';

import './index.css';

Amplify.configure(config);

Serializer.addProperty('question', 'id');

// Add a boolean property to the survey General section
Serializer.addProperty('survey', {
    name: 'scored',
    type: 'boolean',
    displayName: 'Is Scored',
    default: true,
    category: 'general',
    visibleIndex: 0,
    categoryIndex: 0,
});

// Add a property to the Page class
Serializer.addProperty('itemvalue', {
    name: 'score',
    type: 'number',
    displayName: 'score',
    category: 'general',
    visibleIndex: 0,
    categoryIndex: 0,
});

// Add a property to the base Question class and to all questions as a result
Serializer.addProperty('question', {
    name: 'score',
    type: 'number',
    displayName: 'Question Score',
    category: 'MTOmics',
    visibleIndex: 1,
    categoryIndex: 0,
});

Serializer.addProperty('question', {
    name: 'section',
    type: 'string',
    choices: [
        'Genetics and Epigenetics',
        'Blood Sugar Balance',
        'Toxic Burden',
        'Microbiome and Digestive Function',
        'Immune Function',
        'Inflammation',
        'Blood Circulation & Angiogenesis',
        'Hormone Balance',
        'Stress & Biorhythms',
        'Mental & Emotional Health',
    ],
    displayName: 'Question Section',
    category: 'MTOmics',
    visibleIndex: 2,
    categoryIndex: 0,
});

Serializer.addProperty('question', {
    name: 'scoreType',
    type: 'string',
    default: 'Standard',
    choices: ['Standard', 'Negative', 'Overall'],
    displayName: 'Scoring Type',
    category: 'MTOmics',
    visibleIndex: 3,
    categoryIndex: 0,
});

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastWrapper>
                <QueryContextProvider>
                    <App />
                </QueryContextProvider>
            </ToastWrapper>
        </BrowserRouter>
    </React.StrictMode>
);
