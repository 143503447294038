export const UserInfo = (user: { name: string; email: string }) => (
    <div className={`flex flex-col gap-1 overflow-hidden whitespace-nowrap ml-2 first:ml-0`}>
        <span title={user.name} className="capitalize text-slate-800 font-normal whitespace-nowrap overflow-hidden text-ellipsis">
            {user.name}
        </span>
        <span title={user.email} className="text-xs text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
            {user.email}
        </span>
    </div>
);
