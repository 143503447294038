import { ReactNode, useEffect, useRef, useState } from 'react';

interface Props {
    title?: ReactNode,
    children: ReactNode,
}

export const Dropdown = ({ title = '...', children }: Props) => {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (!ref.current?.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.body.addEventListener('click', handler);

        return () => document.body.removeEventListener('click', handler);
    }, []);

    return (
        <div className="relative inline-block text-left text-sm whitespace-nowrap w-full" ref={ref}>
            <div onClick={() => setOpen(!isOpen)}>
                {title}
            </div>

            <div
                className={`
                    absolute
                    left-0
                    top-full
                    z-10
                    pt-2
                    gap-2
                    ${isOpen ? 'flex' : 'hidden'}
                `}
            >
                <div className={`
                    bg-white
                    rounded-md
                    shadow-lg
                    ring-1
                    ring-black
                    ring-opacity-5
                    flex
                    flex-col
                    px-4
                    py-2
                `}>
                    {children}
                </div>
            </div>
        </div>
    );
}
