import { PatientSurveyListWidget } from '@/widgets/PatientSurveyList';
import { Title } from '@/components/Title';

const Page = () => {
    return (
        <>
            <Title>My Surveys</Title>
            <PatientSurveyListWidget />
        </>
    );
};

export default Page;
