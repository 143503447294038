import { useParams } from 'react-router-dom';
import SingleSurvey from '../widgets/Survey';
import { useUserSurvey } from '../queries/useSurvey';

const Survey = () => {
    const { slug } = useParams();
    const { isLoading, isError, data, error } = useUserSurvey(slug);

    if (isLoading) {
        return <p> Loading ...</p>;
    }

    if (isError) {
        return <p>Error while loading: {error.message}</p>
    }

    return (
        <SingleSurvey data={data} />
    );
};

export default Survey;
