import { ComponentProps } from 'react';
import { useCreateAdvocateInvitation } from '../../queries/useCreateAdvocateInvitation';
import { AdminInviteAdvocateForm } from './form';
import { Modal } from '../../components/Modal';
import { Invitation } from '../../types';
import { getHtmlBody, getRegisterAdvocateMessage } from '../../helpers/email';
import { toast } from 'react-toastify';

type Props = { invitation?: Invitation } & Omit<ComponentProps<typeof Modal>, 'children'>;

export const AdminInviteAdvocateModal = ({ isOpen, onClose }: Props) => {
  const { mutateAsync: createInvitation } = useCreateAdvocateInvitation();

  const subject = `You've been invited to the MTOmics platform`;
  const link = `https://${window.location.host}?mode=signup&type=bba`;
  const defaultMessage = getRegisterAdvocateMessage({ firstName: ' ', lastName: ' ' }, 'an advocate', link);

  const onInvitationCreate = ({
    email,
    subject,
    role,
    message,
    firstName,
    lastName,
  }: {
    email: string;
    subject: string;
    role: string;
    message: string;
    firstName: string;
    lastName: string;
  }) => {
    toast.promise(
      createInvitation({
        email,
        role,
        subject,
        firstName,
        lastName,
        message: getHtmlBody(message),
        date: Math.floor(Date.now() / 1000),
      }),
      {
        pending: 'Creating an invitation...',
        success: 'Invitation has been sent.',
        error: {
          render: (err: any) => {
            // Assuming `err.message` contains the error message you want to display
            // If the error is not an instance of Error or doesn't have a message,
            // a default message will be shown.
            console.log(err);
            return typeof err === 'object' && err.data.response.data.error ? err.data.response.data.error : 'Failed to create an invitation.';
          }
        },
      },
    );
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <AdminInviteAdvocateForm subject={subject} message={defaultMessage} onSubmit={onInvitationCreate} />
    </Modal>
  );
};
