import { useQuery } from '@tanstack/react-query';
import { sendRequest } from '@/services/api';
import { User } from '@/types';
import { UUID } from 'crypto';

interface QueryResponseItem {
    id: UUID;
    firstName: string;
    lastName: string;
    email: string;
    type: 'patient';
}

export const usePatientList = () => useQuery<{ patients: User[] }, Error, QueryResponseItem[]>({
    queryKey: ['advocate/patients'],
    queryFn: () => sendRequest('advocate/patients'),
    select: (data) => data.patients.map((patient) => ({
        id: patient.userId,
        firstName: patient.firstName,
        lastName: patient.lastName,
        email: patient.email,
        type: 'patient',
    })),
});

export const useAdvocatePatientList = (params: { advocateId: UUID }) => {
    const path = `manager/advocate/patients/${params.advocateId}`;

    return useQuery<any, Error, any[]>({
        queryKey: [path],
        queryFn: async () => sendRequest(path),
        select: data => data.patients,
    });
};
