import { useAdminActionLog } from '../../queries/useAdminActionLog';
import { Title } from '../../components/Title';
import { UserActionLog } from '../../widgets/UserActionList';

const AdminActionLog = () => {
    const { isLoading, isError, data, error } = useAdminActionLog();

    return (
        <>
            {isLoading && <p> Loading ...</p>}
            {isError && <p> Error while loading: {error.message} </p>}
            {data && console.log(data)}
            {data && (
                <>
                    <Title>
                        Actions Log
                    </Title>
                    <UserActionLog userActionLog={data.sort((a: any, b: any) => b.updated - a.updated)} />
                </>
            )}
        </>
    );
};

export default AdminActionLog;
