import { sendRequest } from '@/services/api';
import { Survey } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { UUID } from 'crypto';

interface QueryResponse {
    surveys: Survey[];
}

/**
 * @todo: merge with useSurveyList
 */
export const useUserSurveyList = (userId: UUID) => useQuery({
    queryKey: [`user/${userId}/surveys`],
    queryFn: () => sendRequest<QueryResponse>(`user/${userId}/surveys`),
    select: (data) => data.surveys,
});
