import { ArrowDown, Arrows } from '../Icons';
import { ArrowUp } from '../Icons/ArrowUp';


export const SortArrows = ({ direction = 'none' }: { direction?: 'asc' | 'desc' | 'none' }) => {
    const iconToDirectionMap = {
        'asc': ArrowUp,
        'desc': ArrowDown,
        'none': Arrows,
    };

    const Icon = iconToDirectionMap[direction];

    return <Icon />;
};
