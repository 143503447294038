
import { useParams, useSearchParams } from 'react-router-dom';
import { useNewSurvey } from '../queries/useSurvey';
import { useAuth } from '../hooks/useAuth';
import SingleSurvey from '../widgets/Survey';

const TakeNewSurvey = () => {
    const [searchParams] = useSearchParams();
    const { user } = useAuth();
    const { slug } = useParams();
    const { isLoading, isError, data, error } = useNewSurvey(slug, searchParams.get('advocateId'));

    return (
        <>
            {isLoading && <p> Loading ...</p>}
            {isError && <p> Error while loading: {error.message} </p>}
            {data && console.log(data)}
            {data && (
                <SingleSurvey
                    data={{
                        surveyData: data.surveyData,
                        survey: data.survey || {
                            id: globalThis.crypto.randomUUID(),
                            userId: user.id,
                            surveyId: slug,
                            status: 'assigned',
                            completion: 0,
                            advocateId: searchParams.get('advocateId'),
                            name: data.surveyData?.title,
                            updated: Math.floor(Date.now() / 1000),
                        },
                    }}
                />
            )}
        </>
    );
};

export default TakeNewSurvey;
