interface Props<T> {
    options: { [label: string]: T },
    selected: T,
    onClick: (option: T) => void,
}

export const RadioGroup = <T,>({ options, selected, onClick }: Props<T>) => (
    <div className="flex items-start gap-4 cursor-pointer pb-6 text-slate-800">
        {Object.entries(options).map(([label, option]) => (
            <div
                key={label}
                onClick={() => onClick(option)}
                className="h-5 flex gap-2 items-center cursor-pointer"
            >
                <div className="w-4 h-4 bg-basic/50 rounded-full flex items-center justify-center shadow-inner shadow-sm">
                    {option === selected && (
                        <div className="w-2 h-2 bg-white shadow rounded-full" />
                    )}
                </div>

                {label}
            </div>
        ))}
    </div>
);
