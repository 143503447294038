import { Body, Head, Table, Td, Th } from '../../../../components/Table';


interface Props {
    issues: { title: string, description: string }[],
}


export const ErrorList = ({ issues }: Props) => {
    return (
        <Table>
            <Head>
                <Th>Title</Th>
                <Th>Description</Th>
            </Head>

            <Body>
                {issues.map((issue) => (
                    <tr key={issue.title} className="even:bg-basic/10">
                        <Td>{issue.title}</Td>
                        <Td>{issue.description}</Td>
                    </tr>
                ))}
            </Body>
        </Table>
    );
};
