import { Title } from '@/components/Title';

const NotFound = () => {
    return (
        <div className="text-slate-600">
            <Title>Not Found</Title>
            <p>The page you're looking for does not exist or has been permanently moved.</p>
        </div>
    );
};

export default NotFound;
