import { ReactNode } from 'react';

export const Table = ({ children }: { children: ReactNode }) => (
    <table className='
        min-w-full
        shadow
        rounded-md
        ring-1
        ring-black
        ring-opacity-5
        divide-y
        divide-gray-300
        table-fixed
        overflow-hidden
    '>
        {children}
    </table>
);
