
import { useState } from 'react';
import {
    ArrowLeftOnRectangleIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ClipboardDocumentCheckIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Link } from '@/components/Link';
import { useAuth } from '@/hooks/useAuth';
import { signOut } from '@/services/auth';
import { TruncatedText } from './elements/TruncatedText';
import { UserAvatar } from './elements/UserAvatar';
import { UserInfo } from './elements/UserInfo';
import { NavLink } from 'react-router-dom';

const getNavigation = (props: ReturnType<typeof useAuth>) => {
    if (props.isAdvocate) {
        return [{ name: 'Dashboard', to: '/', icon: UserGroupIcon }];
    }

    if (props.isClinician) {
        return [{ name: 'Patients', to: '/', icon: UserGroupIcon }];
    }

    if (props.isManager) {
        return [
            { name: 'Client surveys', to: '/', icon: ClipboardDocumentListIcon },
            { name: 'Users', to: '/manager/users', icon: UserGroupIcon },
        ];
    }

    if (props.isAdmin) {
        return [
            { name: 'Client surveys', to: '/', icon: ClipboardDocumentListIcon },
            { name: 'Users', to: '/admin/users', icon: UserGroupIcon },
            { name: 'Manage surveys', to: '/admin/surveys', icon: ClipboardDocumentListIcon },
            { name: 'Action Log', to: '/admin/actions', icon: ClipboardDocumentCheckIcon },
        ];
    }

    if (props.isPatient) {
        return [{ name: 'Dashboard', to: '/', icon: ClipboardDocumentListIcon }];
    }

    return [];
};

const widthMap = {
    'collapsed': 'w-20',
    'expanded': 'w-64',
};

export const Navigation = () => {
    const [isOpen, setOpen] = useState(true);
    const auth = useAuth();
    const navigation = getNavigation(auth);
    const width = widthMap[isOpen ? 'expanded' : 'collapsed'];

    return (
        <nav className={`sticky top-0 flex flex-col self-start min-h-[100vh] px-4 pt-4 ${width} bg-basic/10 text-sm transition-all justify-between`}>
            <div>
                <div className="flex items-center mb-8 pb-8 border-b border-slate-200">
                    <UserAvatar name={auth.user.name} />
                    <UserInfo {...auth.user} />
                </div>

                <div className="flex flex-col gap-4 px-4 mb-8 pb-8 border-b border-slate-200">
                    {navigation.map((item, index) => (
                        <NavLink
                            key={index}
                            to={item.to}
                            className={(props) => `flex gap-2 ${props.isActive ? 'text-slate-800' : 'text-slate-400'}`}
                        >
                            <item.icon className="w-5 h-5 shrink-0" />
                            <TruncatedText>{item.name}</TruncatedText>
                        </NavLink>
                    ))}
                </div>

                <div className="flex flex-col gap-4 px-4">
                    <Link to="/" className={`flex items-center gap-2`} theme="dark">
                        <UserCircleIcon className="w-5 h-5 shrink-0" />
                        <TruncatedText>Profile</TruncatedText>
                    </Link>

                    <Link onClick={signOut} className={`flex items-center gap-2`} theme="dark">
                        <ArrowLeftOnRectangleIcon className="w-5 h-5 shrink-0" />
                        <TruncatedText>Sign Out</TruncatedText>
                    </Link>
                </div>
            </div>

            <div className="flex items-center gap-2 h-12 border-t border-slate-200 shrink-0 mt-8">
                <Link
                    theme="dark"
                    onClick={() => setOpen(!isOpen)}
                    className="flex items-center gap-2 px-4 w-full h-full select-none overflow-hidden"
                >
                    {(
                        isOpen
                            ? <ChevronDoubleLeftIcon className="w-4 h-4 shrink-0" />
                            : <ChevronDoubleRightIcon className="w-4 h-4 shrink-0" />
                    )}
                    <TruncatedText>Collapse Sidebar</TruncatedText>
                </Link>
            </div>
        </nav>
    );
};
