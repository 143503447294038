import { ComponentProps, useState } from 'react';
import { UUID } from 'crypto';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { Button } from '@/components/Button';
import { useInvitationList } from '@/queries/useInvitationList';
import { useMyProfile } from '@/queries/useMyProfile';
import { InvitationReminderModal } from './InvitationReminderModal';
import { Survey } from '@/types';

export const InvitationReminderButton = (props: { invitationId: UUID }) => {
    const [isOpen, setOpen] = useState(false);

    const { data = [] } = useInvitationList();
    const { data: profile = [] } = useMyProfile();

    const invitation = data.find((invitation) => invitation.id === props.invitationId)!;

    const surveyNames = invitation.surveys.map((survey) => survey.name);
    const mainSurveyIndex = Math.max(invitation.surveys.findIndex((survey) => survey.airtable), 0);

    let surveyName = surveyNames[mainSurveyIndex]!;

    if (surveyNames.length > 1) {
        const surveysCount = surveyNames.filter((...[, index]) => index !== mainSurveyIndex).length;

        surveyName = `${surveyName} and ${surveysCount} more`;
    }

    {/* @todo: normalize data for invitation reminder modal */ }
    const targetEntity = {
        ...invitation,
        surveyIds: invitation?.surveys.map((survey) => survey.id),
        surveyName: surveyName,
        invitationId: invitation.id,
        date: invitation.createdOn,
        completion: 0,
        advocate: profile,
    };

    return (
        <>
            <InvitationReminderModal isOpen={isOpen} onClose={() => setOpen(false)} invitation={targetEntity} />

            <Button size="small" onClick={() => setOpen(true)} title="send reminder" ariaLabel="send reminder">
                <EnvelopeIcon className="w-4 h-4 text-slate-600 hover:text-slate-800" />
            </Button>
        </>
    );
};

// @todo: get rid of exhausting props, endpoint needed for redisgn
export const SurveyReminderButton = (props: { survey: Survey }) => {
    const [isOpen, setOpen] = useState(false);
    const { data: profile = [] } = useMyProfile();
    const { email, firstName, lastName } = props.survey.user;

    {/* @todo: normalize data for invitation reminder modal */ }
    const targetEntity = {
        email,
        firstName,
        lastName,
        id: props.survey.id,
        surveyIds: [props.survey.id],
        surveyName: props.survey.name,
        completion: props.survey.completion,
        advocate: profile,
        invitationId: props.survey.id,
        status: 'assigned',
        date: Date.now(),
    } satisfies ComponentProps<typeof InvitationReminderModal>['invitation'];

    return (
        <>
            <InvitationReminderModal isOpen={isOpen} onClose={() => setOpen(false)} invitation={targetEntity} />

            <Button size="small" onClick={() => setOpen(true)} title="send reminder" ariaLabel="send reminder">
                <EnvelopeIcon className="w-4 h-4 text-slate-600 hover:text-slate-800" />
            </Button>
        </>
    );
};
