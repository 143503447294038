import { SurveyCreator } from '../widgets/SurveyCreator';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAdminSurvey } from '../queries/useSurvey';
import { Title } from '../components/Title';

const EditSurvey = () => {
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const { isLoading, isError, data, error } = useAdminSurvey(slug, {
    isVersions: false,
    version: searchParams.get('version'),
  });

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  if (isError) {
    return <p>Error while loading: {error.message}</p>;
  }

  return (
    <>
      <Title>
        {data.survey.title}
      </Title>
      
      <SurveyCreator
        id={slug}
        surveyData={data.survey}
        surveyRecord={data.surveyRecord}
        version={searchParams.get('version') || ''}
      />
    </>
  );
};

export default EditSurvey;
