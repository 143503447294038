import { ComponentProps } from 'react';
import { userCreateAdminInvitationReminder } from '../../queries/userCreateAdminInvitationReminder';
import { InvitationReminderForm } from '../SendReminder/InvitationReminderForm';
import { Modal } from '../../components/Modal';
import { getHtmlBody, getAdvocateInvitationReminderMessage } from '../../helpers/email';
import { formatName } from '../../helpers';
import { Invitation, AdvocateInvitation } from '../../types';

type Props = { invitation?: Invitation | AdvocateInvitation } & Omit<ComponentProps<typeof Modal>, 'isOpen' | 'children'>;

export const AdvocateInvitationReminderModal = ({ invitation, onClose }: Props) => {
    const { mutate: createInvitationReminder } = userCreateAdminInvitationReminder();

    const isOpen = Boolean(invitation);
    const invitationId = invitation?.id;

    const subject = `Signup Reminder`;
    const link = `https://${window.location.host}?mode=signup&type=bba`;
    const fullName = invitation ? formatName(invitation, { separator: ' ', order: 'asc' }) : '';
    const defaultMessage = getAdvocateInvitationReminderMessage(fullName, link);

    const onInvitationCreate = ({ subject, message }: { subject: string; message: string }) => {
        console.log('createInvitationReminder', subject);

        if (!invitationId) {
            console.log('no invitation id found');
            return;
        }

        createInvitationReminder({
            subject,
            id: invitationId,
            message: getHtmlBody(message),
        });

        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <InvitationReminderForm subject={subject} message={defaultMessage} onSubmit={onInvitationCreate} />
        </Modal>
    );
};
