import { Button } from '../Button';


interface Props {
    onFinish: () => void,
    onExit: () => void,
    onPrev?: () => void,
    onNext?: () => void,
}


export const Navigation = ({ onPrev, onNext, onFinish, onExit }: Props) => (
    <div className="max-w-full flex gap-4 py-4">
        {onPrev && (
            <Button onClick={onPrev}>
                Previous
            </Button>
        )}

        <Button onClick={!onNext ? onFinish : onExit}>
            {onNext ? 'Save and exit' : 'Finish'}
        </Button>

        {onNext && (
            <Button onClick={onNext}>
                Next
            </Button>
        )}
    </div>
);
